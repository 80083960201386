@import "plugins/smartadmin/plugin-dataTables.bootstrap";
@import "plugins/smartadmin/plugin-dataTables.colReorder";
@import "plugins/smartadmin/plugin-dataTables.colVis";
@import "plugins/smartadmin/plugin-dataTables.tableTools";
@import "plugins/smartadmin/plugin-dataTables.responsive";
@import "plugins/smartadmin/plugin-dataTables-cust";
@import "plugins/smartadmin/plugin-jarviswidget";
@import "plugins/smartadmin/component-pace";

.jarviswidget header {
  height: 34px;
  padding: 0;
  line-height: 34px;
}

.jarviswidget {
  & > div.noborder{
    border:none;
  }
  header {
    color:$white;

    h2 {
      color: $white
    }
    .header_icon {
      a {
        margin-left: 10px;
        color: $white;
        font-size: 15px;
        i {
          font-size: 15px;
        }
        &:hover {
          text-decoration: none;
          font-weight: 600;
        }
      }
    }
  }
  .widget-body{
    //padding-bottom: 20px;
  }

  .no-padding{
    padding: 0px;
    table{
      margin: 0px;
    }
  }
}
