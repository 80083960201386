/* --- Basics --- */

.loading {
  display: inline-block;
  overflow: hidden;
  height: 1.3em;
  margin-top: -0.3em;
  line-height: 1.5em;
  vertical-align: text-bottom;
}

.loading::after {
  display: inline-table;
  white-space: pre;
  text-align: left;
}
/* --- Animations --- */

@keyframes spin1  { to { transform: translateY( -1.5em); } }
@keyframes spin2  { to { transform: translateY( -3.0em); } }
@keyframes spin3  { to { transform: translateY( -4.5em); } }
@keyframes spin4  { to { transform: translateY( -6.0em); } }
@keyframes spin5  { to { transform: translateY( -7.5em); } }
@keyframes spin6  { to { transform: translateY( -9.0em); } }
@keyframes spin7  { to { transform: translateY(-10.5em); } }
@keyframes spin8  { to { transform: translateY(-12.0em); } }
@keyframes spin9  { to { transform: translateY(-13.5em); } }
@keyframes spin10 { to { transform: translateY(-15.0em); } }
@keyframes spin11 { to { transform: translateY(-16.5em); } }
@keyframes spin12 { to { transform: translateY(-18.0em); } }

.loading::after {
  content: "\A.\A..\A...";
  animation: spin4 2s steps(4) infinite;
}

.loading.triangles::after {
  content: "▹▹▹▹▹\A ▸▹▹▹▹\A ▹▸▹▹▹\A ▹▹▸▹▹\A ▹▹▹▸▹\A ▹▹▹▹▸";
  animation: spin6 1s steps(6) infinite;
}

.loading.beam::after {
  content: "\A=   \A ==  \A === \A ====\A  ===\A   ==\A    =\A";
  animation: spin9 1.2s steps(9) infinite;
  font-family: monospace;
}

.loading.bullet::after {
  content: " ●    \A   ●   \A    ●  \A     ● \A      ●\A     ● \A    ●  \A   ●   \A  ●    \A ●     ";
  animation: spin10 1s steps(10) infinite;
}
.loading.circle::after {
  content: "◴\A◷\A◶\A◵";
  animation: spin4 1s steps(4) infinite;
}

.loading.open-circle::after {
  content: "◜\A◠\A◝\A◞\A◡\A◟";
  animation: spin6 .6s steps(6) infinite;
}

.loading.lifting::after {
  content: "꜈꜍\A꜉꜎\A꜊꜏\A꜋꜐\A꜌꜑";
  animation: spin5 .5s steps(5) infinite alternate;
}

.loading_global_right {
  position: fixed;
  bottom: 17px;
  right: -5px;
  z-index: 9999;
  max-width: 500px;
  margin-right: 15px;
}