@charset "UTF-8";
/*
$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                darken($btn-info-bg, 5%);


$btn-warning-color:              $brand-warning;
$btn-warning-bg:                 $white;
$btn-warning-border:             $brand-warning;

*/
/* SMARTADMIN VARIABLES
 * All variables related specifically to the theme starts from here:
 *
 * Grays moved to front, because they are needed before this point.
 */
/* left side */
/* right side */
/* the color that flashes when you click on the first nav element */
/* the plus icon that appears on the parent nav when expanding and collapsing */
/* these values will only address the first parent in the entire nav */
/* the plus & minus icons */
/* margin top */
/*
 * TEXT COLOR
 */
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
.txt-color-blue {
  color: #57889c !important;
}

.txt-color-blueLight {
  color: #92a2a8 !important;
}

.txt-color-blueDark {
  color: #4c4f53 !important;
}

.txt-color-green {
  color: #356e35 !important;
}

.txt-color-greenLight {
  color: #71843f !important;
}

.txt-color-greenDark {
  color: #496949 !important;
}

.txt-color-red {
  color: #a90329 !important;
}

.txt-color-yellow {
  color: #b09b5b !important;
}

.txt-color-orange {
  color: #b19a6b !important;
}

.txt-color-orangeDark {
  color: #a57225 !important;
}

.txt-color-pink {
  color: #ac5287 !important;
}

.txt-color-pinkDark {
  color: #a8829f !important;
}

.txt-color-purple {
  color: #A128AB !important;
}

.txt-color-darken {
  color: #404040 !important;
}

.txt-color-lighten {
  color: #d5e7ec !important;
}

.txt-color-white {
  color: #ffffff !important;
}

.txt-color-gray {
  color: #CCC !important;
}

.txt-color-grayDark {
  color: #525252 !important;
}

.txt-color-magenta {
  color: #6e3671 !important;
}

.txt-color-teal {
  color: #568a89 !important;
}

.txt-color-redLight {
  color: #a65858 !important;
}

.txt-color-bluemega {
  color: #008BD1 !important;
}

.txt-color-black {
  color: #000 !important;
}

/*
 * BACKGROUNDS
 */
.bg-color-blue {
  background-color: #57889c !important;
}

.bg-color-blueLight {
  background-color: #92a2a8 !important;
}

.bg-color-blueDark {
  background-color: #4c4f53 !important;
}

.bg-color-green {
  background-color: #356e35 !important;
}

.bg-color-greenLight {
  background-color: #71843f !important;
}

.bg-color-greenDark {
  background-color: #496949 !important;
}

.bg-color-red {
  background-color: #a90329 !important;
}

.bg-color-yellow {
  background-color: #b09b5b !important;
}

.bg-color-orange {
  background-color: #c79121 !important;
}

.bg-color-orangeDark {
  background-color: #a57225 !important;
}

.bg-color-pink {
  background-color: #ac5287 !important;
}

.bg-color-pinkDark {
  background-color: #a8829f !important;
}

.bg-color-purple {
  background-color: #6e3671 !important;
}

.bg-color-darken {
  background-color: #404040 !important;
}

.bg-color-lighten {
  background-color: #d5e7ec !important;
}

.bg-color-white {
  background-color: #ffffff !important;
}

.bg-color-gray {
  background-color: #CCC !important;
}

.bg-color-grayDark {
  background-color: #525252 !important;
}

.bg-color-magenta {
  background-color: #6e3671 !important;
}

.bg-color-teal {
  background-color: #568a89 !important;
}

.bg-color-redLight {
  background-color: #a65858 !important;
}

.bg-color-bluemega {
  background-color: #008BD1 !important;
}

.bg-color-bluemegadark {
  background-color: #0E2A40 !important;
}

.bg-color-bluemegalight {
  background-color: #1C537F !important;
}

.bg-color-bluemegalighest {
  background-color: #2A7DBF !important;
}

div.dataTables_length label {
  font-weight: normal;
  float: left;
  text-align: left;
}
div.dataTables_length select {
  width: 75px;
}
div.dataTables_filter label {
  font-weight: normal;
  float: right;
}
div.dataTables_filter input {
  width: 16em;
}
div.dataTables_info {
  padding-top: 8px;
}
div.dataTables_paginate {
  float: right;
  margin: 0;
}
div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
}
table.dataTable td, table.dataTable th {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
}
table.dataTable thead .sorting {
  background: url("../img/sort_both.png") no-repeat center right;
}
table.dataTable thead .sorting_asc {
  background: url("../img/sort_asc.png") no-repeat center right;
}
table.dataTable thead .sorting_desc {
  background: url("../img/sort_desc.png") no-repeat center right;
}
table.dataTable thead .sorting_asc_disabled {
  background: url("../img/sort_asc_disabled.png") no-repeat center right;
}
table.dataTable thead .sorting_desc_disabled {
  background: url("../img/sort_desc_disabled.png") no-repeat center right;
}
table.dataTable thead > tr > th {
  padding-left: 18px;
  padding-right: 18px;
}
table.dataTable th:active {
  outline: none;
}

/* Scrolling */
div.dataTables_scrollHead table {
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
div.dataTables_scrollHead table thead tr:last-child th:first-child, div.dataTables_scrollHead table thead tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody tbody tr:first-child th, div.dataTables_scrollBody tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

/* Frustratingly the border-collapse:collapse used by Bootstrap makes the column
 width calculations when using scrolling impossible to align columns. We have
 to use separate
*/
table.table-bordered.dataTable {
  border-collapse: separate !important;
}
table.table-bordered thead th, table.table-bordered thead td {
  border-left-width: 0;
  border-top-width: 0;
}
table.table-bordered tbody th, table.table-bordered tbody td {
  border-left-width: 0;
  border-bottom-width: 0;
}
table.table-bordered th:last-child, table.table-bordered td:last-child {
  border-right-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

/*
* TableTools styles
*/
/* conflicting styles with bootstrap
.table tbody tr.active td,
.table tbody tr.active th {
  background-color: #08C;
  color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
  background-color: #0075b0;
}

.table tbody tr.active a {
  color: white;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
  background-color: #017ebc;
} */
table.DTTT_selectable tbody tr {
  cursor: pointer;
}

div.DTTT .btn {
  color: #333 !important;
  font-size: 12px;
}
div.DTTT .btn:hover {
  text-decoration: none !important;
}

ul.DTTT_dropdown.dropdown-menu {
  z-index: 2003;
}
ul.DTTT_dropdown.dropdown-menu a {
  color: #333 !important;
  /* needed only when demo_page.css is included */
}
ul.DTTT_dropdown.dropdown-menu li {
  position: relative;
}
ul.DTTT_dropdown.dropdown-menu li:hover a {
  background-color: #0088cc;
  color: white !important;
}

div.DTTT_collection_background {
  z-index: 2002;
}

/* TableTools information display */
div.DTTT_print_info.modal {
  height: 150px;
  margin-top: -75px;
  text-align: center;
}
div.DTTT_print_info h6 {
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
  margin: 1em;
}
div.DTTT_print_info p {
  font-size: 14px;
  line-height: 20px;
}

/*
* FixedColumns styles
*/
div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
div.DTFC_RightHeadWrapper table,
div.DTFC_RightFootWrapper table,
table.DTFC_Cloned tr.even {
  background-color: white;
  margin-bottom: 0;
}

div.DTFC_RightHeadWrapper table,
div.DTFC_LeftHeadWrapper table {
  margin-bottom: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.DTFC_RightHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_RightHeadWrapper table thead tr:last-child td:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.DTFC_RightBodyWrapper table,
div.DTFC_LeftBodyWrapper table {
  border-top: none;
  margin: 0 !important;
}

div.DTFC_RightBodyWrapper tbody tr:first-child th,
div.DTFC_RightBodyWrapper tbody tr:first-child td,
div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
  border-top: none;
}

div.DTFC_RightFootWrapper table,
div.DTFC_LeftFootWrapper table {
  border-top: none;
}

/*
* FixedHeader styles
*/
div.FixedHeader_Cloned table {
  margin: 0 !important;
}

/*
 * Namespace DTCR - "DataTables ColReorder" plug-in
 */
table.DTCR_clonedTable {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202;
}

div.DTCR_pointer {
  width: 1px;
  background-color: #0259C4;
  z-index: 201;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
* ColVis styles
*/
div.ColVis {
  float: right;
  margin-bottom: 1em;
}

button.ColVis_Button,
ul.ColVis_collection li {
  position: relative;
  float: left;
  margin-right: 3px;
  padding: 5px 8px;
  border: 1px solid #999;
  cursor: pointer;
  *cursor: hand;
  font-size: 0.88em;
  color: black !important;
  white-space: nowrap;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 1px 1px 3px #ccc;
  -moz-box-shadow: 1px 1px 3px #ccc;
  -ms-box-shadow: 1px 1px 3px #ccc;
  -o-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;
  /* Generated by http://www.colorzilla.com/gradient-editor/ */
  background: #ffffff;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* Opera 11.10+ */
  background: linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#f9f9f9",GradientType=0 );
  /* IE6-9 */
}

.ColVis_Button:hover,
ul.ColVis_collection li:hover {
  border: 1px solid #666;
  text-decoration: none !important;
  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  -ms-box-shadow: 1px 1px 3px #999;
  -o-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
  background: #f3f3f3;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* Opera 11.10+ */
  background: linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f3f3f3", endColorstr="#f4f4f4",GradientType=0 );
  /* IE6-9 */
}

button.ColVis_Button {
  height: 30px;
  padding: 3px 8px;
}
button.ColVis_Button:active {
  outline: none;
}

button.ColVis_Button::-moz-focus-inner {
  border: none !important;
  padding: 0;
}

div.ColVis_collectionBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 1100;
}

ul.ColVis_collection {
  width: 150px;
  padding: 8px 8px 4px 8px;
  margin: 0;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  z-index: 2002;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}
ul.ColVis_collection li {
  position: relative;
  height: auto;
  left: 0;
  right: 0;
  padding: 0.5em;
  display: block;
  float: none;
  margin-bottom: 4px;
  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  -ms-box-shadow: 1px 1px 3px #999;
  -o-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
  text-align: left;
}
ul.ColVis_collection li.ColVis_Button:hover {
  border: 1px solid #999;
  background-color: #f0f0f0;
}

ul.ColVis_collection li span {
  display: inline-block;
  padding-left: 0.5em;
  cursor: pointer;
}
ul.ColVis_collection li.ColVis_Special {
  border-color: #555;
  background: #ededed;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #ededed 0%, #d6d6d6 77%, #e8e8e8 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #ededed 0%, #d6d6d6 77%, #e8e8e8 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #ededed 0%, #d6d6d6 77%, #e8e8e8 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #ededed 0%, #d6d6d6 77%, #e8e8e8 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #ededed 0%, #d6d6d6 77%, #e8e8e8 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ededed", endColorstr="#e8e8e8",GradientType=0 );
  /* IE6-9 */
}
ul.ColVis_collection li.ColVis_Special:hover {
  background: #e2e2e2;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #d0d0d0 0%, #d5d5d5 89%, #e2e2e2 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #d0d0d0 0%, #d5d5d5 89%, #e2e2e2 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #d0d0d0 0%, #d5d5d5 89%, #e2e2e2 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #d0d0d0 0%, #d5d5d5 89%, #e2e2e2 100%);
  /* Opera 11.10+ */
  background: linear-gradient(top, #d0d0d0 0%, #d5d5d5 89%, #e2e2e2 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f3f3f3", endColorstr="#e2e2e2",GradientType=0 );
  /* IE6-9 */
}

span.ColVis_radio {
  display: inline-block;
  width: 20px;
}

div.ColVis_catcher {
  position: absolute;
  z-index: 1101;
}

.disabled {
  color: #999;
}

/*
 * File:        TableTools.css
 * Description: Styles for TableTools 2
 * Author:      Allan Jardine (www.sprymedia.co.uk)
 * Language:    Javascript
 * License:     GPL v2 / 3 point BSD
 * Project:     DataTables
 *
 * Copyright 2009-2012 Allan Jardine, all rights reserved.
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * CSS name space:
 *   DTTT                  DataTables TableTools
 *
 * Style sheet provides:
 *   CONTAINER             TableTools container element and styles applying to all components
 *   BUTTON_STYLES         Action specific button styles
 *   SELECTING             Row selection styles
 *   COLLECTIONS           Drop down list (collection) styles
 *   PRINTING              Print display styles
 */
/*
 * CONTAINER
 * TableTools container element and styles applying to all components
 */
div.DTTT_container {
  position: relative;
  float: right;
  margin-bottom: 1em;
}

@media screen and (max-width: 640px) {
  div.DTTT_container {
    float: none !important;
    text-align: center;
  }
  div.DTTT_container:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}
button.DTTT_button,
div.DTTT_button,
a.DTTT_button {
  position: relative;
  display: inline-block;
  margin-right: 3px;
  padding: 5px 8px;
  border: 1px solid #999;
  cursor: pointer;
  *cursor: hand;
  font-size: 0.88em;
  color: black !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 1px 1px 3px #ccc;
  -moz-box-shadow: 1px 1px 3px #ccc;
  -ms-box-shadow: 1px 1px 3px #ccc;
  -o-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;
  /* Generated by http://www.colorzilla.com/gradient-editor/ */
  background: #ffffff;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* Opera 11.10+ */
  background: linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#f9f9f9",GradientType=0 );
  /* IE6-9 */
}
button.DTTT_button:hover,
div.DTTT_button:hover,
a.DTTT_button:hover {
  border: 1px solid #666;
  text-decoration: none !important;
  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  -ms-box-shadow: 1px 1px 3px #999;
  -o-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
  background: #f3f3f3;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* Opera 11.10+ */
  background: linear-gradient(top, #f3f3f3 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f3f3f3", endColorstr="#f4f4f4",GradientType=0 );
  /* IE6-9 */
}

/* Buttons are cunning border-box sizing - we can't just use that for A and DIV due to IE6/7 */
button.DTTT_button {
  height: 30px;
  padding: 3px 8px;
}

.DTTT_button embed {
  outline: none;
}

button.DTTT_disabled,
div.DTTT_disabled,
a.DTTT_disabled {
  color: #999;
  border: 1px solid #d0d0d0;
  background: #ffffff;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #ffffff 0%, #f9f9f9 89%, #fafafa 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #ffffff 0%, #f9f9f9 89%, #fafafa 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #f9f9f9 89%, #fafafa 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #ffffff 0%, #f9f9f9 89%, #fafafa 100%);
  /* Opera 11.10+ */
  background: linear-gradient(top, #ffffff 0%, #f9f9f9 89%, #fafafa 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#fafafa",GradientType=0 );
  /* IE6-9 */
}

/*
 * BUTTON_STYLES
 * Action specific button styles
 * If you want images - comment this back in

a.DTTT_button_csv,
a.DTTT_button_xls,
a.DTTT_button_copy,
a.DTTT_button_pdf,
a.DTTT_button_print {
	padding-right: 0px;
}

a.DTTT_button_csv span,
a.DTTT_button_xls span,
a.DTTT_button_copy span,
a.DTTT_button_pdf span,
a.DTTT_button_print span {
	display: inline-block;
	height: 24px;
	line-height: 24px;
	padding-right: 30px;
}

a.DTTT_button_csv span { background: url(../images/csv.png) no-repeat bottom right; }
a.DTTT_button_csv:hover span { background: url(../images/csv_hover.png) no-repeat center right; }

a.DTTT_button_xls span { background: url(../images/xls.png) no-repeat center right; }
a.DTTT_button_xls:hover span { background: #f0f0f0 url(../images/xls_hover.png) no-repeat center right; }

a.DTTT_button_copy span { background: url(../images/copy.png) no-repeat center right; }
a.DTTT_button_copy:hover span { background: #f0f0f0 url(../images/copy_hover.png) no-repeat center right; }

a.DTTT_button_pdf span { background: url(../images/pdf.png) no-repeat center right; }
a.DTTT_button_pdf:hover span { background: #f0f0f0 url(../images/pdf_hover.png) no-repeat center right; }

a.DTTT_button_print span { background: url(../images/print.png) no-repeat center right; }
a.DTTT_button_print:hover span { background: #f0f0f0 url(../images/print_hover.png) no-repeat center right; }

 */
button.DTTT_button_collection span {
  padding-right: 17px;
  /*background: url("../img/collection.png") no-repeat center right;*/
}

button.DTTT_button_collection:hover span {
  padding-right: 17px;
  background: #f0f0f0;
  /*background: #f0f0f0 url("../img/collection_hover.png") no-repeat center right;*/
}

/*
 * SELECTING
 * Row selection styles
 */
table.DTTT_selectable tbody tr {
  cursor: pointer;
  *cursor: hand;
}

table.dataTable tr.DTTT_selected.odd {
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.odd td.sorting_1 {
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.odd td.sorting_2 {
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.odd td.sorting_3 {
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.even {
  background-color: #B0BED9;
}

table.dataTable tr.DTTT_selected.even td.sorting_1 {
  background-color: #B0BED9;
}

table.dataTable tr.DTTT_selected.even td.sorting_2 {
  background-color: #B0BED9;
}

table.dataTable tr.DTTT_selected.even td.sorting_3 {
  background-color: #B0BED9;
}

/*
 * COLLECTIONS
 * Drop down list (collection) styles
 */
div.DTTT_collection {
  width: 150px;
  padding: 8px 8px 4px 8px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  z-index: 2002;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

div.DTTT_collection_background {
  /*background: transparent url("../img/background.png") repeat top left;*/
  z-index: 2001;
}

div.DTTT_collection button.DTTT_button,
div.DTTT_collection div.DTTT_button,
div.DTTT_collection a.DTTT_button {
  position: relative;
  left: 0;
  right: 0;
  display: block;
  float: none;
  margin-bottom: 4px;
  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  -ms-box-shadow: 1px 1px 3px #999;
  -o-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
}

/*
 * PRINTING
 * Print display styles
 */
.DTTT_print_info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 150px;
  margin-left: -200px;
  margin-top: -75px;
  text-align: center;
  color: #333;
  padding: 10px 30px;
  background: #ffffff;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* Opera 11.10+ */
  background: linear-gradient(top, #ffffff 0%, #f3f3f3 89%, #f9f9f9 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#f9f9f9",GradientType=0 );
  /* IE6-9 */
  opacity: 0.95;
  border: 1px solid black;
  border: 1px solid rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}
.DTTT_print_info h6 {
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
  margin: 1em;
}
.DTTT_print_info p {
  font-size: 14px;
  line-height: 20px;
}

table.has-columns-hidden > tbody > tr > td > span.responsiveExpander {
  background: url("../img/plus.png") no-repeat 5px center;
  padding-left: 32px;
  cursor: pointer;
}

table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander {
  background: url("../img/minus.png") no-repeat 5px center;
}

table.has-columns-hidden > tbody > tr.row-detail > td {
  background: #eee;
}

table.has-columns-hidden > tbody > tr.row-detail > td > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

table.has-columns-hidden > tbody > tr.row-detail > td > ul > li > span.columnTitle {
  font-weight: bold;
}

.dt-toolbar {
  display: block;
  position: relative;
  padding: 6px 7px 1px;
  width: 100%;
  float: left;
  border-bottom: 1px solid #ccc;
  background: #fafafa;
}

.dt-toolbar-footer {
  background: #fafafa;
  font-size: 11px;
  overflow: hidden;
  padding: 5px 10px;
  border-top: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px #fff;
  -moz-box-shadow: inset 0 1px #fff;
  -ms-box-shadow: inset 0 1px #fff;
  box-shadow: inset 0 1px #fff;
}

.dt-toolbar > :first-child, .dt-toolbar-footer > :first-child {
  padding-left: 0px !important;
}
.dt-toolbar > :last-child, .dt-toolbar-footer > :last-child {
  padding-right: 0px !important;
}

table.dataTable thead > tr > th, table.dataTable tfoot > tr > th {
  padding-left: 9px !important;
}
table.dataTable thead > tr > th input, table.dataTable tfoot > tr > th input {
  width: 100% !important;
  font-weight: normal;
}

.dataTables_empty {
  padding: 20px 10px !important;
  font-size: 14px;
  text-align: center;
  color: #575757;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 8px 10px;
}

div.dataTables_info {
  padding-top: 9px;
  font-size: 13px;
  font-weight: bold;
  font-style: italic;
  color: #969696;
}

/*
* Double lines in thead tfoot
*/
.dataTable thead > tr > th {
  border-left: none !important;
}

/* checkbox */
.dataTable input[type=checkbox].checkbox,
.dataTable input[type=radio].radiobox {
  left: -999px;
}

/*
* DT FIlter
*/
.dataTables_filter {
  float: left;
  width: 100%;
}
.dataTables_filter .input-group-addon {
  width: 32px;
  margin-top: 0px;
  float: left;
  height: 32px;
  padding-top: 8px;
}
.dataTables_filter .input-group-addon + .form-control {
  float: left;
}

/*
* LTR filter position
*/
.dt-toolbar > :first-child .dataTables_filter > :only-child, .dt-toolbar > :first-child .DTTT, .dt-toolbar > :first-child .pagination, .dt-toolbar > :first-child .dataTables_length {
  float: left !important;
}
.dt-toolbar > :last-child .dataTables_filter > :only-child, .dt-toolbar > :last-child .DTTT, .dt-toolbar > :last-child .pagination, .dt-toolbar > :last-child .dataTables_length {
  float: right;
}

/* bottom portion */
.dt-toolbar-footer > :first-child .dataTables_filter > :only-child, .dt-toolbar-footer > :first-child .DTTT, .dt-toolbar-footer > :first-child .pagination, .dt-toolbar-footer > :first-child .dataTables_length {
  float: left;
}
.dt-toolbar-footer > :last-child .dataTables_filter > :only-child, .dt-toolbar-footer > :last-child .DTTT, .dt-toolbar-footer > :last-child .pagination, .dt-toolbar-footer > :last-child .dataTables_length {
  float: right;
}

/*
* PRINT
*/
body.DTTT_Print {
  background: #fff !important;
}

.DTTT_Print #main {
  margin: 0px !important;
}

.DTTT_PrintMessage {
  font-size: 20px;
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  font-weight: bold;
}

.DTTT_Print table.dataTable {
  margin-top: 0px !important;
}

.DTTT_Print .dataTables_wrapper table {
  border: 1px solid #C7C7C7 !important;
}

/*
* COLUMN SHOW/HIDE
*/
div.ColVis {
  margin-bottom: 0px !important;
}

ul.ColVis_collection {
  width: 127px;
}
ul.ColVis_collection span {
  vertical-align: 3px;
}
ul.ColVis_collection label {
  margin-bottom: 0px !important;
}

/*
 * Processing Fix
 */
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}

/* custom font icon */
.dataTable tbody .details-control {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.dataTable tbody .details-control:before {
  font-family: "FontAwesome";
  content: "";
  font-size: 1.4em;
  color: #15660e;
}

.dataTable tbody .shown .details-control:before {
  content: "";
  color: #a90329;
}

.dataTable tbody .shown + tr > td {
  padding: 0px !important;
}

.dataTable tbody .shown {
  background: rgba(205, 209, 98, 0.2) !important;
}

.dataTable tbody .shown > td {
  background: none !important;
}

.dataTable tbody .shown + tr > td > table > tbody > tr:last-child {
  border-bottom: 1px solid #c2c2c2;
}

.dataTable tbody .shown + tr > td > table tbody tr {
  background-color: rgba(205, 209, 98, 0.05) !important;
}

.table-bordered .progress {
  background: #fff !important;
  -webkit-box-shadow: 0 1px 0 transparent, 0 0 0 1px #D6D6D6 inset;
  -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px #D6D6D6 inset;
  box-shadow: 0 1px 0 transparent, 0 0 0 1px #D6D6D6 inset;
}

/* datatable scroll-y bug fix for firefox */
.dataTables_scroll .dataTables_scrollHead,
.dataTables_scroll .dataTables_scrollBody,
.dataTables_scroll + .dt-toolbar-footer {
  float: left;
}

.dataTables_scroll + .dt-toolbar-footer {
  width: 100%;
}

.dataTables_scroll .dataTables_scrollHeadInner {
  margin-top: -6px;
}

.dataTables_scroll .dataTables_scrollHead {
  background: #f4f4f4;
}

/*
  Copyright 2013 - SmartAdmin : WebApp (Sunny)

  * This is part of an item on wrapbootstrap.com
  * https://wrapbootstrap.com/user/myorange
  * ==================================

  Table of Contents
  ---------------------------------------------------------------

    - Main
    - Header
    - Content
    - Edit box
    - Timestamp
    - Helpers
    - Fullscreen
    - Styling
    - Custom styles
    - Icons - A few used examples
    - RTL support
    - Clearfix
    - Examples (minimalistic in grid)
    - Examples (lists)

*/
/*
 * MAIN
 */
article.sortable-grid {
  min-height: 30px;
}

.jarviswidget {
  margin: 0 0 30px 0;
  position: relative;
  -moz-border-radius: 0px;
  -khtml-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  padding: 0;
}

.widget-content-padding {
  padding: 20px;
}
.widget-content-padding .well {
  margin-bottom: 0px;
}

/*
 * HEADER
 */
.jarviswidget > header {
  height: 34px;
  /* make sure ".jarviswidget-loader" is also the same height or it will flicker during collapse */
  padding: 0;
  line-height: 40px;
}
.jarviswidget > header > :first-child.widget-icon {
  margin-left: 0;
}
.jarviswidget > header .jarviswidget-icon {
  width: 16px;
  height: 16px;
  float: left;
  margin: 12px 10px 0 0;
  display: block;
  zoom: 1;
  z-index: 4000;
}
.jarviswidget > header > .widget-icon {
  display: block;
  width: 30px;
  height: 32px;
  position: relative;
  float: left;
  font-size: 111%;
  line-height: 32px;
  text-align: center;
  margin-right: -10px;
}
.jarviswidget > header > h2 {
  margin-left: 10px !important;
  float: left;
}
.jarviswidget > header h2 {
  height: 100%;
  width: auto;
  display: inline-block;
  font-size: 14px;
  position: relative;
  margin: 0;
  line-height: 34px;
  font-weight: normal;
  letter-spacing: 0px;
}

.ui-sortable .jarviswidget-sortable > header {
  cursor: move;
  -moz-border-radius: 0px;
  -khtml-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  line-height: normal;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
}
.ui-sortable .jarviswidget-sortable.jarviswidget-collapsed > header {
  -moz-border-radius: 0px;
  -khtml-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.jarviswidget-ctrls {
  width: auto;
  float: right;
  padding: 0;
  margin: 0;
}
.jarviswidget-ctrls .button-icon {
  min-width: 30px;
  height: 32px;
  float: left;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  border-left: 1px solid rgba(0, 0, 0, 0.09);
}
.jarviswidget-ctrls .button-icon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.jarviswidget-loader {
  width: 32px;
  height: 32px;
  margin: 0;
  float: right;
  background-repeat: no-repeat;
  background-position: center center;
  display: none;
  text-align: center;
  line-height: 32px;
  font-size: 111%;
}

/*
 * CONTENT
 */
.jarviswidget > div {
  float: left;
  width: 100%;
  position: relative;
  font-size: 13px;
  -moz-border-radius: 0px;
  -khtml-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  margin: 0;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-top: none;
  border-right-color: #CCC !important;
  border-bottom-color: #CCC !important;
  border-left-color: #CCC !important;
  padding: 13px 13px 0px;
  /* as per version 1.3 */
  overflow: visible;
}
.jarviswidget .widget-body {
  min-height: 100px;
  position: relative;
  padding-bottom: 13px;
}
.jarviswidget .widget-body.widget-hide-overflow {
  overflow: hidden;
}

.widget-toolbar.no-border {
  border-left: none;
}

.widget-body.no-padding {
  margin: -13px -13px 0px;
}

.jarviswidget.well.transparent .widget-body.no-padding {
  margin: 0px !important;
}

.widget-body > table {
  margin-bottom: 0;
}

.widget-body.no-padding .alert {
  margin: 0 0 10px;
  padding: 10px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  border-width: 0px 0px 1px !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}

.widget-body.no-padding .cke_chrome, .no-padding .md-editor {
  border: none;
}

.widget-toolbar > .smart-form {
  margin-top: 2px;
}

.widget-toolbar > .smart-form .toggle:last-child {
  font-size: 12px;
  line-height: 29px;
}

.widget-toolbar > .smart-form .radio input + i,
.widget-toolbar > .smart-form .checkbox input + i,
.widget-toolbar > .smart-form .toggle input + i {
  border-width: 1px;
  border-color: #C7C7C7 !important;
  margin-top: 1px;
  box-shadow: 0 1px 1px #FFF, 0 1px 1px #858585 inset;
  -webkit-box-shadow: 0 1px 1px #FFF, 0 1px 1px #858585 inset;
  -moz-box-shadow: 0 1px 1px #FFF, 0 1px 1px #858585 inset;
}

/*
 * WELL WIDGET
 */
.jarviswidget.well {
  margin: 0 0 30px 0;
}
.jarviswidget.well > div {
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}
.jarviswidget.well header {
  display: none;
}

/*
 * WIDGET FOOTER
 */
.widget-footer {
  display: block;
  min-height: 32px;
  vertical-align: middle;
  position: relative;
  background-color: #F8F7F7;
  padding: 5px;
  border-top: 1px solid #E4E4E4;
  text-align: right;
  margin: 0px -13px -13px;
}
.no-padding .widget-footer {
  margin: 0;
}
.widget-footer.smart-form > label {
  margin-top: 4px;
  display: block;
}

/*
 * EDIT BOX
 */
.jarviswidget-editbox {
  display: none;
  padding: 10px;
}

/*.jarviswidget-editbox div {
width: 100%;
float: left;
margin-bottom: 0;
}
.jarviswidget-editbox label {
width: 80px;
height: 26px;
float: left;
line-height: 26px;
font-weight: bold;
margin-bottom:0;
}
.jarviswidget-editbox input {
width: 200px;
height: 24px;
padding: 0 5px;
border: 1px solid #666;
}
.jarviswidget-editbox span {
height: 26px;
width: 26px;
float: left;
margin: 0 5px 0 0;
cursor: pointer;
}*/
/*
 * TIMESTAMP
 */
.jarviswidget-timestamp {
  font-size: 12px;
  color: #868686;
  font-style: italic;
  margin: 10px 0 0;
}

/*
 * HELPERS
 */
.jarviswidget-placeholder {
  -moz-border-radius: 0px;
  -khtml-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  margin-bottom: 28px;
  /* adjusted from 28px */
  padding: 0;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

/*
 * FULL SCREEN
 */
body.nooverflow {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

#jarviswidget-fullscreen-mode {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
}
#jarviswidget-fullscreen-mode .jarviswidget {
  margin: 0;
  -moz-border-radius: 0;
  -khtml-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#jarviswidget-fullscreen-mode .jarviswidget > div {
  overflow-y: scroll;
  -moz-border-radius: 0;
  -khtml-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#jarviswidget-fullscreen-mode .jarviswidget > div > header {
  cursor: default;
}

/*
 * WIDGET BODY TOOLBAR
 */
.widget-body-toolbar, .smart-form.widget-body-toolbar {
  display: block;
  padding: 8px 10px;
  margin: -13px -13px 13px;
  min-height: 42px;
  border-bottom: 1px solid #ccc;
  background: #fafafa;
}

.no-padding.widget-body-toolbar, .no-padding .widget-body-toolbar, .no-padding .smart-form.widget-body-toolbar {
  display: block;
  margin: 0px;
}

.widget-body-toolbar.smart-form .inline-group, .smart-form .widget-body-toolbar .inline-group {
  float: left;
  margin-top: 4px;
}

.widget-body-toolbar .btn {
  vertical-align: middle;
}

.widget-body-toolbar .btn-xs {
  margin-top: 5px;
}

/*
 * WIDGET BODY AJAX LOAD
 */
.widget-body-ajax-loading::before {
  content: url("../img/ajax-loader.gif");
  padding-top: 18%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  display: block;
  background: rgba(255, 255, 255, 0.4);
  height: 100%;
  z-index: 1;
  width: 100%;
  position: absolute;
}

.widget-body-ajax-loading:hover {
  cursor: wait !important;
}

/*
 * STYLING
 */
.jarviswidget > header {
  color: #333;
  border: 1px solid #C2C2C2;
  background: #fafafa;
}
.jarviswidget > div {
  background-color: #fff !important;
}

.jarviswidget-editbox {
  border-bottom: 1px solid #B1B1B1;
  background-color: #fff;
  margin: -13px -13px 13px;
}
.no-padding .jarviswidget-editbox {
  margin: 0 0 10px;
}

.jarviswidget-placeholder {
  background-color: #FFC;
  border: 1px dashed #A7A7A7;
}

/*
 * CUSTOM STYLES
 */
/* remove colors */
.jarviswidget-remove-colors {
  color: #333 !important;
  padding: 0 !important;
  background: none !important;
}

/* magenta theme */
.jarviswidget-color-magenta > header {
  background: #6E3671;
  color: #FFF;
}
.jarviswidget-color-magenta > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-magenta .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-magenta .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-magenta > header {
  border-color: #6E3671 !important;
}

/* pink theme */
.jarviswidget-color-pink > header {
  background: #AC5287;
  color: #FFF;
}
.jarviswidget-color-pink > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-pink .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-pink .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-pink > header {
  border-color: #AC5287 !important;
}

/* pinkDark theme */
.jarviswidget-color-pinkDark > header {
  background: #A8829F;
  color: #FFF;
}
.jarviswidget-color-pinkDark > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-pinkDark .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-pinkDark .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-pinkDark > header {
  border-color: #A8829F !important;
}

/* yellow theme */
.jarviswidget-color-yellow > header {
  background: #B09B5B;
  color: #FFF;
}
.jarviswidget-color-yellow > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-yellow .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-yellow .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-yellow > header {
  border-color: #B09B5B !important;
}

/* orange theme */
.jarviswidget-color-orange > header {
  background: #C79121;
  color: #FFF;
}
.jarviswidget-color-orange > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-orange .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-orange .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-orange > header {
  border-color: #C79121 !important;
}

/* orangeDark theme */
.jarviswidget-color-orangeDark > header {
  background: #A57225;
  color: #FFF;
}
.jarviswidget-color-orangeDark > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-orangeDark .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-orangeDark .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-orangeDark > header {
  border-color: #A57225 !important;
}

/* darken theme */
.jarviswidget-color-darken > header {
  background: #404040;
  color: #FFF;
}
.jarviswidget-color-darken > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-darken > .nav-tabs > li:not(.active) > a {
  color: #fff !important;
}
.jarviswidget-color-darken .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-darken > header {
  border-color: #404040 !important;
}

.jarviswidget-color-bluemega > header {
  background: #008BD1;
  color: #FFF;
}
.jarviswidget-color-bluemega > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-bluemega > .nav-tabs > li:not(.active) > a {
  color: #fff !important;
}
.jarviswidget-color-bluemega .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-bluemega > header {
  border-color: #008BD1 !important;
}

/* purple theme */
.jarviswidget-color-purple > header {
  background: #65596B;
  color: #FFF;
}
.jarviswidget-color-purple > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-purple .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-purple .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-purple > header {
  border-color: #65596B !important;
}

/* teal theme */
.jarviswidget-color-teal > header {
  background: #568A89;
  color: #FFF;
}
.jarviswidget-color-teal > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-teal .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-teal .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-teal > header {
  border-color: #568A89 !important;
}

/* teal theme */
.jarviswidget-color-blueDark > header {
  background: #4C4F53;
  color: #FFF;
}
.jarviswidget-color-blueDark > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-blueDark .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-blueDark .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-blueDark > header {
  border-color: #4C4F53 !important;
}

/* blue theme */
.jarviswidget-color-blue > header {
  background: #60747C;
  color: #FFF;
}
.jarviswidget-color-blue > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-blue .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-blue .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-blue > header {
  border-color: #60747C !important;
}

/* blueLight theme */
.jarviswidget-color-blueLight > header {
  background: #CCDADF;
  color: #2b363c;
}
.jarviswidget-color-blueLight > header > .jarviswidget-ctrls a {
  color: #2b363c !important;
}
.jarviswidget-color-blueLight .nav-tabs li:not(.active) a, .jarviswidget-color-blueLight .nav-tabs li a:hover {
  color: #2b363c !important;
}
.jarviswidget-color-blueLight > header {
  border-color: #92A2A8 !important;
}

/* red theme */
.jarviswidget-color-red > header {
  background: #931313;
  color: #FFF;
}
.jarviswidget-color-red > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-red .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-red .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-red > header {
  border-color: #931313 !important;
}

/* redLight theme */
.jarviswidget-color-redLight > header {
  background: #A65858;
  color: #FFF;
}
.jarviswidget-color-redLight > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-redLight .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-redLight .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-redLight > header {
  border-color: #A65858 !important;
}

/* white theme */
.jarviswidget-color-white > header {
  background: #fff;
  color: #838383;
}
.jarviswidget-color-white > header > .jarviswidget-ctrls a {
  color: #838383 !important;
}
.jarviswidget-color-white .nav-tabs li:not(.active) a, .jarviswidget-color-white .nav-tabs li a:hover {
  color: #838383 !important;
}
.jarviswidget-color-white > header {
  border-color: #C8C8C8 !important;
}

/* greenDark theme */
.jarviswidget-color-greenDark > header {
  background: #496949;
  color: #FFF;
}
.jarviswidget-color-greenDark > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-greenDark .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-greenDark .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-greenDark > header {
  border-color: #638167 !important;
}

/* green theme */
.jarviswidget-color-green > header {
  background: #356E35;
  color: #FFF;
}
.jarviswidget-color-green > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-green .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-green .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-green > header {
  border-color: #638167 !important;
}

/* greenLight theme */
.jarviswidget-color-greenLight > header {
  background: #71843F;
  color: #FFF;
}
.jarviswidget-color-greenLight > header > .jarviswidget-ctrls a {
  color: #fff !important;
}
.jarviswidget-color-greenLight .nav-tabs li:not(.active) a {
  color: #fff !important;
}
.jarviswidget-color-greenLight .nav-tabs li a:hover {
  color: #333 !important;
}
.jarviswidget-color-greenLight > header {
  border-color: #638167 !important;
}

/*
 * WIDGET COLOR DROPDOWN
 */
.color-select {
  list-style: none;
  margin: 0;
  padding: 4px;
  min-width: 166px;
  max-width: 156px;
  right: -3px;
}
.color-select li {
  display: block;
  margin: 2px;
  float: left;
}
.color-select li span:hover {
  border: 3px solid rgba(0, 0, 0, 0.2);
}

.color-select li span, .color-box {
  display: block;
  width: 22px;
  height: 22px;
  padding: 0;
  background: #333;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.color-box {
  position: relative;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.3) !important;
}
.color-box:hover, .open .color-box {
  outline: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.color-box:hover {
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
}
.color-box:active {
  top: 1px;
  left: 1px;
}
.widget-toolbar > .color-box {
  margin-top: 7px;
  width: 18px;
  height: 18px;
  outline: 1px solid rgba(0, 0, 0, 0.2);
}
.widget-toolbar > .color-box:hover, .widget-toolbar > .color-box:active, .widget-toolbar > .color-box:focus {
  outline: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.widget-toolbar .dropdown-menu.color-select {
  right: -1px;
}

/*
 * ICONS
 */
.jarviswidget-ctrls a {
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  line-height: 32px;
  padding: 0;
  margin: 0;
  color: #333;
}

/*
 * RTL - SUPPORT

.rtl {
  .jarviswidget > header {
    padding: 0 7px 0 0;
    h2 {
      float: right;
      text-align: right
    }
    .jarviswidget-icon {
      float: right;
      margin: 12px 0 0 7px;
    }
  }
  .jarviswidget-ctrls {
    float: left;
    padding: 10px 0 0 3px;
    margin: 0;
    .button-icon { margin: 0 0 5px 5px; }
  }
  .jarviswidget-loader { float: left; }
}*/
/*
 * CLEARFIX
 */
.jarviswidget:before, .jarviswidget:after, .jarviswidget > div:before, .jarviswidget > div:after, .inner-spacer:before, .inner-spacer:after, .jarviswidget-editbox:before, .jarviswidget-editbox:after, .clearfix:before, .clearfix:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
}

.jarviswidget:after, .jarviswidget > div:after, .inner-spacer:after, .jarviswidget-editbox:after, .clearfix:after {
  clear: both;
}

.jarviswidget, .jarviswidget > div, .inner-spacer:after, .jarviswidget-editbox, .clearfix {
  zoom: 1;
  /*IE*/
}

/*
 * WIDGET TOOLBARS
 */
.widget-toolbar {
  display: inline-block;
  float: right;
  width: auto;
  height: 32px;
  line-height: 32px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.09);
  cursor: pointer;
  padding: 0 8px;
  text-align: center;
}
.widget-toolbar > :first-child {
  text-align: left;
}

/*
 * WIDGET TOOLBAR COMPONENTS
 */
/*
 * TABS
 */
.jarviswidget header .nav-tabs {
  border-bottom-color: transparent;
}
.jarviswidget header .nav-tabs > li > a {
  border-radius: 0px;
  border: none;
  padding: 7px 15px 6px;
}
.jarviswidget header .nav-tabs > li.active > a, .jarviswidget header .nav-tabs > li.active > a:hover, .jarviswidget header .nav-tabs > li.active > a:focus {
  color: #555;
  background-color: #FFF;
  border: 1px solid #C2C2C2;
  border-bottom-color: transparent;
  border-top: none;
  cursor: default;
}

.jarviswidget header:first-child .nav-tabs {
  float: left;
}
.jarviswidget header:first-child .nav-tabs li {
  padding-left: 0;
}
.jarviswidget header:first-child .nav-tabs li a {
  color: #333;
}
.jarviswidget header:first-child .nav-tabs.pull-right li:last-child a {
  margin-right: 0px !important;
  border-right: none !important;
}

/*
 * PILLS
 */
.jarviswidget header .nav-pills {
  margin: 3px;
}
.jarviswidget header .nav-pills > li > a {
  padding: 3px 5px 4px;
}

/*
 * BTN GROUPS
 */
.widget-toolbar .btn-group {
  margin-top: -3px;
}

/*
 * BTNs
 */
.widget-toolbar > .btn {
  margin-top: -3px;
  font-size: 12px !important;
  padding: 1px 8px !important;
}

/*
 * INPUT
 */
.widget-toolbar .smart-form label.checkbox, .widget-toolbar .smart-form label.radio {
  line-height: 29px;
}

/*
 * LABELS
 */
.widget-toolbar > .label {
  vertical-align: middle;
  margin-top: -3px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  padding: 4px 7px;
}

/*
 * BADGE
 */
.widget-toolbar > .badge {
  padding: 5px 5px;
  font-size: 14px;
  border-radius: 50%;
  font-weight: normal;
  min-width: 24px;
  text-align: center !important;
}

/*
 * PROGRESS BARS
 */
.widget-toolbar .progress {
  width: 130px;
  margin: 7px 0 0 0;
  height: 18px !important;
  font-size: 12px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #D1D1D1 inset;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #D1D1D1 inset;
  -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px #D1D1D1 inset;
}

.widget-toolbar .progress-bar {
  font-size: 12px;
}

/*
 * PAGINATION
 */
.widget-toolbar .pagination {
  margin: 4px 0;
}
.widget-toolbar .pagination > li > a {
  padding: 2px 7px;
}

/*
 * TEXT INPUT
 */
.widget-toolbar .widget-text-input {
  max-width: 220px;
}

.widget-toolbar input[type=text] {
  height: 28px !important;
  margin-top: 2px;
}

.widget-toolbar.smart-form .icon-append, .widget-toolbar.smart-form .icon-prepend,
.widget-toolbar .smart-form .icon-append, .widget-toolbar .smart-form .icon-prepend {
  top: 3px !important;
}

/*
 * Enhancements for other plugins 
 */
#jarviswidget-fullscreen-mode .fc-grid .fc-day-number {
  padding: 5px 12px;
  font-size: 40px;
}

/*
 * PACE PROGRESS BAR
 */
@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #3276b1;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 2px;
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 63px;
  right: 40px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}

.jarviswidget header {
  height: 34px;
  padding: 0;
  line-height: 34px;
}

.jarviswidget > div.noborder {
  border: none;
}
.jarviswidget header {
  color: #ffffff;
}
.jarviswidget header h2 {
  color: #ffffff;
}
.jarviswidget header .header_icon a {
  margin-left: 10px;
  color: #ffffff;
  font-size: 15px;
}
.jarviswidget header .header_icon a i {
  font-size: 15px;
}
.jarviswidget header .header_icon a:hover {
  text-decoration: none;
  font-weight: 600;
}
.jarviswidget .no-padding {
  padding: 0px;
}
.jarviswidget .no-padding table {
  margin: 0px;
}

/*Messagebox */
.divMessageBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000;
}

/*
.MessageIE {
  background-image: url("../img/bg.png");    
}*/
.MessageBoxContainer {
  position: relative;
  top: 35%;
  color: white;
  position: relative;
  width: 100%;
  background-color: #232323;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
}
.MessageBoxContainer input {
  width: 50%;
  padding: 5px;
}
.MessageBoxContainer select {
  width: 50%;
  padding: 5px;
}

.MessageBoxMiddle {
  position: relative;
  left: 20%;
  width: 60%;
}
.MessageBoxMiddle .MsgTitle {
  letter-spacing: -1px;
  font-size: 24px;
  font-weight: 300;
}
.MessageBoxMiddle .pText {
  font-style: 30px;
}

.MessageBoxButtonSection {
  width: 100%;
  height: 30px;
}
.MessageBoxButtonSection button {
  float: right;
  margin-right: 7px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: bold;
}

/* End of MessageBox */
.LoadingBoxContainer {
  position: relative;
  top: 20%;
  color: white;
  position: relative;
  width: 100%;
  background-color: #232323;
}
.LoadingBoxContainer .MsgTitle {
  font-size: 26px;
}
.LoadingBoxContainer .pText {
  font-style: 30px;
}

.LoadingBoxMiddle {
  position: relative;
  left: 20%;
  width: 50%;
  padding: 10px;
}

#LoadingPoints {
  position: absolute;
}

/* bigBoxes */
.bigBox {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: #004d60;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  width: 390px;
  height: 150px;
  color: white;
  z-index: 99999;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  border-left: 5px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.bigBox span {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: -1px;
  padding: 5px 0 !important;
  display: block;
}
.bigBox p {
  font-size: 13px;
  margin-top: 10px;
}
.bigBox .bigboxicon {
  font-size: 30px;
  text-align: left;
  position: absolute;
  top: 120px;
  left: 6px;
  z-index: 0;
}
.bigBox .bigboxnumber {
  width: 100%;
  text-align: right;
  font-size: 25px;
}

#divMiniIcons {
  position: fixed;
  width: 415px;
  right: 10px;
  bottom: 180px;
  z-index: 9999;
  float: right;
}
#divMiniIcons .cajita {
  text-align: center;
  vertical-align: middle;
  padding: 4px 6px;
  color: #FFF;
  float: right;
  cursor: pointer;
  display: block;
  background-color: #F00;
  font-size: 17px;
  margin-left: 4px;
  margin-top: 5px;
}
#divMiniIcons .cajita:active {
  top: 1px;
  left: 1px;
  position: relative;
}

.botClose {
  position: absolute;
  right: 10px;
  height: 16px;
  width: 15px;
  cursor: pointer;
  font-size: 18px;
  opacity: 0.5;
  display: block;
  top: 15px;
}
.botClose:hover {
  opacity: 1;
}

.cajita img {
  width: 23px;
  height: 23px;
  padding-left: 3px;
  padding-top: 3px;
}

/* End of  bigBoxes */
/* SmallBox */
#divSmallBoxes {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.SmallBox {
  position: absolute;
  right: 5px;
  top: 20px;
  width: 420px;
  color: white;
  z-index: 9999;
  overflow: hidden;
  border: 1px solid transparent;
}
.SmallBox:hover {
  border: 1px solid #fff;
  cursor: pointer;
}
.SmallBox .foto {
  font-size: 50px;
  position: absolute;
  left: 17px;
}
.SmallBox .textoFull {
  width: 93%;
  float: left;
  padding-left: 20px;
}
.SmallBox .textoFoto {
  width: 78%;
  margin: 3px 20px 3px 80px;
  float: left;
}
.SmallBox span {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: -1px;
  display: block;
  margin: 4px 0;
}
.SmallBox p {
  font-size: 13px;
  margin-top: 2px;
}

.miniPic {
  position: absolute;
  bottom: 8px;
  right: 9px;
}

.miniIcono {
  height: 100%;
  font-size: 20px;
}

/* End of SmallBox */
#pageslide {
  /* These styles MUST be included. Do not change. */
  display: none;
  position: absolute;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  /* Specify the width of your pageslide here */
  width: 305px;
  padding: 20px;
  /* These styles are optional, and describe how the pageslide will look */
  background-color: #004d60;
  color: #fff;
  -webkit-box-shadow: inset 0 0 5px 5px #222;
  -moz-shadow: inset 0 0 0px 0px #222;
  box-shadow: inset 0 0 0px 0px #222;
}

.purehtml {
  color: white;
  font-size: 16px;
}

/* Mobile Phones */
@media screen and (max-width: 450px) and (max-width: 767px) {
  /* SmallBox Responsive */
  #divSmallBoxes {
    position: fixed;
    width: 90%;
    right: 0;
    top: 0;
  }

  .SmallBox {
    width: 95%;
  }
  .SmallBox:hover {
    box-shadow: 0px 0px 10px #888888;
    cursor: pointer;
  }
  .SmallBox span {
    font-size: 16px;
    /*font-weight: bold;*/
  }
  .SmallBox p {
    font-size: 12px;
    margin-top: 2px;
  }
  .SmallBox .textoFull {
    width: 93%;
    float: left;
    padding-left: 20px;
  }
  .SmallBox .textoFoto {
    width: 55%;
    margin: 3px 20px 3px 80px;
    float: left;
  }

  /* End of SmallBox Responsive*/
  /* bigBoxes Responsive */
  .bigBox {
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: #004d60;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    width: 88%;
    height: 150px;
    color: white;
    z-index: 99999;
  }

  /* End of  bigBoxes  Responsive */
  /*Messagebox Responsive */
  .divMessageBox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100000;
  }

  .MessageBoxContainer {
    position: relative;
    top: 25%;
    color: white;
    position: relative;
    width: 100%;
    background-color: #232323;
  }

  .MessageBoxMiddle {
    position: relative;
    left: 0;
    width: 100%;
    padding: 3px;
  }

  .MessageBoxMiddle .MsgTitle {
    font-size: 22px;
  }

  .MessageBoxMiddle .pText {
    font-style: 10px;
  }

  .MessageBoxContainer input {
    width: 50%;
    padding: 5px;
  }

  .MessageBoxContainer select {
    width: 50%;
    padding: 5px;
  }

  .MessageBoxButtonSection {
    width: 100%;
    height: 30px;
  }

  .MessageBoxButtonSection button {
    float: right;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
  }

  /* End of MessageBox Responsive */
}
/* Animations */
.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.fast {
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  -ms-animation-duration: 0.4s;
  -o-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
}
@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
}
@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.fast {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-moz-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-o-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
.animated.flash {
  -webkit-animation-name: flash;
  -moz-animation-name: flash;
  -o-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
  }
}
@-moz-keyframes shake {
  0%, 100% {
    -moz-transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px);
  }
}
@-o-keyframes shake {
  0%, 100% {
    -o-transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -o-transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -o-transform: translateX(10px);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}
.animated.shake {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.animated.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}
@-moz-keyframes tada {
  0% {
    -moz-transform: scale(1);
  }
  10%, 20% {
    -moz-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -moz-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -moz-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -moz-transform: scale(1) rotate(0);
  }
}
@-o-keyframes tada {
  0% {
    -o-transform: scale(1);
  }
  10%, 20% {
    -o-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -o-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -o-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -o-transform: scale(1) rotate(0);
  }
}
@keyframes tada {
  0% {
    transform: scale(1);
  }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
.animated.tada {
  -webkit-animation-name: tada;
  -moz-animation-name: tada;
  -o-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-moz-keyframes swing {
  20% {
    -moz-transform: rotate(15deg);
  }
  40% {
    -moz-transform: rotate(-10deg);
  }
  60% {
    -moz-transform: rotate(5deg);
  }
  80% {
    -moz-transform: rotate(-5deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}
@-o-keyframes swing {
  20% {
    -o-transform: rotate(15deg);
  }
  40% {
    -o-transform: rotate(-10deg);
  }
  60% {
    -o-transform: rotate(5deg);
  }
  80% {
    -o-transform: rotate(-5deg);
  }
  100% {
    -o-transform: rotate(0deg);
  }
}
@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.animated.swing {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  -moz-animation-name: swing;
  -o-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes wobble {
  0% {
    -moz-transform: translateX(0%);
  }
  15% {
    -moz-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -moz-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -moz-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -moz-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -moz-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -moz-transform: translateX(0%);
  }
}
@-o-keyframes wobble {
  0% {
    -o-transform: translateX(0%);
  }
  15% {
    -o-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -o-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -o-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -o-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -o-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -o-transform: translateX(0%);
  }
}
@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    transform: translateX(0%);
  }
}
.animated.wobble {
  -webkit-animation-name: wobble;
  -moz-animation-name: wobble;
  -o-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.1);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }
  50% {
    -o-transform: scale(1.1);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.animated.pulse {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
}
@-moz-keyframes flip {
  0% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -moz-animation-timing-function: ease-out;
  }
  40% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -moz-animation-timing-function: ease-out;
  }
  50% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }
  80% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -moz-animation-timing-function: ease-in;
  }
  100% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }
}
@-o-keyframes flip {
  0% {
    -o-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -o-animation-timing-function: ease-out;
  }
  40% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -o-animation-timing-function: ease-out;
  }
  50% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-animation-timing-function: ease-in;
  }
  80% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -o-animation-timing-function: ease-in;
  }
  100% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -o-animation-timing-function: ease-in;
  }
}
@keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flip;
  -o-backface-visibility: visible !important;
  -o-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@-moz-keyframes flipInX {
  0% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -moz-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -moz-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@-o-keyframes flipInX {
  0% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -o-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -o-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
.animated.flipInX {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInX;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutX {
  0% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutX {
  0% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
.animated.flipOutX {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -moz-animation-name: flipOutX;
  -moz-backface-visibility: visible !important;
  -o-animation-name: flipOutX;
  -o-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@-moz-keyframes flipInY {
  0% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -moz-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -moz-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@-o-keyframes flipInY {
  0% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -o-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -o-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
.animated.flipInY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutY {
  0% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutY {
  0% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
.animated.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipOutY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animated.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  -moz-animation-name: fadeInUpBig;
  -o-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  -moz-animation-name: fadeInLeftBig;
  -o-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  -moz-animation-name: fadeInRightBig;
  -o-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.animated.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
.animated.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
}
@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
}
@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
.animated.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
}
@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
}
@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}
.animated.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
}
@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
}
@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}
.animated.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}
@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}
@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}
@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}
.animated.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  -moz-animation-name: fadeOutUpBig;
  -o-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}
@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}
@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}
@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}
.animated.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  -moz-animation-name: fadeOutDownBig;
  -o-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}
@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}
@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}
@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}
.animated.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  -moz-animation-name: fadeOutLeftBig;
  -o-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}
@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}
@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}
.animated.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  -moz-animation-name: fadeOutRightBig;
  -o-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }
  70% {
    -moz-transform: scale(0.9);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }
  70% {
    -o-transform: scale(0.9);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    transform: translateY(0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  -moz-animation-name: slideInDown;
  -o-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes slideInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  100% {
    transform: translateX(0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  -moz-animation-name: slideInLeft;
  -o-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes slideInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  100% {
    transform: translateX(0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  -moz-animation-name: slideInRight;
  -o-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}
@-moz-keyframes slideOutLeft {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}
@-o-keyframes slideOutLeft {
  0% {
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}
@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  -moz-animation-name: slideOutLeft;
  -o-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}
@-moz-keyframes slideOutRight {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}
@-o-keyframes slideOutRight {
  0% {
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}
@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  -moz-animation-name: slideOutRight;
  -o-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}
@-moz-keyframes slideOutUp {
  0% {
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}
@-o-keyframes slideOutUp {
  0% {
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}
@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  -moz-animation-name: slideOutUp;
  -o-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.animated.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }
  80% {
    -moz-transform: translateY(10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }
  80% {
    -o-transform: translateY(10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.animated.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }
  80% {
    -moz-transform: translateY(-10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }
  80% {
    -o-transform: translateY(-10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.animated.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }
  80% {
    -moz-transform: translateX(-10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }
  80% {
    -o-transform: translateX(-10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
.animated.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }
  80% {
    -moz-transform: translateX(10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }
  80% {
    -o-transform: translateX(10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
.animated.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
}
@-moz-keyframes bounceOut {
  0% {
    -moz-transform: scale(1);
  }
  25% {
    -moz-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
}
@-o-keyframes bounceOut {
  0% {
    -o-transform: scale(1);
  }
  25% {
    -o-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
}
@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}
.animated.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  -o-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}
@-moz-keyframes bounceOutUp {
  0% {
    -moz-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}
@-o-keyframes bounceOutUp {
  0% {
    -o-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}
@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}
.animated.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  -moz-animation-name: bounceOutUp;
  -o-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}
@-moz-keyframes bounceOutDown {
  0% {
    -moz-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}
@-o-keyframes bounceOutDown {
  0% {
    -o-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}
@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}
.animated.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  -moz-animation-name: bounceOutDown;
  -o-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}
@-moz-keyframes bounceOutLeft {
  0% {
    -moz-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}
@-o-keyframes bounceOutLeft {
  0% {
    -o-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}
@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}
.animated.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  -moz-animation-name: bounceOutLeft;
  -o-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}
@-moz-keyframes bounceOutRight {
  0% {
    -moz-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}
@-o-keyframes bounceOutRight {
  0% {
    -o-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}
@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}
.animated.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  -moz-animation-name: bounceOutRight;
  -o-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateIn {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateIn {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateIn {
  -webkit-animation-name: rotateIn;
  -moz-animation-name: rotateIn;
  -o-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  -moz-animation-name: rotateInUpLeft;
  -o-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  -moz-animation-name: rotateInDownLeft;
  -o-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  -moz-animation-name: rotateInUpRight;
  -o-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  -moz-animation-name: rotateInDownRight;
  -o-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOut {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(200deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOut {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}
.animated.rotateOut {
  -webkit-animation-name: rotateOut;
  -moz-animation-name: rotateOut;
  -o-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}
.animated.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  -moz-animation-name: rotateOutUpLeft;
  -o-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}
.animated.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  -moz-animation-name: rotateOutDownLeft;
  -o-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}
.animated.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  -moz-animation-name: rotateOutUpRight;
  -o-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}
.animated.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  -moz-animation-name: rotateOutDownRight;
  -o-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    opacity: 0;
  }
}
@-moz-keyframes hinge {
  0% {
    -moz-transform: rotate(0);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -moz-transform: rotate(80deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  40% {
    -moz-transform: rotate(60deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  80% {
    -moz-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  100% {
    -moz-transform: translateY(700px);
    opacity: 0;
  }
}
@-o-keyframes hinge {
  0% {
    -o-transform: rotate(0);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -o-transform: rotate(80deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  40% {
    -o-transform: rotate(60deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  80% {
    -o-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  100% {
    -o-transform: translateY(700px);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}
.animated.hinge {
  -webkit-animation-name: hinge;
  -moz-animation-name: hinge;
  -o-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
}
@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }
}
@-o-keyframes rollIn {
  0% {
    opacity: 0;
    -o-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }
}
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}
.animated.rollIn {
  -webkit-animation-name: rollIn;
  -moz-animation-name: rollIn;
  -o-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
  }
}
@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(100%) rotate(120deg);
  }
}
@-o-keyframes rollOut {
  0% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(100%) rotate(120deg);
  }
}
@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}
.animated.rollOut {
  -webkit-animation-name: rollOut;
  -moz-animation-name: rollOut;
  -o-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@-moz-keyframes lightSpeedIn {
  0% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -moz-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -moz-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@-o-keyframes lightSpeedIn {
  0% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -o-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -o-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
.animated.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  -moz-animation-name: lightSpeedIn;
  -o-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.animated.lightSpeedIn {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@-moz-keyframes lightSpeedOut {
  0% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@-o-keyframes lightSpeedOut {
  0% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
.animated.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  -moz-animation-name: lightSpeedOut;
  -o-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

.animated.lightSpeedOut {
  -webkit-animation-duration: 0.25s;
  -moz-animation-duration: 0.25s;
  -o-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
/*!
 * SmartAdmin - Responsive WebApp
 *
 * Copyright 2014 bootstraphunter.com
 * Designed and built with all the love in the world by @bootstraphunt.
 */
.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.fast {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-moz-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-o-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
.animated.flash {
  -webkit-animation-name: flash;
  -moz-animation-name: flash;
  -o-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
  }
}
@-moz-keyframes shake {
  0%, 100% {
    -moz-transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px);
  }
}
@-o-keyframes shake {
  0%, 100% {
    -o-transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    -o-transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    -o-transform: translateX(10px);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}
.animated.shake {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.animated.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}
@-moz-keyframes tada {
  0% {
    -moz-transform: scale(1);
  }
  10%, 20% {
    -moz-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -moz-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -moz-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -moz-transform: scale(1) rotate(0);
  }
}
@-o-keyframes tada {
  0% {
    -o-transform: scale(1);
  }
  10%, 20% {
    -o-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -o-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -o-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -o-transform: scale(1) rotate(0);
  }
}
@keyframes tada {
  0% {
    transform: scale(1);
  }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
.animated.tada {
  -webkit-animation-name: tada;
  -moz-animation-name: tada;
  -o-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-moz-keyframes swing {
  20% {
    -moz-transform: rotate(15deg);
  }
  40% {
    -moz-transform: rotate(-10deg);
  }
  60% {
    -moz-transform: rotate(5deg);
  }
  80% {
    -moz-transform: rotate(-5deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}
@-o-keyframes swing {
  20% {
    -o-transform: rotate(15deg);
  }
  40% {
    -o-transform: rotate(-10deg);
  }
  60% {
    -o-transform: rotate(5deg);
  }
  80% {
    -o-transform: rotate(-5deg);
  }
  100% {
    -o-transform: rotate(0deg);
  }
}
@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.animated.swing {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  -moz-animation-name: swing;
  -o-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes wobble {
  0% {
    -moz-transform: translateX(0%);
  }
  15% {
    -moz-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -moz-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -moz-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -moz-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -moz-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -moz-transform: translateX(0%);
  }
}
@-o-keyframes wobble {
  0% {
    -o-transform: translateX(0%);
  }
  15% {
    -o-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -o-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -o-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -o-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -o-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -o-transform: translateX(0%);
  }
}
@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    transform: translateX(0%);
  }
}
.animated.wobble {
  -webkit-animation-name: wobble;
  -moz-animation-name: wobble;
  -o-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.1);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }
  50% {
    -o-transform: scale(1.1);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.animated.pulse {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
}
@-moz-keyframes flip {
  0% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -moz-animation-timing-function: ease-out;
  }
  40% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -moz-animation-timing-function: ease-out;
  }
  50% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }
  80% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -moz-animation-timing-function: ease-in;
  }
  100% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }
}
@-o-keyframes flip {
  0% {
    -o-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -o-animation-timing-function: ease-out;
  }
  40% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -o-animation-timing-function: ease-out;
  }
  50% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-animation-timing-function: ease-in;
  }
  80% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -o-animation-timing-function: ease-in;
  }
  100% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -o-animation-timing-function: ease-in;
  }
}
@keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flip;
  -o-backface-visibility: visible !important;
  -o-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@-moz-keyframes flipInX {
  0% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -moz-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -moz-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@-o-keyframes flipInX {
  0% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -o-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -o-transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
.animated.flipInX {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInX;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutX {
  0% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutX {
  0% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
.animated.flipOutX {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -moz-animation-name: flipOutX;
  -moz-backface-visibility: visible !important;
  -o-animation-name: flipOutX;
  -o-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@-moz-keyframes flipInY {
  0% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -moz-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -moz-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@-o-keyframes flipInY {
  0% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -o-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -o-transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}
.animated.flipInY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutY {
  0% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutY {
  0% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
.animated.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipOutY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animated.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  -moz-animation-name: fadeInUpBig;
  -o-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  -moz-animation-name: fadeInLeftBig;
  -o-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.animated.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  -moz-animation-name: fadeInRightBig;
  -o-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.animated.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
.animated.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
}
@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
}
@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
.animated.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
}
@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
}
@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}
.animated.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
}
@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
}
@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}
.animated.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}
@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}
@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}
@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}
.animated.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  -moz-animation-name: fadeOutUpBig;
  -o-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}
@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}
@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}
@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}
.animated.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  -moz-animation-name: fadeOutDownBig;
  -o-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}
@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}
@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}
@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}
.animated.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  -moz-animation-name: fadeOutLeftBig;
  -o-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}
@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}
@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}
.animated.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  -moz-animation-name: fadeOutRightBig;
  -o-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }
  70% {
    -moz-transform: scale(0.9);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }
  70% {
    -o-transform: scale(0.9);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    transform: translateY(0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  -moz-animation-name: slideInDown;
  -o-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes slideInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  100% {
    transform: translateX(0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  -moz-animation-name: slideInLeft;
  -o-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes slideInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  100% {
    transform: translateX(0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  -moz-animation-name: slideInRight;
  -o-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}
@-moz-keyframes slideOutLeft {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}
@-o-keyframes slideOutLeft {
  0% {
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}
@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  -moz-animation-name: slideOutLeft;
  -o-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}
@-moz-keyframes slideOutRight {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}
@-o-keyframes slideOutRight {
  0% {
    -o-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}
@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  -moz-animation-name: slideOutRight;
  -o-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}
@-moz-keyframes slideOutUp {
  0% {
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}
@-o-keyframes slideOutUp {
  0% {
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}
@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  -moz-animation-name: slideOutUp;
  -o-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.animated.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }
  80% {
    -moz-transform: translateY(10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }
  80% {
    -o-transform: translateY(10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.animated.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }
  80% {
    -moz-transform: translateY(-10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }
  80% {
    -o-transform: translateY(-10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.animated.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }
  80% {
    -moz-transform: translateX(-10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }
  80% {
    -o-transform: translateX(-10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
.animated.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }
  80% {
    -moz-transform: translateX(10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }
  80% {
    -o-transform: translateX(10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
.animated.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
}
@-moz-keyframes bounceOut {
  0% {
    -moz-transform: scale(1);
  }
  25% {
    -moz-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
}
@-o-keyframes bounceOut {
  0% {
    -o-transform: scale(1);
  }
  25% {
    -o-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
}
@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}
.animated.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  -o-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}
@-moz-keyframes bounceOutUp {
  0% {
    -moz-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}
@-o-keyframes bounceOutUp {
  0% {
    -o-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}
@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}
.animated.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  -moz-animation-name: bounceOutUp;
  -o-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}
@-moz-keyframes bounceOutDown {
  0% {
    -moz-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}
@-o-keyframes bounceOutDown {
  0% {
    -o-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}
@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}
.animated.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  -moz-animation-name: bounceOutDown;
  -o-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}
@-moz-keyframes bounceOutLeft {
  0% {
    -moz-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}
@-o-keyframes bounceOutLeft {
  0% {
    -o-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}
@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}
.animated.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  -moz-animation-name: bounceOutLeft;
  -o-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}
@-moz-keyframes bounceOutRight {
  0% {
    -moz-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}
@-o-keyframes bounceOutRight {
  0% {
    -o-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}
@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}
.animated.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  -moz-animation-name: bounceOutRight;
  -o-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateIn {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateIn {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateIn {
  -webkit-animation-name: rotateIn;
  -moz-animation-name: rotateIn;
  -o-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  -moz-animation-name: rotateInUpLeft;
  -o-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  -moz-animation-name: rotateInDownLeft;
  -o-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  -moz-animation-name: rotateInUpRight;
  -o-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  -moz-animation-name: rotateInDownRight;
  -o-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOut {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(200deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOut {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}
.animated.rotateOut {
  -webkit-animation-name: rotateOut;
  -moz-animation-name: rotateOut;
  -o-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}
.animated.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  -moz-animation-name: rotateOutUpLeft;
  -o-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}
.animated.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  -moz-animation-name: rotateOutDownLeft;
  -o-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}
.animated.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  -moz-animation-name: rotateOutUpRight;
  -o-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}
.animated.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  -moz-animation-name: rotateOutDownRight;
  -o-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    opacity: 0;
  }
}
@-moz-keyframes hinge {
  0% {
    -moz-transform: rotate(0);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -moz-transform: rotate(80deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  40% {
    -moz-transform: rotate(60deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  80% {
    -moz-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  100% {
    -moz-transform: translateY(700px);
    opacity: 0;
  }
}
@-o-keyframes hinge {
  0% {
    -o-transform: rotate(0);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -o-transform: rotate(80deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  40% {
    -o-transform: rotate(60deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  80% {
    -o-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  100% {
    -o-transform: translateY(700px);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}
.animated.hinge {
  -webkit-animation-name: hinge;
  -moz-animation-name: hinge;
  -o-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
}
@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }
}
@-o-keyframes rollIn {
  0% {
    opacity: 0;
    -o-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }
}
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}
.animated.rollIn {
  -webkit-animation-name: rollIn;
  -moz-animation-name: rollIn;
  -o-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
  }
}
@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(100%) rotate(120deg);
  }
}
@-o-keyframes rollOut {
  0% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(100%) rotate(120deg);
  }
}
@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}
.animated.rollOut {
  -webkit-animation-name: rollOut;
  -moz-animation-name: rollOut;
  -o-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@-moz-keyframes lightSpeedIn {
  0% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -moz-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -moz-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@-o-keyframes lightSpeedIn {
  0% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -o-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -o-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
.animated.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  -moz-animation-name: lightSpeedIn;
  -o-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.animated.lightSpeedIn {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@-moz-keyframes lightSpeedOut {
  0% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@-o-keyframes lightSpeedOut {
  0% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
.animated.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  -moz-animation-name: lightSpeedOut;
  -o-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

.animated.lightSpeedOut {
  -webkit-animation-duration: 0.25s;
  -moz-animation-duration: 0.25s;
  -o-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.flag {
  width: 16px;
  height: 11px;
  background: url("../img/flags/flags.png") no-repeat;
  margin-top: -0.18em;
  display: inline-block;
}

.flag.flag-ad {
  background-position: -16px 0;
}

.flag.flag-ae {
  background-position: -32px 0;
}

.flag.flag-af {
  background-position: -48px 0;
}

.flag.flag-ag {
  background-position: -64px 0;
}

.flag.flag-ai {
  background-position: -80px 0;
}

.flag.flag-al {
  background-position: -96px 0;
}

.flag.flag-am {
  background-position: -112px 0;
}

.flag.flag-an {
  background-position: -128px 0;
}

.flag.flag-ao {
  background-position: -144px 0;
}

.flag.flag-ar {
  background-position: -160px 0;
}

.flag.flag-as {
  background-position: -176px 0;
}

.flag.flag-at {
  background-position: -192px 0;
}

.flag.flag-au {
  background-position: -208px 0;
}

.flag.flag-aw {
  background-position: -224px 0;
}

.flag.flag-az {
  background-position: -240px 0;
}

.flag.flag-ba {
  background-position: 0 -11px;
}

.flag.flag-bb {
  background-position: -16px -11px;
}

.flag.flag-bd {
  background-position: -32px -11px;
}

.flag.flag-be {
  background-position: -48px -11px;
}

.flag.flag-bf {
  background-position: -64px -11px;
}

.flag.flag-bg {
  background-position: -80px -11px;
}

.flag.flag-bh {
  background-position: -96px -11px;
}

.flag.flag-bi {
  background-position: -112px -11px;
}

.flag.flag-bj {
  background-position: -128px -11px;
}

.flag.flag-bm {
  background-position: -144px -11px;
}

.flag.flag-bn {
  background-position: -160px -11px;
}

.flag.flag-bo {
  background-position: -176px -11px;
}

.flag.flag-br {
  background-position: -192px -11px;
}

.flag.flag-bs {
  background-position: -208px -11px;
}

.flag.flag-bt {
  background-position: -224px -11px;
}

.flag.flag-bv {
  background-position: -240px -11px;
}

.flag.flag-bw {
  background-position: 0 -22px;
}

.flag.flag-by {
  background-position: -16px -22px;
}

.flag.flag-bz {
  background-position: -32px -22px;
}

.flag.flag-ca {
  background-position: -48px -22px;
}

.flag.flag-catalonia {
  background-position: -64px -22px;
}

.flag.flag-cd {
  background-position: -80px -22px;
}

.flag.flag-cf {
  background-position: -96px -22px;
}

.flag.flag-cg {
  background-position: -112px -22px;
}

.flag.flag-ch {
  background-position: -128px -22px;
}

.flag.flag-ci {
  background-position: -144px -22px;
}

.flag.flag-ck {
  background-position: -160px -22px;
}

.flag.flag-cl {
  background-position: -176px -22px;
}

.flag.flag-cm {
  background-position: -192px -22px;
}

.flag.flag-cn {
  background-position: -208px -22px;
}

.flag.flag-co {
  background-position: -224px -22px;
}

.flag.flag-cr {
  background-position: -240px -22px;
}

.flag.flag-cu {
  background-position: 0 -33px;
}

.flag.flag-cv {
  background-position: -16px -33px;
}

.flag.flag-cw {
  background-position: -32px -33px;
}

.flag.flag-cy {
  background-position: -48px -33px;
}

.flag.flag-cz {
  background-position: -64px -33px;
}

.flag.flag-de {
  background-position: -80px -33px;
}

.flag.flag-dj {
  background-position: -96px -33px;
}

.flag.flag-dk {
  background-position: -112px -33px;
}

.flag.flag-dm {
  background-position: -128px -33px;
}

.flag.flag-do {
  background-position: -144px -33px;
}

.flag.flag-dz {
  background-position: -160px -33px;
}

.flag.flag-ec {
  background-position: -176px -33px;
}

.flag.flag-ee {
  background-position: -192px -33px;
}

.flag.flag-eg {
  background-position: -208px -33px;
}

.flag.flag-eh {
  background-position: -224px -33px;
}

.flag.flag-en {
  background-position: -176px -44px;
}

.flag.flag-england {
  background-position: -240px -33px;
}

.flag.flag-er {
  background-position: 0 -44px;
}

.flag.flag-es {
  background-position: -16px -44px;
}

.flag.flag-et {
  background-position: -32px -44px;
}

.flag.flag-eu {
  background-position: -48px -44px;
}

.flag.flag-fi {
  background-position: -64px -44px;
}

.flag.flag-fj {
  background-position: -80px -44px;
}

.flag.flag-fk {
  background-position: -96px -44px;
}

.flag.flag-fm {
  background-position: -112px -44px;
}

.flag.flag-fo {
  background-position: -128px -44px;
}

.flag.flag-fr {
  background-position: -144px -44px;
}

.flag.flag-ga {
  background-position: -160px -44px;
}

.flag.flag-gb {
  background-position: -176px -44px;
}

.flag.flag-gd {
  background-position: -192px -44px;
}

.flag.flag-ge {
  background-position: -208px -44px;
}

.flag.flag-gf {
  background-position: -224px -44px;
}

.flag.flag-gg {
  background-position: -240px -44px;
}

.flag.flag-gh {
  background-position: 0 -55px;
}

.flag.flag-gi {
  background-position: -16px -55px;
}

.flag.flag-gl {
  background-position: -32px -55px;
}

.flag.flag-gm {
  background-position: -48px -55px;
}

.flag.flag-gn {
  background-position: -64px -55px;
}

.flag.flag-gp {
  background-position: -80px -55px;
}

.flag.flag-gq {
  background-position: -96px -55px;
}

.flag.flag-gr {
  background-position: -112px -55px;
}

.flag.flag-gs {
  background-position: -128px -55px;
}

.flag.flag-gt {
  background-position: -144px -55px;
}

.flag.flag-gu {
  background-position: -160px -55px;
}

.flag.flag-gw {
  background-position: -176px -55px;
}

.flag.flag-gy {
  background-position: -192px -55px;
}

.flag.flag-hk {
  background-position: -208px -55px;
}

.flag.flag-hm {
  background-position: -224px -55px;
}

.flag.flag-hn {
  background-position: -240px -55px;
}

.flag.flag-hr {
  background-position: 0 -66px;
}

.flag.flag-ht {
  background-position: -16px -66px;
}

.flag.flag-hu {
  background-position: -32px -66px;
}

.flag.flag-ic {
  background-position: -48px -66px;
}

.flag.flag-id {
  background-position: -64px -66px;
}

.flag.flag-ie {
  background-position: -80px -66px;
}

.flag.flag-il {
  background-position: -96px -66px;
}

.flag.flag-im {
  background-position: -112px -66px;
}

.flag.flag-in {
  background-position: -128px -66px;
}

.flag.flag-io {
  background-position: -144px -66px;
}

.flag.flag-iq {
  background-position: -160px -66px;
}

.flag.flag-ir {
  background-position: -176px -66px;
}

.flag.flag-is {
  background-position: -192px -66px;
}

.flag.flag-it {
  background-position: -208px -66px;
}

.flag.flag-je {
  background-position: -224px -66px;
}

.flag.flag-jm {
  background-position: -240px -66px;
}

.flag.flag-jo {
  background-position: 0 -77px;
}

.flag.flag-jp {
  background-position: -16px -77px;
}

.flag.flag-ke {
  background-position: -32px -77px;
}

.flag.flag-kg {
  background-position: -48px -77px;
}

.flag.flag-kh {
  background-position: -64px -77px;
}

.flag.flag-ki {
  background-position: -80px -77px;
}

.flag.flag-km {
  background-position: -96px -77px;
}

.flag.flag-kn {
  background-position: -112px -77px;
}

.flag.flag-kp {
  background-position: -128px -77px;
}

.flag.flag-kr {
  background-position: -144px -77px;
}

.flag.flag-kurdistan {
  background-position: -160px -77px;
}

.flag.flag-kw {
  background-position: -176px -77px;
}

.flag.flag-ky {
  background-position: -192px -77px;
}

.flag.flag-kz {
  background-position: -208px -77px;
}

.flag.flag-la {
  background-position: -224px -77px;
}

.flag.flag-lb {
  background-position: -240px -77px;
}

.flag.flag-lc {
  background-position: 0 -88px;
}

.flag.flag-li {
  background-position: -16px -88px;
}

.flag.flag-lk {
  background-position: -32px -88px;
}

.flag.flag-lr {
  background-position: -48px -88px;
}

.flag.flag-ls {
  background-position: -64px -88px;
}

.flag.flag-lt {
  background-position: -80px -88px;
}

.flag.flag-lu {
  background-position: -96px -88px;
}

.flag.flag-lv {
  background-position: -112px -88px;
}

.flag.flag-ly {
  background-position: -128px -88px;
}

.flag.flag-ma {
  background-position: -144px -88px;
}

.flag.flag-mc {
  background-position: -160px -88px;
}

.flag.flag-md {
  background-position: -176px -88px;
}

.flag.flag-me {
  background-position: -192px -88px;
}

.flag.flag-mg {
  background-position: -208px -88px;
}

.flag.flag-mh {
  background-position: -224px -88px;
}

.flag.flag-mk {
  background-position: -240px -88px;
}

.flag.flag-ml {
  background-position: 0 -99px;
}

.flag.flag-mm {
  background-position: -16px -99px;
}

.flag.flag-mn {
  background-position: -32px -99px;
}

.flag.flag-mo {
  background-position: -48px -99px;
}

.flag.flag-mp {
  background-position: -64px -99px;
}

.flag.flag-mq {
  background-position: -80px -99px;
}

.flag.flag-mr {
  background-position: -96px -99px;
}

.flag.flag-ms {
  background-position: -112px -99px;
}

.flag.flag-mt {
  background-position: -128px -99px;
}

.flag.flag-mu {
  background-position: -144px -99px;
}

.flag.flag-mv {
  background-position: -160px -99px;
}

.flag.flag-mw {
  background-position: -176px -99px;
}

.flag.flag-mx {
  background-position: -192px -99px;
}

.flag.flag-my {
  background-position: -208px -99px;
}

.flag.flag-mz {
  background-position: -224px -99px;
}

.flag.flag-na {
  background-position: -240px -99px;
}

.flag.flag-nc {
  background-position: 0 -110px;
}

.flag.flag-ne {
  background-position: -16px -110px;
}

.flag.flag-nf {
  background-position: -32px -110px;
}

.flag.flag-ng {
  background-position: -48px -110px;
}

.flag.flag-ni {
  background-position: -64px -110px;
}

.flag.flag-nl {
  background-position: -80px -110px;
}

.flag.flag-no {
  background-position: -96px -110px;
}

.flag.flag-np {
  background-position: -112px -110px;
}

.flag.flag-nr {
  background-position: -128px -110px;
}

.flag.flag-nu {
  background-position: -144px -110px;
}

.flag.flag-nz {
  background-position: -160px -110px;
}

.flag.flag-om {
  background-position: -176px -110px;
}

.flag.flag-pa {
  background-position: -192px -110px;
}

.flag.flag-pe {
  background-position: -208px -110px;
}

.flag.flag-pf {
  background-position: -224px -110px;
}

.flag.flag-pg {
  background-position: -240px -110px;
}

.flag.flag-ph {
  background-position: 0 -121px;
}

.flag.flag-pk {
  background-position: -16px -121px;
}

.flag.flag-pl {
  background-position: -32px -121px;
}

.flag.flag-pm {
  background-position: -48px -121px;
}

.flag.flag-pn {
  background-position: -64px -121px;
}

.flag.flag-pr {
  background-position: -80px -121px;
}

.flag.flag-ps {
  background-position: -96px -121px;
}

.flag.flag-pt {
  background-position: -112px -121px;
}

.flag.flag-pw {
  background-position: -128px -121px;
}

.flag.flag-py {
  background-position: -144px -121px;
}

.flag.flag-qa {
  background-position: -160px -121px;
}

.flag.flag-re {
  background-position: -176px -121px;
}

.flag.flag-ro {
  background-position: -192px -121px;
}

.flag.flag-rs {
  background-position: -208px -121px;
}

.flag.flag-ru {
  background-position: -224px -121px;
}

.flag.flag-rw {
  background-position: -240px -121px;
}

.flag.flag-sa {
  background-position: 0 -132px;
}

.flag.flag-sb {
  background-position: -16px -132px;
}

.flag.flag-sc {
  background-position: -32px -132px;
}

.flag.flag-scotland {
  background-position: -48px -132px;
}

.flag.flag-sd {
  background-position: -64px -132px;
}

.flag.flag-se {
  background-position: -80px -132px;
}

.flag.flag-sg {
  background-position: -96px -132px;
}

.flag.flag-sh {
  background-position: -112px -132px;
}

.flag.flag-si {
  background-position: -128px -132px;
}

.flag.flag-sk {
  background-position: -144px -132px;
}

.flag.flag-sl {
  background-position: -160px -132px;
}

.flag.flag-sm {
  background-position: -176px -132px;
}

.flag.flag-sn {
  background-position: -192px -132px;
}

.flag.flag-so {
  background-position: -208px -132px;
}

.flag.flag-somaliland {
  background-position: -224px -132px;
}

.flag.flag-sr {
  background-position: -240px -132px;
}

.flag.flag-ss {
  background-position: 0 -143px;
}

.flag.flag-st {
  background-position: -16px -143px;
}

.flag.flag-sv {
  background-position: -32px -143px;
}

.flag.flag-sx {
  background-position: -48px -143px;
}

.flag.flag-sy {
  background-position: -64px -143px;
}

.flag.flag-sz {
  background-position: -80px -143px;
}

.flag.flag-tc {
  background-position: -96px -143px;
}

.flag.flag-td {
  background-position: -112px -143px;
}

.flag.flag-tf {
  background-position: -128px -143px;
}

.flag.flag-tg {
  background-position: -144px -143px;
}

.flag.flag-th {
  background-position: -160px -143px;
}

.flag.flag-tj {
  background-position: -176px -143px;
}

.flag.flag-tk {
  background-position: -192px -143px;
}

.flag.flag-tl {
  background-position: -208px -143px;
}

.flag.flag-tm {
  background-position: -224px -143px;
}

.flag.flag-tn {
  background-position: -240px -143px;
}

.flag.flag-to {
  background-position: 0 -154px;
}

.flag.flag-tr {
  background-position: -16px -154px;
}

.flag.flag-tt {
  background-position: -32px -154px;
}

.flag.flag-tv {
  background-position: -48px -154px;
}

.flag.flag-tw {
  background-position: -64px -154px;
}

.flag.flag-tz {
  background-position: -80px -154px;
}

.flag.flag-ua {
  background-position: -96px -154px;
}

.flag.flag-ug {
  background-position: -112px -154px;
}

.flag.flag-um {
  background-position: -128px -154px;
}

.flag.flag-us {
  background-position: -144px -154px;
}

.flag.flag-uy {
  background-position: -160px -154px;
}

.flag.flag-uz {
  background-position: -176px -154px;
}

.flag.flag-va {
  background-position: -192px -154px;
}

.flag.flag-vc {
  background-position: -208px -154px;
}

.flag.flag-ve {
  background-position: -224px -154px;
}

.flag.flag-vg {
  background-position: -240px -154px;
}

.flag.flag-vi {
  background-position: 0 -165px;
}

.flag.flag-vn {
  background-position: -16px -165px;
}

.flag.flag-vu {
  background-position: -32px -165px;
}

.flag.flag-wales {
  background-position: -48px -165px;
}

.flag.flag-wf {
  background-position: -64px -165px;
}

.flag.flag-ws {
  background-position: -80px -165px;
}

.flag.flag-ye {
  background-position: -96px -165px;
}

.flag.flag-yt {
  background-position: -112px -165px;
}

.flag.flag-za {
  background-position: -128px -165px;
}

.flag.flag-zanzibar {
  background-position: -144px -165px;
}

.flag.flag-zm {
  background-position: -160px -165px;
}

.flag.flag-zw {
  background-position: -176px -165px;
}

/* flag with span */
.flag + span {
  text-transform: capitalize;
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-top: -3px;
}

@media (min-width: 768px) and (max-width: 979px) {
  .flag + span {
    max-width: 30px !important;
  }
}
.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-none {
  cursor: none;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-wait {
  cursor: wait;
}

.cursor-cell {
  cursor: cell;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-text {
  cursor: text;
}

.cursor-vertical-text {
  cursor: vertical-text;
}

.cursor-alias {
  cursor: alias;
}

.cursor-copy {
  cursor: copy;
}

.cursor-move {
  cursor: move;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

:-webkit-full-screen {
  /* properties */
}
:-webkit-full-screen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5A5A5A 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5A5A5A), color-stop(100%, #686868));
  background-image: -webkit-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#5A5A5A", endColorstr="#686868",GradientType=0 );
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  color: #FFF;
  border-color: #494949;
}

:-moz-full-screen {
  /* properties */
}
:-moz-full-screen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5A5A5A 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5A5A5A), color-stop(100%, #686868));
  background-image: -webkit-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#5A5A5A", endColorstr="#686868",GradientType=0 );
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  color: #FFF;
  border-color: #494949;
}

:-ms-fullscreen {
  /* properties */
}
:-ms-fullscreen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5A5A5A 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5A5A5A), color-stop(100%, #686868));
  background-image: -webkit-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#5A5A5A", endColorstr="#686868",GradientType=0 );
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  color: #FFF;
  border-color: #494949;
}

:full-screen {
  /*pre-spec */
  /* properties */
}
:full-screen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5A5A5A 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5A5A5A), color-stop(100%, #686868));
  background-image: -webkit-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#5A5A5A", endColorstr="#686868",GradientType=0 );
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  color: #FFF;
  border-color: #494949;
}

:fullscreen {
  /* spec */
  /* properties */
}
:fullscreen #fullscreen > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5A5A5A 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5A5A5A), color-stop(100%, #686868));
  background-image: -webkit-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#5A5A5A", endColorstr="#686868",GradientType=0 );
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  color: #FFF;
  border-color: #494949;
}

/* deeper elements */
:-webkit-full-screen video {
  width: 100%;
  height: 100%;
}

/* styling the backdrop*/
::backdrop {
  /* properties */
}

::-ms-backdrop {
  /* properties */
}

/*
 * Error Page
 */
.error-box {
  max-width: 900px;
  margin: 0 auto;
}

.error-text {
  font-weight: 400;
  color: #FFF;
  letter-spacing: -4px;
  font-size: 700%;
  margin-bottom: 30px;
  text-shadow: 0 1px 0 #CCC, 0 2px 0 #bfbfbf, 0 3px 0 #BBB, 0 4px 0 #B9B9B9, 0 5px 0 #AAA, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}

.error-search {
  width: 300px;
  margin: 0 auto 20px;
}

.error-icon-shadow {
  text-shadow: 0 1px 0 #803838, 0 2px 0 #A85D5D, 0 3px 0 #B86565, 0 4px 0 #D86F6F, 0 5px 0 #B3A5A5, 0 6px 1px rgba(131, 51, 51, 0.1), 0 0 5px rgba(102, 30, 30, 0.1), 0 1px 3px rgba(95, 15, 15, 0.3), 0 3px 5px rgba(100, 23, 23, 0.2), 0 5px 10px rgba(114, 30, 30, 0.25), 0 10px 10px rgba(94, 15, 15, 0.2), 0 20px 20px rgba(121, 38, 38, 0.15);
}

/*
 * LOGIN/REGISTER/EXTR PAGE
 */
.display-image {
  margin-top: -60px;
  margin-right: 20px;
}

.terms-body {
  max-height: 400px;
  overflow: auto;
}

#extr-page {
  background: #FFF;
  overflow: visible;
}
#extr-page .container {
  border: none;
}
#extr-page #header {
  margin: 0;
  height: 71px;
  border-bottom: 1px solid #eeeeee !important;
  overflow: hidden;
  padding: 0 30px;
  background-clip: padding-box;
  border-width: 0;
  min-height: 28px;
  /*margin-bottom: 20px;*/
  background: #FFF;
}
#extr-page #header #logo {
  margin-top: 22px;
  margin-left: 0px;
}
#extr-page #header #logo img {
  width: 137px;
}
#extr-page #main {
  padding-top: 20px;
}
#extr-page h4.paragraph-header {
  color: #565656;
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  margin-top: 15px;
  width: 270px;
}
#extr-page h5.about-heading {
  color: #565656;
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 5px;
}
#extr-page .login-header-big {
  font-weight: 400;
}
#extr-page .login-desc-box-l {
  min-height: 350px;
  width: 50%;
}
#extr-page .login-app-icons {
  vertical-align: top;
  margin-top: 90px;
  width: 300px;
}

#extr-page-header-space {
  float: right;
  text-align: right;
  display: block;
  vertical-align: middle;
  line-height: 71px;
}
#extr-page-header-space > :first-child {
  font-size: 13px;
  margin-right: 14px;
  vertical-align: -3px;
  font-weight: 400;
}
#extr-page-header-space .btn {
  font-weight: bold;
  text-transform: uppercase;
}
#extr-page-header-space > * {
  display: inline-block;
}

@media (min-width: 768px) and (max-width: 880px) {
  #extr-page #header {
    padding: 0 5px !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 679px) {
  #extr-page #header {
    padding: 0 5px !important;
  }
  #extr-page #header #logo {
    margin-top: 22px;
  }
  #extr-page #header #logo img {
    width: 135px;
  }
}
/*
 * NESTABLES
 */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}

.dd-item, .dd-empty, .dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  font-size: 15px;
  margin: 5px 0;
  padding: 7px 15px;
  color: #333333;
  text-decoration: none;
  border: 1px solid #cfcfcf;
  background: #fbfbfb;
}
.dd-handle:hover {
  color: #2ea8e5;
  background: #FFF;
}

.dd-item > button {
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 7px 10px;
  line-height: 22px !important;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: rgba(0, 0, 0, 0);
  text-align: center;
}

.dd-item > button {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

.dd-item > button:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #0091d9;
}

.dd-item > button[data-action=collapse]:before {
  content: "";
  color: #a90329;
}

.dd-placeholder, .dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #eeeeee;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/**
 * Nestable Extras
 */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #bfbfbf;
  border-bottom: 2px solid #bfbfbf;
}

#nestable-menu {
  padding: 0;
  margin: 10px 0 20px;
}

#nestable-output, #nestable2-output {
  width: 100%;
  padding: 5px;
}

@media only screen and (min-width: 700px) {
  .dd {
    float: left;
    width: 100%;
  }

  .dd + .dd {
    margin-left: 2%;
  }
}
.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}

/**
 * Nestable Draggable Handles
 */
.dd3-content {
  display: block;
  margin: 5px 0;
  padding: 6px 10px 8px 40px;
  font-size: 15px;
  color: #333333;
  text-decoration: none;
  border: 1px solid #cfcfcf;
  background: #fbfbfb;
}

.dd3-content:hover {
  color: #2ea8e5;
  background: #FFF;
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 38px;
}

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: move;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #aaa;
  background: #bfbfbf;
  background: -webkit-linear-gradient(top, #dddddd 0%, #bbb 100%);
  background: -moz-linear-gradient(top, #dddddd 0%, #bbb 100%);
  background: linear-gradient(top, #dddddd 0%, #bbb 100%);
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  padding: 8px 15px;
}

.dd3-handle:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #818181;
  font-weight: normal;
  line-height: 28px;
}

.dd3-handle:hover:before {
  color: #C27616;
}

.dd-handle:hover + .dd-list .dd-handle, .dd-handle:hover {
  background: #FDDFB3 !important;
  border: 1px solid #FAA937;
  color: #333 !important;
}

.dd-handle > span {
  font-size: 13px;
  color: #777;
}

.dd-dragel > .dd-item > .dd-handle {
  border-left-width: 4px;
}

.dd-dragel > li.dd-item.dd3-item {
  position: relative;
  top: 10px;
  left: 10px;
  z-index: 999;
}

/*
 * SEARCH RESULTS
 */
.search-results {
  padding: 18px 5px;
}
.search-results + .search-results {
  border-top: 1px dashed #e3e3e3;
}
.search-results > :first-child {
  margin-bottom: 4px;
  font-weight: 400;
}
.search-results > :first-child a {
  text-decoration: underline;
}
.search-results .url {
  font-style: normal;
  font-size: 14px;
}
.search-results img {
  display: inline-block;
  margin-top: 4px;
  margin-right: 4px;
  width: 80px;
}
.search-results > div {
  display: inline-block;
  vertical-align: top;
}
.search-results .note {
  margin: 0;
  line-height: normal;
}
.search-results .note a {
  text-decoration: none !important;
  color: #333333;
}
.search-results .note a:hover {
  color: #ed1c24;
}

.ajax-loading-error {
  margin-top: 70px;
  display: block;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
}
.ajax-notifications .ajax-loading-error {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
}

.ajax-notifications .ajax-loading-animation {
  font-size: 15px;
  text-align: center;
  margin-top: 70px;
}

html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: url("../img/mybg.png") #ffffff;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: url("../img/mybg.png") #ffffff;
  direction: ltr;
}

/* when mobile menu is active */
body.mobile-view-activated.hidden-menu {
  overflow-x: hidden;
}

body.modal-open {
  overflow: hidden !important;
}

a:hover, a:active, a:focus, button, button:active, button:focus, object, embed, input::-moz-focus-inner {
  outline: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
  font-weight: 300;
}

.page-title {
  margin: 12px 0 28px;
}
.page-title span {
  font-size: 16px;
  color: #333333;
  display: inline-block;
  vertical-align: 1px;
}

label {
  font-weight: normal;
}

*:focus {
  outline: 0 !important;
}

/*
 * IE FIX for tap delay
 */
a, input, button {
  -ms-touch-action: none !important;
}

textarea:focus,
select:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=time]:focus,
input[type=week]:focus,
input[type=number]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=color]:focus,
.uneditable-input:focus {
  outline: 0;
  outline: thin dotted \9 ;
  /* IE6-9 */
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.input-sm,
.input-lg,
.input-xs,
.form-control {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}

.input-xs {
  height: 24px;
  padding: 2px 10px;
  font-size: 11px;
  line-height: 1.5;
}

.btn-xs {
  padding: 1px 5px;
}

.btn-sm {
  padding: 6px 10px 5px;
}

.btn-lg {
  padding: 10px 16px;
}

.no-space {
  margin: 0;
}
.no-space > [class*=col-] {
  margin: 0 !important;
  padding-right: 0;
  padding-left: 0;
}

#content {
  padding: 10px 14px;
  position: relative;
  /*position:relative;
  left:0px;*/
}

body.container {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  box-shadow: 0 2px 70px rgba(0, 0, 0, 0.45);
}

body.container.hidden-menu:not(.mobile-view-activated) {
  overflow: hidden;
}

body.container.modal-open {
  padding: 0px !important;
}

/*
 * Headers
 */
h1 {
  letter-spacing: -1px;
  font-size: 24px;
  margin: 10px 0;
}

h1 small {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -1px;
}

.lead {
  font-size: 19px;
}

h2 {
  letter-spacing: -1px;
  font-size: 22px;
  margin: 20px 0;
  line-height: normal;
}

h3 {
  display: block;
  font-size: 19px;
  font-weight: 400;
  margin: 20px 0;
  line-height: normal;
}

h4 {
  line-height: normal;
}

h5 {
  font-size: 17px;
  font-weight: 300;
  margin: 10px 0;
  line-height: normal;
}

h6 {
  font-size: 15px;
  margin: 10px 0;
  font-weight: bold;
  line-height: normal;
}

.row-seperator-header {
  margin: 15px 14px 20px;
  border-bottom: none;
  display: block;
  color: #646464;
  font-size: 20px;
  font-weight: 400;
}

/*
 * Footer
 * see: component-page-footer.less
 */
/*
 * Center Canvas
 */
.center-canvas, .center-child-canvas > canvas {
  display: block !important;
  margin: 0 auto !important;
}

/*
 * JUST A BORDER 
 */
.bordered {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.bordered:hover {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

/*
 * ***************************************************
 * MAIN LAYOUT START
 * ***************************************************
 */
aside {
  display: block;
  /*overflow: hidden;*/
  background: #f7f7f7;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiMzYTM2MzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMmEyNzI1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==");
  background: -moz-linear-gradient(right, #f7f7f7 99%, #cacaca 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(99%, #f7f7f7), color-stop(100%, #cacaca));
  background: -webkit-linear-gradient(left, #f7f7f7 93%, #cacaca 100%);
  background: -o-linear-gradient(left, #f7f7f7 99%, #cacaca 100%);
  background: -ms-linear-gradient(left, #f7f7f7 99%, #cacaca 100%);
  background: linear-gradient(to right, #f7f7f7 99%, #cacaca 100%);
  min-height: 100%;
}

/*
 * HEADER GROUP
 */
#header {
  display: block;
  height: 65px;
  margin: 0;
  padding: 0 13px 0 0;
  position: relative;
  z-index: 905;
}

#header > div {
  display: inline-block;
  vertical-align: middle;
  height: 65px;
  float: left;
}

#header > div.open {
  font-weight: bold;
}

.fixed-header #header {
  position: fixed;
  width: 100%;
  z-index: 905;
  top: 0px;
  border-bottom: 2px solid #999999;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.1);
}

/* as per version 1.3 */
.fixed-header.fixed-ribbon #header {
  border-bottom: none;
  box-shadow: none;
}

.container.fixed-header #header {
  max-width: 1164px;
}

.fixed-header #header .ajax-dropdown {
  z-index: 906;
}

.fixed-header #shortcut {
  position: fixed;
}

.fixed-header #main {
  margin-top: 65px;
}

#logo-group > span {
  display: inline-block;
  height: 55px;
  float: left;
}

#logo-group span#activity {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: default !important;
  display: inline-block;
  font-weight: bold;
  height: 24px;
  width: 24px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  color: #c4bab6;
  font-size: 19px;
  margin: 10px 0 0 0;
  position: relative;
}

#logo-group span#activity:hover {
  border: 1px solid #bfbfbf;
  transition: all 0s;
  cursor: pointer !important;
  -webkit-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
}

#logo {
  display: inline-block;
  width: 175px;
  margin-top: 13px;
  margin-left: 9px;
}

#logo img {
  width: 110px;
  height: auto;
  padding-left: 3px;
}

#activity b.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  background: #0091d9;
  display: inline-block;
  font-size: 10px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  color: #FFF;
  font-weight: bold;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  padding: 2px 4px 3px 4px;
  text-align: center;
  line-height: normal;
}

#activity.active .badge {
  background: #006fa6 !important;
}

#project-context,
.project-context {
  display: inline-block;
  padding: 7px 13px 0;
  position: relative;
}

#project-context > span,
.project-context > span {
  display: block;
}

#project-context > :first-child,
.project-context > :first-child {
  display: block;
  color: #bfbfbf;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 0 1px white;
  padding-left: 0px;
  text-align: left;
}

#project-selector,
.project-selector {
  max-width: 97%;
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  color: #555555;
  font-size: 14px;
  cursor: pointer;
}

#project-selector:hover,
.project-selector:hover {
  color: #333333;
}

.header-search {
  position: relative;
}

.header-search.pull-right {
  margin-left: 6px;
}

.header-search > input[type=text] {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  min-width: 200px;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  border-color: #bfbfbf;
  background: #FFF;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-top: 10px;
  line-height: normal;
}

.header-search > button {
  background: none;
  border: none;
  color: #6D6A69;
  font-size: 17px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0px;
  top: 10px;
  width: 30px;
  z-index: 2;
}

#search-mobile {
  display: none;
}

#cancel-search-js {
  position: absolute;
  display: none;
  background: #a90329;
  z-index: 3;
  color: #FFF;
  padding: 0;
  right: 10px;
  top: 10px;
  text-align: center;
  height: 29px;
  line-height: 29px;
  width: 30px;
  font-size: 17px;
  text-decoration: none !important;
}

#header > :first-child, aside {
  width: 220px;
}

/*
 * LEFT PANEL
 */
/*
 * MAIN PANEL
 */
/* line 520, /Volumes/Macintosh HD/Users/dlouvard/Sites/BluemegaPart/resources/vendor/styles/smartadmin/_main.scss */
#main {
  margin-left: 220px;
  padding: 0;
  padding-bottom: 52px;
  min-height: 500px;
  position: relative;
}

/*
 * RIBBON
 */
#ribbon {
  min-height: 40px;
  background: #0e0e0e;
  padding: 0 13px;
  position: relative;
}

#ribbon .breadcrumb {
  display: inline-block;
  margin: 0;
  padding: 11px 34px 11px 0 !important;
  background: none;
  vertical-align: top;
}

#ribbon .breadcrumb a, #ribbon .breadcrumb {
  color: #BBB !important;
  text-decoration: none !important;
}

#ribbon .breadcrumb > .active, #ribbon .breadcrumb li:last-child {
  color: #E4E4E4;
}

.fixed-ribbon #ribbon {
  position: fixed;
  right: 0;
  left: 220px;
  top: 65px;
  z-index: 901;
}

.container.fixed-ribbon #ribbon {
  width: 944px;
  left: 590px;
}

.fixed-ribbon #content {
  padding-top: 66px;
}

.minified.fixed-ribbon #ribbon {
  left: 45px;
}

.hidden-menu.fixed-ribbon #ribbon {
  left: 10px;
}

/*
 * NAV
 * Menu CSS is learnt from: http://experiments.wemakesites.net/css3-treeview.html
 */
.fixed-navigation #left-panel {
  height: 100%;
  position: fixed;
  /*z-index: $fixed-navigation-zindex; */
}

/* Add this if not using slimscroll*/
.fixed-navigation nav {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 49px;
}

.fixed-navigation nav > ul {
  padding-right: 15px;
  position: relative;
  height: 100%;
  width: 114%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mobile-detected.fixed-navigation nav > ul {
  padding-right: 30px !important;
}

/* Slimscrol CSS change: delete this if not using slimscroll 

.fixed-navigation nav {
	padding-bottom: 49px;
}

.fixed-navigation nav > ul {
	position: relative;
}

.slimScrollBar,
.slimScrollRail {
	display:none !important;
}

.fixed-navigation .slimScrollBar,
.fixed-navigation .slimScrollRail {
	display:block !important;
}

.minified .slimScrollBar {
	display:none !important;
}*/
/* end slimscroll css change*/
.minified.fixed-navigation nav {
  -webkit-overflow-scrolling: none;
  overflow: visible;
  height: auto;
  padding-bottom: 0px;
}

.minified.fixed-navigation nav > ul {
  overflow: visible;
  padding-right: 0px;
  width: 100%;
}

/* All levels */
/* All levels */
nav ul {
  width: 100%;
  padding: 40px 28px 25px 0;
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: 0.5em;
  list-style: none;
  position: relative;
}

/* All levels */
nav ul .active > a {
  color: #333333 !important;
  font-weight: 700;
  position: relative;
}

/* All levels */
nav ul li.active > a:before {
  content: "";
  font-family: FontAwesome;
  display: block;
  height: 27px;
  line-height: normal;
  width: 27px;
  position: absolute;
  right: -21px;
  font-size: 20px;
  color: #555555;
}

nav ul li.active.open > a:before {
  content: "";
}

nav > ul > li:last-child.open {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

/* All levels 
nav ul li {
	overflow: hidden;
}*/
/* All levels */
nav ul li a {
  line-height: normal;
  font-size: 14px;
  padding: 10px 10px 10px 11px;
  color: #050505;
  display: block;
  font-weight: normal;
  text-decoration: none !important;
  position: relative;
}

/* Second level */
nav > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}

/* Third Level */
nav > ul > li > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}

/* All levels */
nav ul li a:focus {
  color: #c9c9c9;
}

/* All levels */
nav ul li a:hover {
  color: #555555;
  text-decoration: none;
}

nav ul li a.inactive,
nav ul li a.inactive:hover {
  opacity: 0.5;
  cursor: not-allowed;
  color: #050505;
}

/* All levels */
nav ul b {
  float: right;
  font-size: 11px;
  margin-top: 2px;
}

/* All levels */
nav ul span.menu-item-parent {
  display: inline-block;
  margin: 0;
  padding: 0;
}

/* First level: A, Second level: LI Open */
nav ul li.open > a, nav ul li.open > a b {
  color: #fff !important;
}

/* First level: Icon */
nav > ul > li > a b {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

nav > ul > li > a > i {
  /*font-size: 121%; fa-lg overrides this so..  */
  margin-right: 5px;
  width: 15px;
  display: inline-block;
  text-align: center;
  position: relative;
}

/* First level icon */
nav > ul > li > a > i > em {
  font-size: 9px;
  display: block;
  padding: 2px;
  position: absolute;
  top: -8px;
  right: -6px;
  text-decoration: none;
  font-style: normal;
  background: #ED1C24;
  color: #FFF;
  min-width: 13px;
  border-radius: 50%;
  max-height: 13px;
  line-height: 8px;
  font-weight: bold;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Second level switched to relative */
nav ul li li {
  border-bottom: none;
  position: relative;
}

/* Second level: UL */
nav > ul > li > ul::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 23px;
  top: 0;
  bottom: 0;
  border-left: 1px solid #7A7A7A;
}

/* Second level: UL LI */
nav > ul ul li::before {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  left: 23px;
  top: 16px;
  border-top: 1px solid #7A7A7A;
  z-index: 1;
}

/* Third level: UL LI */
nav > ul ul ul li::before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  left: 10px;
  top: 17px;
  border-top: 1px solid transparent;
}

/* Second level and onwards > */
nav > ul ul li a i {
  font-size: 14px !important;
  width: 18px !important;
  text-align: center !important;
  vertical-align: 0 !important;
  line-height: 1 !important;
}

/* Second and Third level line color */
nav > ul > li:hover > ul::before, nav > ul > li:hover > ul > li::before {
  border-color: #ACACAC !important;
}

/* Second Level: UL */
nav ul ul {
  margin: 0;
  padding: 0;
  display: none;
  background: rgba(69, 69, 69, 0.6);
}

nav ul ul {
  padding: 7px 0;
}

/* Third Level: UL */
nav ul ul ul {
  background: transparent;
  padding: 0;
}

/* Third Level: LI */
nav ul ul li {
  margin: 0;
  padding: 0;
}

/* Second Level: A */
nav ul ul li > a {
  padding-left: 42px;
  font-size: 14px;
  font-weight: normal;
  outline: 0;
}

/* Second Level: A:hover */
nav ul ul li > a:hover {
  background-color: rgba(69, 69, 69, 0.8);
  color: #FFF;
}

/* Third Level: A */
nav ul ul ul li a {
  color: #bfbfbf;
  padding: 8px 10px 8px 60px;
  font-size: 14px;
}

/* Third Level: A:hover */
nav ul ul ul li a:hover {
  color: #FFF;
}

/* Fourth Level: A */
nav ul ul ul ul li a {
  padding-left: 80px;
}

/* Fifth Level: A */
nav ul ul ul ul ul li a {
  padding-left: 100px;
}

/* Sixth Level: A */
nav ul ul ul ul ul ul li a {
  padding-left: 120px;
}

/* Second level: B */
nav ul ul b {
  color: #B3B3B3;
}

/* Second level: LI:hover B */
nav ul ul > li:hover b {
  color: #D5D9E2;
}

/*
 * HEADER NAV DROPDOWN LIST
 */
.header-dropdown-list {
  list-style: none;
  float: right;
  position: relative;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.header-dropdown-list > li {
  display: inline-block;
  padding-right: 5px;
}
.header-dropdown-list > li > .dropdown-toggle {
  margin-top: 16px;
  display: block;
}

/*
 * ***************************************************
 * MAIN LAYOUT END
 * ***************************************************
 */
/*
 * ACCORDIONS
 */
.smart-accordion-default.panel-group {
  margin-bottom: 0px;
}

.smart-accordion-default.panel-group .panel + .panel {
  margin-top: -1px;
}

.smart-accordion-default.panel-group .panel-heading {
  padding: 0px;
}

.smart-accordion-default.panel-group .panel-title a {
  display: block;
  padding: 10px 15px;
  text-decoration: none !important;
}

.smart-accordion-default .panel-heading, .panel-group .panel {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}

.smart-accordion-default .panel-default > .panel-heading {
  background-color: #fcfcfc;
}

.smart-accordion-default .panel-default {
  border-color: #c3c3c3;
}

.smart-accordion-default .panel-title > a > :first-child {
  display: none;
}

.smart-accordion-default .panel-title > a.collapsed > .fa {
  display: none;
}

.smart-accordion-default .panel-title > a.collapsed > :first-child {
  display: inline-block;
}

.no-padding .smart-accordion-default > div {
  border-left: none !important;
  border-right: none !important;
}

.no-padding .smart-accordion-default > div:first-child {
  border-top: none !important;
}

.no-padding .smart-accordion-default > div:last-child {
  border-bottom: none !important;
}

/*
 * ON OFF SWITCHES
 */
/* http://proto.io/freebies/onoff/ */
.onoffswitch-container {
  margin-top: 4px;
  margin-left: 7px;
  display: inline-block;
}

.onoffswitch {
  position: relative;
  width: 50px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #626262;
  border-radius: 50px;
  border-color: #adadad #b3b3b3 #9e9e9e;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  display: block;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 15px;
  padding: 0;
  line-height: 15px;
  font-size: 10px;
  color: #FFF;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: attr(data-swchon-text);
  text-shadow: 0 -1px 0 #333333;
  padding-left: 7px;
  background-color: #3276b1;
  color: #FFF;
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.05);
  text-align: left;
}

.onoffswitch-inner:after {
  content: attr(data-swchoff-text);
  padding-right: 7px;
  text-shadow: 0 -1px 0 #FFF;
  background-color: #FFF;
  color: #555555;
  text-align: right;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.onoffswitch-switch {
  width: 19px;
  height: 19px;
  margin: -2px;
  background: white;
  border: 1px solid #9a9a9a;
  border-radius: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 32px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #f4f4f4;
  background-image: -moz-linear-gradient(top, white, #eeeeee);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(#eeeeee));
  background-image: -webkit-linear-gradient(top, white, #eeeeee);
  background-image: -o-linear-gradient(top, white, #eeeeee);
  background-image: linear-gradient(to bottom, white, #eeeeee);
  background-repeat: repeat-x;
  -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.3);
}

.onoffswitch-checkbox + .onoffswitch-label .onoffswitch-switch:before, .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
  content: "";
  color: #a90329;
  display: block;
  text-align: center;
  line-height: 19px;
  font-size: 10px;
  text-shadow: 0 -1px 0 #FFF;
  font-weight: bold;
  font-family: FontAwesome;
}

/* checked */
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
  content: "";
  color: #57889c;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
  display: block;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.onoffswitch-switch:hover {
  background-color: #eeeeee;
}

.onoffswitch-switch:active {
  background-color: #eeeeee;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

/*
 * Disabled state
 */
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:after, .onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-inner:before {
  text-shadow: 0 1px 0 #FFF;
  background: #bfbfbf;
  color: #333333;
}

.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-switch, .onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch {
  background-color: #f4f4f4;
  background-image: -moz-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#bfbfbf), to(#f4f4f4));
  background-image: -webkit-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: -o-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: linear-gradient(to bottom, #bfbfbf, #f4f4f4);
  box-shadow: none !important;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label,
.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-label {
  border-color: #ababab #999999 #878787 !important;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  border-color: #3276b1 #2a6395 #255681;
}

.onoffswitch + span, .onoffswitch-title {
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
}

/*
 * FORMS
 */
.form-control {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.form hr {
  margin-left: -13px;
  margin-right: -13px;
  border-color: rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}

.form fieldset {
  display: block;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
}

fieldset {
  position: relative;
}

.form-actions {
  display: block;
  padding: 13px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(249, 249, 249, 0.9);
  margin-top: 25px;
  margin-left: -13px;
  margin-right: -13px;
  margin-bottom: -13px;
  text-align: right;
}

.well .form-actions {
  margin-left: -19px;
  margin-right: -19px;
  margin-bottom: -19px;
}

.well.well-lg .form-actions {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
}

.well.well-sm .form-actions {
  margin-left: -9px;
  margin-right: -9px;
  margin-bottom: -9px;
}

.popover-content .form-actions {
  margin: 0 -14px -9px;
  border-radius: 0 0 3px 3px;
  padding: 9px 14px;
}

.no-padding .form .form-actions {
  margin: 0;
  display: block;
  padding: 13px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
  text-align: right;
  margin-top: 25px;
}

.form header, legend, .bootstrap-duallistbox-container label {
  display: block;
  padding: 8px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  background: #FFF;
  font-size: 16px;
  font-weight: 300;
  color: #222222;
  margin: 25px 0px 20px;
}

.no-padding .form header {
  margin: 25px 14px 0;
}

.form header:first-child {
  margin-top: 10px;
}

legend {
  font-weight: 400;
  margin-top: 0px;
  background: none;
}

.input-group-addon {
  padding: 6px 10px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.input-group-addon {
  transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
}

.input-group-addon .fa {
  font-size: 14px;
}

.input-group-addon .fa-lg, .input-group-addon .fa-2x {
  font-size: 2em;
}

.input-group-addon .fa-3x, .input-group-addon .fa-4x, .input-group-addon .fa-5x {
  font-size: 30px;
}

input[type=text]:focus + .input-group-addon {
  border-color: #0091d9;
  background-color: #72a0ce;
  color: #FFF;
}

.has-warning input[type=text], .has-warning input[type=text] + .input-group-addon {
  border-color: #dbab57;
}

.has-warning input[type=text] + .input-group-addon {
  background-color: #faf2cc;
  color: #c09853;
}

.has-warning input[type=text]:focus, .has-warning input[type=text]:focus + .input-group-addon {
  border-color: #dbab57;
}

.has-warning input[type=text]:focus + .input-group-addon {
  background-color: #e1c118;
  color: #FFF;
}

.has-error .input-group-addon {
  border-color: #b94a48;
  background: #f2dede;
  color: #b94a48;
}

.has-error input[type=text], .has-error input[type=text] + .input-group-addon {
  border-color: #b94a48;
}

.has-error input[type=text] + .input-group-addon {
  background-color: #f2dede;
  color: #b94a48;
}

.has-error input[type=text]:focus, .has-error input[type=text]:focus + .input-group-addon {
  border-color: #c05e5e;
}

.has-error input[type=text]:focus + .input-group-addon {
  background-color: #c05e5e;
  color: #FFF;
}

.has-success input[type=text]:focus + .input-group-addon {
  border-color: #468847;
  background-color: #468847;
  color: #FFF;
}

.form fieldset .form-group:last-child, .form fieldset .form-group:last-child .note,
.form .form-group:last-child, .form .form-group:last-child .note {
  margin-bottom: 0;
}

.note {
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
  color: #999999;
}

/*
 * WITH ICON
 */
.input-icon-right {
  position: relative;
}

.input-icon-right > i, .input-icon-left > i {
  position: absolute;
  right: 10px;
  top: 30%;
  font-size: 16px;
  color: #bfbfbf;
}

.input-icon-left > i {
  right: auto;
  left: 24px;
}

.input-icon-right .form-control {
  padding-right: 27px;
}

.input-icon-left .form-control {
  padding-left: 29px;
}

input[type=text].ui-autocomplete-loading,
input[type=password].ui-autocomplete-loading,
input[type=datetime].ui-autocomplete-loading,
input[type=datetime-local].ui-autocomplete-loading,
input[type=date].ui-autocomplete-loading,
input[type=month].ui-autocomplete-loading,
input[type=time].ui-autocomplete-loading,
input[type=week].ui-autocomplete-loading,
input[type=number].ui-autocomplete-loading,
input[type=email].ui-autocomplete-loading,
input[type=url].ui-autocomplete-loading,
input[type=search].ui-autocomplete-loading,
input[type=tel].ui-autocomplete-loading,
input[type=color].ui-autocomplete-loading {
  background-image: url("../img/select2-spinner.gif") !important;
  background-repeat: no-repeat;
  background-position: 99% 50%;
  padding-right: 27px;
}

.input-group-addon .checkbox, .input-group-addon .radio {
  min-height: 0px;
  margin-right: 0px !important;
  padding-top: 0;
}

.input-group-addon label input[type=checkbox].checkbox + span,
.input-group-addon label input[type=radio].radiobox + span,
.input-group-addon label input[type=radio].radiobox + span:before,
.input-group-addon label input[type=checkbox].checkbox + span:before {
  margin-right: 0px;
}

.input-group-addon .onoffswitch, .input-group-addon .onoffswitch-label {
  margin: 0;
}

/*
 * Timeline Seperator
 */
.timeline-seperator {
  display: block;
  text-align: center;
  color: #999999;
  margin: 20px 0 0;
}
.timeline-seperator > :first-child {
  padding: 4px 5px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  background: #FFF;
  font-size: 11px;
  border-radius: 4px;
}

.timeline-seperator .btn, .timeline-seperator .btn + .dropdown-menu {
  margin-right: 15px;
}

.timeline-seperator:after {
  display: block;
  content: " ";
  margin: 0 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  margin-top: -8px;
}

/*
 * Alerts
 */
.alert {
  margin-bottom: 20px;
  margin-top: 0px;
  padding: 5px 15px 5px 34px;
  color: #675100;
  border-width: 0px;
  border-left-width: 5px;
  padding: 10px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}
.alert .close {
  top: 0px;
  right: -5px;
  line-height: 20px;
}

.alert-heading {
  font-weight: 600;
}

.alert-danger {
  border-color: #953b39;
  color: #FFF;
  background-color: #c26565;
  text-shadow: none;
}

.alert-warning {
  border-color: #e0b66c;
  color: #826430;
  background-color: spin(#efd9b3, 8);
}

.alert-success {
  border-color: #8ac38b;
  color: #356635;
  background-color: spin(#c4e0c4, -20);
}

.alert-info {
  border-color: #9cb4c5;
  color: #305d8c;
  background-color: #d6dde7;
}

/*
 * WELLS
 */
.well {
  background-color: #fbfbfb;
  border: 1px solid #dddddd;
  box-shadow: 0 1px 1px #ececec;
  -webkit-box-shadow: 0 1px 1px #ececec;
  -moz-box-shadow: 0 1px 1px #ececec;
  position: relative;
}

.well.well-clean {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
}

.well.well-glass {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.25);
  color: #FFF;
}

.well.well-light {
  background: #FFF;
  border: 1px solid #e2e2e2;
}

.widget-body .well {
  margin-bottom: 0px;
}

.well.transparent {
  border: none !important;
  background: none !important;
}

.jarviswidget.well.transparent > div, .jarviswidget.well.transparent > div .widget-body {
  padding: 0px !important;
}

/* any wells with custom bg the border will be darkened */
.well[class^=bg-], .well[class*=" bg-"] {
  border: 1px solid #555555 !important;
}

/*
 * TABS
 */
.nav-tabs > li > a .badge {
  font-size: 11px;
  padding: 3px 5px 3px 5px;
  opacity: 0.5;
  margin-left: 5px;
  min-width: 17px;
  font-weight: normal;
}

.nav-tabs > li > a > .fa {
  opacity: 0.5;
}

.tabs-left .nav-tabs > li > a .badge {
  margin-right: 5px;
  margin-left: 0px;
}

.nav-tabs > li > a .label {
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;
  opacity: 0.5;
}

.nav-tabs > li.active > a .badge, .nav-tabs > li.active > a .label, .nav-tabs > li.active > a > .fa {
  opacity: 1;
}

.nav-tabs > li > a {
  border-radius: 0px;
  color: #333333;
}

.nav-tabs > li.active > a {
  -webkit-box-shadow: 0 -2px 0 #57889c;
  -moz-box-shadow: 0 -2px 0 #57889c;
  box-shadow: 0 -2px 0 #57889c;
  border-top-width: 0px !important;
  margin-top: 1px !important;
  font-weight: bold;
}

.tabs-left .nav-tabs > li.active > a {
  -webkit-box-shadow: -2px 0 0 #57889c;
  -moz-box-shadow: -2px 0 0 #57889c;
  box-shadow: -2px 0 0 #57889c;
  border-top-width: 1px !important;
  border-left: none !important;
  margin-left: 1px !important;
}

.tabs-left .nav-pills > li.active > a {
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.tabs-right .nav-tabs > li.active > a {
  -webkit-box-shadow: 2px 0 0 #57889c;
  -moz-box-shadow: 2px 0 0 #57889c;
  box-shadow: 2px 0 0 #57889c;
  border-top-width: 1px !important;
  border-right: none !important;
  margin-right: 1px !important;
}

.tabs-below .nav-tabs > li.active > a {
  -webkit-box-shadow: 0 2px 0 #57889c;
  -moz-box-shadow: 0 2px 0 #57889c;
  box-shadow: 0 2px 0 #57889c;
  border-bottom-width: 0px !important;
  border-top: none !important;
  margin-top: 0px !important;
}

.tabs-below > .nav-tabs, .tabs-right > .nav-tabs, .tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: none;
}

.tab-content > .active, .pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #dddddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li,
.tabs-left > .nav-pills > li, .tabs-right > .nav-pills > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a,
.tabs-left > .nav-pills > li > a, .tabs-right > .nav-pills > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs, .tabs-left > .nav-pills {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #dddddd;
}

.tabs-left > .nav-pills {
  border-right: none;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
}

.tabs-left > .nav-tabs > li > a:hover, .tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #d5d5d5 #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a, .tabs-left > .nav-tabs .active > a:hover, .tabs-left > .nav-tabs .active > a:focus {
  border-color: #d5d5d5 transparent #d5d5d5 #dddddd;
  *border-right-color: #FFF;
}

.tabs-left > .tab-content {
  margin-left: 109px;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #dddddd;
  /* DDD */
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
}

.tabs-right > .nav-tabs > li > a:hover, .tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a, .tabs-right > .nav-tabs .active > a:hover, .tabs-right > .nav-tabs .active > a:focus {
  border-color: #dddddd #dddddd #dddddd transparent;
  *border-left-color: #FFF;
}

.tabs-below > .nav-tabs, .tabs-right > .nav-tabs, .tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: none;
}

.tab-content > .active, .pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #dddddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a:hover, .tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #dddddd;
  border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a, .tabs-below > .nav-tabs > .active > a:hover, .tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #dddddd #dddddd #dddddd;
  /* tabs */
}

/* bordered tabs */
.nav-tabs.bordered {
  background: #FFF;
  border: 1px solid #dddddd;
}

.nav-tabs.bordered > :first-child a {
  border-left-width: 0px !important;
}

.nav-tabs.bordered + .tab-content {
  border: 1px solid #dddddd;
  border-top: none;
}

/* bordered tabs top right */
.tabs-pull-right.nav-tabs > li, .tabs-pull-right.nav-pills > li {
  float: right;
}

.tabs-pull-right.nav-tabs > li:first-child > a, .tabs-pull-right.nav-pills > li:first-child > a {
  margin-right: 1px;
}

.tabs-pull-right.bordered.nav-tabs > li:first-child > a, .tabs-pull-right.bordered.nav-pills > li:first-child > a {
  border-left-width: 1px !important;
  margin-right: 0px;
  border-right-width: 0px;
}

/* jarviswidget header first child is tab */
.jarviswidget > header > .nav-tabs.pull-left > li:first-child a {
  border-left-width: 0px !important;
}

/* 
 * WEBKIT IOS HIGHLIGHT COLOR
 * 
 */
a:link, .btn, button {
  -webkit-tap-highlight-color: fade(#a90329, 50%);
}

.login-info, .login-info *, #logo-group *, .minified .menu-item-parent {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/*
 * BS2 useful classes
 * these classes and its importance was overlooked in BS3
 */
.hidden-desktop {
  display: none !important;
}

/*
 * DROPDOWN MENU
 */
.dropdown-menu-xs {
  min-width: 37px;
}

.dropdown-menu-xs > li > a {
  padding: 3px 10px;
}

.dropdown-menu-xs > li > a:hover i {
  color: #fff !important;
}

/* Dropdown menu extended */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #bfbfbf;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #FFF;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

/*
 * Table
 */
.table.has-tickbox thead tr th:first-child {
  width: 18px;
}

.table.has-tickbox.smart-form thead tr th:first-child .radio,
.table.has-tickbox.smart-form thead tr th:first-child .checkbox,
.table.has-tickbox.smart-form tbody tr td:first-child .radio,
.table.has-tickbox.smart-form tbody tr td:first-child .checkbox {
  padding: 0px !important;
}

.table thead tr,
.fc-border-separate thead tr {
  background-color: #eeeeee;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#fafafa));
  background-image: -webkit-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -moz-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -ms-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -o-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  font-size: 12px;
}

.fc-border-separate thead tr th {
  padding: 4px;
  line-height: 1.428571429;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-width: 1px;
}

.no-padding > .table-bordered,
.no-padding > .table-responsive > .table-bordered {
  border: none !important;
}

.no-padding > .table-bordered thead tr th:first-child,
.no-padding > .table-bordered thead tr td:first-child,
.no-padding > .table-bordered tbody tr td:first-child,
.no-padding > .table-responsive > .table-bordered thead tr th:first-child,
.no-padding > .table-responsive > .table-bordered thead tr td:first-child,
.no-padding > .table-responsive > .table-bordered tbody tr td:first-child {
  border-left-width: 0px !important;
}

.no-padding > .table-bordered thead tr th:last-child,
.no-padding > .table-bordered tbody tr td:last-child,
.no-padding > .table-responsive > .table-bordered thead tr th:last-child,
.no-padding > .table-responsive > .table-bordered tbody tr td:last-child {
  border-right-width: 0px !important;
}

.no-padding > .table-bordered tbody tr:last-child td,
.no-padding > .table-responsive > .table-bordered tbody tr:last-child td {
  border-bottom-width: 0px !important;
}

.no-padding .table-responsive .table {
  margin-bottom: 0;
}

.no-padding .note-editable .table-bordered {
  border: 1px solid #dddddd !important;
}

.smart-form.table td .radio i,
.smart-form.table td .checkbox i,
.smart-form.table th .radio i,
.smart-form.table th .checkbox i {
  top: 0px !important;
}

/* condenced table */
.table-condensed.table > tbody > tr > td,
.table-condensed.table > tbody > tr > th,
.table-condensed.table > tfoot > tr > td,
.table-condensed.table > tfoot > tr > th,
.table-condensed.table > thead > tr > td,
.table-condensed.table > thead > tr > th {
  padding: 5px 10px !important;
}

/*
 * small stats
 */
.show-stats .progress {
  height: 7px;
  border-radius: 0;
  margin-top: 3px;
  margin-bottom: 15px;
}

.show-stats {
  padding-top: 6px !important;
}

.show-stat-buttons {
  float: left;
  width: 100%;
}

.show-stats .show-stat-buttons > :first-child {
  padding-right: 5px;
}

.show-stats .show-stat-buttons > :last-child {
  padding-left: 5px;
}

.show-stat-microcharts {
  margin-left: -10px;
  margin-right: -10px;
}

.show-stat-microcharts > div {
  border-right: 1px solid #DADADA !important;
  border-top: 1px solid #DADADA !important;
  margin-top: 10px !important;
  height: 65px;
  overflow: hidden;
  padding: 7px 9px;
}

.show-stat-microcharts > :last-child {
  border-right: none !important;
}

.show-stat-microcharts .sparkline {
  margin-top: 10px;
  margin-right: 8px;
  opacity: 0.7;
}

.show-stat-microcharts .sparkline:hover {
  opacity: 1;
}

.smaller-stat {
  margin: 0;
  padding: 0;
  list-style: none;
}

.smaller-stat li {
  margin-top: 3px;
  margin-right: 0px;
}

.smaller-stat li:first-child {
  margin-top: 8px;
}

.smaller-stat span.label {
  width: 40px;
  display: block;
  text-align: left;
  opacity: 0.5;
  cursor: default;
}

.smaller-stat span.label:hover {
  opacity: 1;
}

/*
 * Login info
 */
.login-info {
  padding: 1px 0px 0px 15px;
  font-size: 12px;
  height: 41px;
  color: #ffffff;
  border-width: 1px 0px 1px 0px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  background-color: #0e0e0e;
  margin: 0px !important;
}

.login-info a {
  text-decoration: none !important;
  color: #050505;
  display: inline-block;
  margin-top: 6px;
}

.login-info a span {
  text-transform: capitalize;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.login-info a span + i {
  vertical-align: middle;
}

.login-info a:hover {
  color: #FFF;
}

.login-info > span {
  height: 38px;
  display: block;
  padding: 0 10px;
  border-bottom: 1px solid #1A1817;
}

.login-info img {
  width: 25px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-top: 1px;
  margin-right: 5px;
  margin-left: 0px;
  border-left: 3px solid #FFF;
}

img.online, img.busy, img.offline, img.away {
  border-left: 3px solid #fff;
}

img.online {
  border-left-color: #40ac2b !important;
}

img.busy {
  border-left-color: #ed1c24 !important;
}

img.offline {
  border-left-color: #dddddd !important;
}

img.away {
  border-left-color: #ffc40d !important;
}

/*
 * Client page forms
 */
.client-form header {
  padding: 15px 13px;
  margin: 0;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
}

/*
 * MINIFIED MENU
 */
.minified .inbox-badge {
  border-radius: 50%;
  font-size: 9px;
  padding: 2px;
  position: absolute;
  top: 6px;
  right: 6px;
  background: #ED1C24;
  min-width: 13px;
}

.minifyme {
  border-bottom: 1px solid #302F2F;
  background: #454545;
  color: #A8A8A8;
  text-align: center;
  padding: 0;
  width: 36px;
  height: 28px;
  display: block;
  position: absolute;
  right: 0px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  margin-top: 7px;
  font-size: 19px;
  transition: all 0.1s linear 0s;
}

.minifyme:hover {
  background: #646464;
  color: #E4E4E4;
  right: 0px;
  width: 40px;
  padding-right: 5px;
}

.minified .minifyme {
  right: 0px;
  color: #A8A8A8;
  position: relative;
  width: 100%;
  border-radius: 0px;
  margin: 0;
  height: 34px;
  font-size: 23px;
}

.minified .minifyme .fa:before {
  content: "";
}

.minified .login-info a span, .minified .login-info a i {
  display: none;
}

.minified .login-info span {
  padding: 0px 5px;
  text-align: center;
}

.minified .login-info img {
  margin: -2px 0 0;
  width: 30px;
}

.minified .login-info {
  height: 38px;
  border-bottom: 1px solid #181818;
}

.minified #left-panel {
  width: 45px;
}

.minified #main {
  margin-left: 45px;
}

/*
 * Minified NAV
 */
.minified #left-panel, .minified nav > ul > li {
  overflow: visible;
}

.minified nav > ul > ul {
  min-height: 100px !important;
  max-height: 180px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #ed1c24 !important;
}

.minified nav > ul > li, .minified nav > ul > li a {
  position: relative;
}

.minified nav ul > li > a {
  padding: 10px 11px;
  display: block;
}

.minified nav > ul > li > a .fa.fa-fw {
  display: block;
  width: auto;
  text-align: center;
  padding: 0;
}

.minified nav > ul > li > a > i {
  text-align: center;
  margin: 0;
  display: block;
}

.minified nav > ul > li.active > a::before {
  content: "";
}

.minified nav ul ul li a {
  padding-left: 14px;
}

.minified nav ul ul li li a {
  padding-left: 25px;
}

.minified nav > ul ul li::before {
  left: 12px;
}

.minified nav > ul > li > ul::before {
  left: 12px;
}

.minified nav > ul > li > a .menu-item-parent, .minified nav > ul > li > a > b {
  display: none;
}

.minified nav > ul > li > a > .menu-item-parent {
  display: none;
  position: absolute;
  left: 40px;
  top: -3px;
  width: 186px;
  height: 38px;
  line-height: 38px;
  background-color: whiteSmoke;
  color: #333333;
  z-index: 3;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-left: 1px solid #bfbfbf;
  border-top: 1px solid #D8D4D4;
  border-bottom: 1px solid #FFF;
  padding-left: 12px;
}

.minified nav > ul > li > ul {
  display: none !important;
  position: absolute;
  left: 40px;
  width: 199px;
  z-index: 5;
  border: 1px solid #bfbfbf;
  min-height: 180px;
  -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
  background: #454545;
  margin-top: -3px;
  overflow: hidden;
}

.minified nav ul > li > ul > li > ul {
  position: relative;
  left: 13px;
  border: none;
  width: auto;
  margin-left: -12px;
  width: 197px;
}

.minified nav > ul > li:hover > a > .menu-item-parent, .minified nav > ul > li:hover > ul {
  display: block !important;
}

.minified nav > ul > li > ul > li {
  background-color: rgba(69, 69, 69, 0.9);
}

.minified nav > ul > li > ul > li > ul > li {
  background-color: #484B50;
}

.minified nav > ul > li {
  border-bottom: 1px solid #1A1817;
  border-top: 1px solid #525151;
}

.minified nav > ul > li > ul::before {
  border-left: none;
}

.minified nav > ul ul li::before {
  border: none;
}

.slimScrollDiv, .slimScrollDiv > :first-child {
  overflow: hidden;
}

.minified .slimScrollDiv, .minified .slimScrollDiv > :first-child {
  overflow: visible !important;
}

.minified .slimScrollBar, .minified .slimScrollRail {
  display: none;
}

.minified nav > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}

.minified nav > ul > li > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}

.minified nav > ul > li > ul > li > ul > li a {
  padding-top: 6px;
  padding-bottom: 6px;
}

/* Fourth Level: A */
.minified nav ul ul ul ul li a {
  padding-left: 45px;
}

/* Fifth Level: A */
.minified nav ul ul ul ul ul li a {
  padding-left: 62px;
}

/* Sixth Level: A */
.minified nav ul ul ul ul ul ul li a {
  padding-left: 82px;
}

/*
 * ALTERNATIVE PAGING
 */
.pagination.pagination-alt > li > a {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  margin-left: -1px;
}

.pagination.pagination-alt > li:first-child > a {
  padding-left: 0px;
}

/*
 * CUSTOM BUTTON
 */
.pagination > li > a, .pagination > li > span {
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
}

.disabled {
  color: #FFF;
}

.btn-default.disabled {
  color: #999999;
}

.btn {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
}

.btn:active {
  position: relative;
  top: 1px;
  left: 1px;
}

.btn.btn-ribbon {
  background-color: #707070;
  background-image: -moz-linear-gradient(top, #777, #666);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#777), to(#666));
  background-image: -webkit-linear-gradient(top, #777, #666);
  background-image: -o-linear-gradient(top, #777, #666);
  background-image: linear-gradient(to bottom, #777, #666);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff777777", endColorstr="#ff666666", GradientType=0);
  color: white;
  padding: 0 5px;
  line-height: 20px;
  vertical-align: middle;
  height: 20px;
  display: block;
  border: none;
  float: left;
  margin: 0 8px 0 0;
  cursor: pointer;
}

.btn.btn-ribbon > i {
  font-size: 111%;
}

.ribbon-button-alignment {
  padding-top: 10px;
  display: inline-block;
}

.ribbon-button-alignment.pull-right > .btn.btn-ribbon {
  margin: 0 0 0 8px;
}

/*
 * Panels
 */
.panel-purple {
  border-color: #A128AB;
}
.panel-purple > .panel-heading {
  color: #FFF;
  background-color: #A128AB;
  border-color: #A128AB;
}

.panel-greenLight {
  border-color: #71843f;
}
.panel-greenLight > .panel-heading {
  color: #FFF;
  background-color: #71843f;
  border-color: #71843f;
}

.panel-greenDark {
  border-color: #496949;
}
.panel-greenDark > .panel-heading {
  color: #FFF;
  background-color: #496949;
  border-color: #496949;
}

.panel-darken {
  border-color: #333333;
}
.panel-darken > .panel-heading {
  color: #FFF;
  background-color: #404040;
  border-color: #404040;
}

.panel-pink {
  border-color: #ac5287;
}
.panel-pink > .panel-heading {
  color: #FFF;
  background-color: #ac5287;
  border-color: #ac5287;
}

.panel-green {
  border-color: #356e35;
}
.panel-green > .panel-heading {
  color: #FFF;
  background-color: #356e35;
  border-color: #356e35;
}

.panel-blueLight {
  border-color: #92a2a8;
}
.panel-blueLight > .panel-heading {
  color: #FFF;
  background-color: #92a2a8;
  border-color: #92a2a8;
}

.panel-pinkDark {
  border-color: #a8829f;
}
.panel-pinkDark > .panel-heading {
  color: #FFF;
  background-color: #a8829f;
  border-color: #a8829f;
}

.panel-redLight {
  border-color: #a65858;
}
.panel-redLight > .panel-heading {
  color: #FFF;
  background-color: #a65858;
  border-color: #a65858;
}

.panel-red {
  border-color: #a90329;
}
.panel-red > .panel-heading {
  color: #FFF;
  background-color: #a90329;
  border-color: #a90329;
}

.panel-teal {
  border-color: #568a89;
}
.panel-teal > .panel-heading {
  color: #FFF;
  background-color: #568a89;
  border-color: #568a89;
}

.panel-orange {
  border-color: #c79121;
}
.panel-orange > .panel-heading {
  color: #FFF;
  background-color: #c79121;
  border-color: #c79121;
}

.panel-blueDark {
  border-color: #4c4f53;
}
.panel-blueDark > .panel-heading {
  color: #FFF;
  background-color: #4c4f53;
  border-color: #4c4f53;
}

.panel-magenta {
  border-color: #6e3671;
}
.panel-magenta > .panel-heading {
  color: #FFF;
  background-color: #6e3671;
  border-color: #6e3671;
}

.panel-blue {
  border-color: #57889c;
}
.panel-blue > .panel-heading {
  color: #FFF;
  background-color: #57889c;
  border-color: #57889c;
}

.panel-footer > .btn-block {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

/*
 * Circle Buttons
 */
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 15px;
  font-size: 24px;
  line-height: 50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

/*
 * Big Metro Buttons
 */
.btn-metro {
  margin: 0 0 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn-metro > span {
  display: block;
  vertical-align: bottom;
  margin-top: 10px;
  text-transform: uppercase;
}

.btn-metro > span.label {
  position: absolute;
  top: 0px;
  right: 0px;
}

/*
 * buttons with labels
 */
.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-link {
  box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 14px;
}

/*
 * ANIMATION
 */
#left-panel {
  -webkit-transition: 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
  transition: 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
  -webkit-transform: translate(0px, 0px) translateZ(0px);
}

#left-panel {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 904;
  padding-top: 65px;
}

/* removed as it was too jerky */
/*#main {
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}*/
/*
 * CHARTS
 */
.chart-small, .chart-large, .chart-xl {
  overflow: hidden;
}

.chart-small {
  height: 100px;
  width: 100%;
}

.chart-large {
  height: 235px;
  width: 100%;
}

.chart-xl {
  height: 297px;
  width: 100%;
}

/*
 * Flot Tool tips 
 */
#flotTip {
  padding: 3px 5px;
  background-color: #FFF;
  z-index: 9999;
  color: #333333;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  border: 1px solid #C1C1C1;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

#flotTip span {
  color: #38812D;
  font-weight: bold;
}

.legendLabel span {
  display: block;
  margin: 0 5px;
}

.legendColorBox {
  padding-left: 10px;
  vertical-align: top;
  padding-top: 5px;
}

.legendColorBox div > div {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

/*
 * Morris Charts 
 */
.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #666;
  background: rgba(255, 255, 255, 0.8);
  border: solid 2px rgba(230, 230, 230, 0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.morris-hover {
  position: absolute;
  z-index: 903;
}

.fixed-page-footer .morris-hover {
  z-index: 900;
}

/*
 * Flot Chart 
 */
.chart {
  overflow: hidden;
  /*- disabled for widget chrome bug */
  height: 220px;
  margin: 20px 5px 10px 10px;
}

.has-legend {
  margin-top: 30px !important;
}

.has-legend-unique {
  margin-top: 19px !important;
}

/*
 * Carren colors
 */
.icon-color-good {
  color: #40ac2b;
}

.icon-color-bad {
  color: #ed1c24;
}

/*
 * SIGNS
 */
.percent-sign:after, .degree-sign:after {
  content: " %";
  line-height: normal;
}

.degree-sign:after {
  content: " °F";
}

/*
 * HIDDEN MENU
 */
.hidden-menu #left-panel {
  left: -210px;
  z-index: 903;
}

.hidden-menu #main {
  margin-left: 10px;
}

.hidden-menu #left-panel:hover {
  left: 0px;
}

.hidden-menu .minifyme {
  display: none;
}

.minified #hide-menu {
  display: none;
}

.btn-header.pull-right {
  margin-left: 6px;
}

.btn-header a > span {
  font-size: 13px;
  font-weight: normal;
  line-height: 30px;
  height: 30px;
  display: inline-block;
}

.btn-header > :first-child > a {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: default !important;
  display: inline-block;
  font-weight: bold;
  height: 30px;
  line-height: 24px;
  min-width: 30px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  color: #6D6A69;
  font-size: 17px;
  margin: 10px 0 0 0;
}

.btn-header > :first-child > a:hover {
  border: 1px solid #bfbfbf;
  color: #222;
  transition: all 0s;
  cursor: pointer;
  -webkit-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
}

.btn-header > :first-child > a:active {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e8e8e8), color-stop(100%, #ededed));
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: -ms-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#e8e8e8", endColorstr="#ededed",GradientType=0 );
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
}

.hidden-menu #hide-menu > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5A5A5A 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5A5A5A), color-stop(100%, #686868));
  background-image: -webkit-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  background-image: linear-gradient(to bottom, #5A5A5A 0%, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#5A5A5A", endColorstr="#686868",GradientType=0 );
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  color: #FFF;
  border-color: #494949;
}

.hidden-menu #hide-menu > :first-child > a:active, .full-screen #fullscreen > :first-child > a:active {
  -webkit-box-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
}

/*
 * AJAX DROPDOWN
 */
.ajax-dropdown > :first-child {
  margin: 0 0 3px 0;
  padding: 0 0 9px 0;
}

.ajax-dropdown {
  position: absolute;
  display: none;
  z-index: 905;
  background: white;
  top: 48px;
  left: 16px;
  width: 344px;
  height: 435px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  -moz-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  padding: 10px;
  background: #FFF;
  border: 1px solid #b3b3b3;
}

.ajax-dropdown:after, .ajax-dropdown:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ajax-dropdown:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 7px;
  left: 50%;
  margin-left: -7px;
}

.ajax-dropdown:before {
  border-color: rgba(131, 131, 131, 0);
  border-bottom-color: #838383;
  border-width: 8px;
  left: 50%;
  margin-left: -8px;
}

.ajax-dropdown .btn-group .btn {
  font-weight: bold;
  text-transform: capitalize;
}

/* better for 3 childs*/
.ajax-dropdown .btn-group > :nth-child(2) {
  border-right-width: 0px;
  border-left-width: 0px;
}

.ajax-dropdown .btn-group > :last-child {
  border-right-width: 1px !important;
}

/* better for 4+ childs 
.ajax-dropdown .btn-group >:first-child {
	border-right-width:1px;
}

.ajax-dropdown .btn-group >:nth-child(n+2) {
	border-left-width:0px;
}

.ajax-dropdown .btn-group >:last-child {
	border-right-width:1px !important;
}	*/
.ajax-dropdown .btn-group .btn:active {
  top: 0px;
  left: 0px;
}

.ajax-notifications {
  height: 365px;
  display: block;
  overflow: auto;
  margin-right: -10px;
  margin-left: -10px;
  border-bottom: 1px solid #CECECE;
  border-top: 1px solid #CECECE;
  background: #E9E9E9;
}

.ajax-notifications .alert.alert-transparent {
  background-color: transparent;
  border-color: transparent;
  color: #757575;
  margin: 13px 10px;
}

.ajax-notifications .alert > :first-child {
  margin-bottom: 10px;
  font-weight: 400;
}

.ajax-dropdown > :last-child {
  font-size: 13px;
  display: block;
  padding: 5px 0;
  line-height: 22px;
  font-weight: 400;
}

.ajax-dropdown .fa-4x.fa-border {
  border-width: 3px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  display: block;
  margin: 0 auto;
  width: 46px;
  text-align: center;
  color: #D1D1D1;
  border-color: #D1D1D1;
}

/*
 * LARGE DROPDOWNS
 */
.dropdown-large {
  position: static !important;
}

.dropdown-menu-large {
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0px;
}

.dropdown-menu-large > li > ul {
  padding: 0;
  margin: 0;
}

.dropdown-menu-large > li > ul > li {
  list-style: none;
}

.dropdown-menu-large > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}

.dropdown-menu-large > li ul > li > a:hover,
.dropdown-menu-large > li ul > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

.dropdown-menu-large .disabled > a,
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  color: #999999;
}

.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}

.dropdown-menu-large .dropdown-header {
  color: #428bca;
  font-size: 18px;
}

@media (max-width: 768px) {
  .dropdown-menu-large {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-menu-large > li {
    margin-bottom: 30px;
  }

  .dropdown-menu-large > li:last-child {
    margin-bottom: 0;
  }

  .dropdown-menu-large .dropdown-header {
    padding: 3px 15px !important;
  }
}
/*
 * PAGING
 */
.pagination-xs > li > a, .pagination-xs > li > span {
  padding: 4px 9px;
  font-size: 12px;
}

/*
 * NOTIFICATIONS : MAIL
 */
.notification-body *, .notification-body:before *, .notification-body:after * {
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
}

.notification-body {
  padding: 0 0 0 3px;
  margin: 0;
  list-style: none;
}

.notification-body > li {
  border-bottom: 1px solid #E9E9E9;
  position: relative;
}

.notification-body > li > span {
  background: #FFF;
  display: block;
  min-height: 25px;
  overflow: hidden;
  padding: 8px 10px 13px;
  white-space: normal;
}

.notification-body > li > span:hover {
  background: #F0F4F7;
  color: #667F8F;
}

.notification-body > li a.msg {
  padding-left: 50px !important;
}

.notification-body > li a, .notification-body > li a:hover {
  text-decoration: none;
}

.notification-body > li a, .notification-body > li a span {
  display: block;
  position: relative;
  overflow: hidden;
}

.notification-body > li a, .notification-body > li a:hover {
  text-decoration: none;
}

.notification-body > li:hover .progress {
  background: #FFF;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #A7A7A7 inset;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #CCC inset;
  -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px white inset;
}

.notification-body > li:hover .text-muted {
  color: #333333;
}

.notification-body .from {
  font-size: 14px;
  line-height: normal;
  color: #333333;
  font-weight: normal;
  margin-right: 60px;
  margin-bottom: 6px;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.notification-body .unread .from {
  font-weight: bold;
}

.notification-body .unread {
  background: #FFFFE0;
}

.notification-body time {
  position: absolute;
  top: 3px;
  right: 0;
  font-size: 11px;
  font-weight: normal;
  color: #058dc7;
}

.notification-body .msg-body, .notification-body .subject {
  font-size: 13px;
  color: #A0A0A0;
  max-height: 35px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.notification-body .subject {
  font-size: 13px;
  color: #333333;
  max-height: 22px;
}

/*
 * WIZARDS
 */
.bootstrapWizard {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
}

.bootstrapWizard a:hover, .bootstrapWizard a:active, .bootstrapWizard a:focus {
  text-decoration: none;
}

.bootstrapWizard li {
  display: block;
  float: left;
  width: 25%;
  text-align: center;
  padding-left: 0;
}

.bootstrapWizard li:before {
  border-top: 3px solid #55606E;
  content: "";
  display: block;
  font-size: 0;
  overflow: hidden;
  position: relative;
  top: 11px;
  right: 1px;
  width: 100%;
  z-index: 1;
}

.bootstrapWizard li:first-child:before {
  left: 50%;
  max-width: 50%;
}

.bootstrapWizard li:last-child:before {
  max-width: 50%;
  width: 50%;
}

.bootstrapWizard li.complete .step {
  background: #0aa66e;
  padding: 1px 6px;
  border: 3px solid #55606E;
}

.bootstrapWizard li .step i {
  font-size: 10px;
  font-weight: normal;
  position: relative;
  top: -1.5px;
}

.bootstrapWizard li .step {
  background: #B2B5B9;
  color: #FFF;
  display: inline;
  font-size: 15px;
  font-weight: bold;
  line-height: 12px;
  padding: 7px 13px;
  border: 3px solid transparent;
  border-radius: 50%;
  line-height: normal;
  position: relative;
  text-align: center;
  z-index: 2;
  transition: all 0.1s linear 0s;
}

.bootstrapWizard li.active .step, .bootstrapWizard li.active.complete .step {
  background: #0091d9;
  color: #FFF;
  font-weight: bold;
  padding: 7px 13px;
  font-size: 15px;
  border-radius: 50%;
  border: 3px solid #55606E;
}

.bootstrapWizard li.complete .title, .bootstrapWizard li.active .title {
  color: #2B3D53;
}

.bootstrapWizard li .title {
  color: #bfbfbf;
  display: block;
  font-size: 13px;
  line-height: 15px;
  max-width: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
  top: 20px;
  word-wrap: break-word;
  z-index: 104;
}

.wizard-actions {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
}

.wizard-actions li {
  display: inline;
}

.tab-content.transparent {
  background-color: transparent;
}

/*
 * FUELUX WIZARD
 */
.fuelux .wizard {
  position: relative;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *zoom: 1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
}

.fuelux .wizard:before,
.fuelux .wizard:after {
  display: table;
  line-height: 0;
  content: "";
}

.fuelux .wizard:after {
  clear: both;
}

.fuelux .wizard ul {
  width: 4000px;
  padding: 0;
  margin: 0;
  list-style: none outside none;
}

.fuelux .wizard ul.previous-disabled li.complete {
  cursor: default;
}

.fuelux .wizard ul.previous-disabled li.complete:hover {
  color: #356e35;
  cursor: default;
  background: #f3f4f5;
}

.fuelux .wizard ul.previous-disabled li.complete:hover .chevron:before {
  border-left-color: #f3f4f5;
}

.fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #999999;
  cursor: default;
  background: #ededed;
}

.fuelux .wizard ul li .chevron {
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #d4d4d4;
}

.fuelux .wizard ul li .chevron:before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #ededed;
  content: "";
}

.fuelux .wizard ul li.complete {
  color: #468847;
  background: #f3f4f5;
}

.fuelux .wizard ul li.complete:hover {
  cursor: pointer;
  background: #e7eff8;
}

.fuelux .wizard ul li.complete:hover .chevron:before {
  border-left: 14px solid #e7eff8;
}

.fuelux .wizard ul li.complete .chevron:before {
  border-left: 14px solid #f3f4f5;
}

.fuelux .wizard ul li.active {
  color: #3a87ad;
  background: #f1f6fc;
}

.fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid #f1f6fc;
}

.fuelux .wizard ul li .badge {
  margin-right: 8px;
}

.fuelux .wizard ul li:first-child {
  padding-left: 20px;
  border-radius: 4px 0 0 4px;
}

.fuelux .wizard .actions {
  position: absolute;
  right: 0;
  z-index: 2;
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 46px;
  vertical-align: middle;
  background-color: #eeeeee;
  border-left: 1px solid #d4d4d4;
}

.fuelux .wizard .actions a {
  margin-right: 8px;
  font-size: 12px;
  line-height: 45px;
}

.fuelux .wizard .actions .btn-prev i {
  margin-right: 5px;
}

.fuelux .wizard .actions .btn-next i {
  margin-left: 5px;
}

.fuelux .step-content .step-pane {
  display: none;
}

.fuelux .step-content .active {
  display: block;
}

.fuelux .step-content .active .btn-group .active {
  display: inline-block;
}

/*
 * jarvismetro TILE
 */
.jarvismetro-tile {
  float: left;
  display: block;
  background-color: #FFF;
  width: 100px;
  height: 70px;
  cursor: pointer;
  box-shadow: inset 0px 0px 1px #FFFFCC;
  border: 1px dotted #C5C5C5;
  text-decoration: none;
  color: #FFF;
  position: relative;
  font-weight: 300;
  font-smooth: always;
  margin: 0 10px 20px 0;
  padding: 5px;
  position: relative;
}

.jarvismetro-tile:hover {
  z-index: 10;
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
}

.jarvismetro-tile.big-cubes {
  width: 120px;
  height: 120px;
}

.jarvismetro-tile.double {
  width: 249px;
}

.jarvismetro-tile:active {
  top: 1px;
  left: 1px;
}

.jarvismetro-tile .iconbox {
  text-align: center;
}

.jarvismetro-tile .iconbox i {
  display: block;
  margin: 15px auto 0;
  height: 75px;
}

.jarvismetro-tile .iconbox span {
  display: block;
  text-align: left;
}

.jarvismetro-tile .iconbox span > span {
  position: absolute;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  padding: 5px 5px;
  border: 1px solid #FFF;
}

#shortcut .selected {
  border: 1px solid #0091d9 !important;
}

#shortcut .selected {
  position: relative;
}

#shortcut .selected:before {
  display: block;
  position: absolute;
  content: "";
  color: #FFF;
  right: 4px;
  top: 3px;
  font-family: FontAwesome;
  z-index: 2;
}

#shortcut .selected:after {
  display: block;
  width: 0;
  height: 0;
  border-top: 35px solid #0091d9;
  border-left: 35px solid rgba(0, 0, 0, 0);
  position: absolute;
  display: block;
  right: 0;
  content: "";
  top: 0;
  z-index: 1;
}

/*
 * SHORT CUT
 */
#shortcut {
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  height: auto;
  width: 100%;
  background-color: #33302F;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 907;
  display: none;
  color: #FFF;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#shortcut ul {
  padding: 15px 20px 10px;
  list-style: none;
  margin: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid #423F3F;
}

#shortcut ul li {
  display: inline-block;
  width: auto;
}

#shortcut ul li .jarvismetro-tile {
  margin: 0px 3px 3px;
  border: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

#shortcut ul li .jarvismetro-tile:hover {
  color: #FFF;
  text-decoration: none;
}

#shortcut ul li .jarvismetro-tile:active, #shortcut ul li .jarvismetro-tile:focus {
  left: 0;
  top: 0;
}

.shortcut-on #response-btn {
  display: none !important;
}

.shortcut-on #main .navbar, .shortcut-on #left-bar .navbar {
  border: none !important;
}

/*
 * ARROW BOX
 */
.arrow-box-up:after, .arrow-box-up:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box-up:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #FFF;
  border-width: 7px;
  left: 50%;
  margin-left: -7px;
}

.arrow-box-up:before {
  border-color: rgba(131, 131, 131, 0);
  border-bottom-color: #838383;
  border-width: 8px;
  left: 50%;
  margin-left: -8px;
}

/*
 * right
 */
.arrow-box-up-right:after, .arrow-box-up-right:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box-up-right:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #FFF;
  border-width: 7px;
  right: 10px;
  margin-left: -7px;
}

.arrow-box-up-right:before {
  border-color: rgba(131, 131, 131, 0);
  border-bottom-color: #838383;
  border-width: 8px;
  right: 9px;
  margin-left: -8px;
}

/*
 * BOOTSTRAP TOOLTIP
 */
.tooltip-inner {
  padding: 5px 10px;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border-radius: 0px;
}

/*
 * WEBKIT SCROLLING CSS
 * Reference: http://stackoverflow.com/questions/10592657/scrollbar-stylesheet
 * GitHub Source: https://gist.github.com/jambu/2004633
 */
.custom-scroll::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  height: 10px;
  width: 10px;
}

.custom-scroll::-webkit-scrollbar:hover {
  background-color: #E9E9E9;
  border: 1px solid #dbdbdb;
}

.custom-scroll::-webkit-scrollbar-button:start:decrement,
.custom-scroll::-webkit-scrollbar-button:end:increment {
  background: transparent;
  display: block;
  height: 0;
}

.custom-scroll::-webkit-scrollbar-track {
  background-clip: padding-box;
  border-width: 0 0 0 4px;
  border: solid transparent;
}

.custom-scroll::-webkit-scrollbar-track-piece {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-radius: 0;
}

.custom-scroll::-webkit-scrollbar-thumb {
  -moz-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
  border-width: 0;
  border: none;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

.custom-scroll::-webkit-scrollbar-thumb:vertical,
.custom-scroll::-webkit-scrollbar-thumb:horizontal {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: #bfbfbf;
  border-radius: 0;
}

.custom-scroll::-webkit-scrollbar-thumb:active {
  -moz-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.33);
  background-color: rgba(0, 0, 0, 0.44);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.33);
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #959595;
}

/*
 * Typehead
 */
.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
  height: 32px;
  padding: 6px 12px;
}

.tt-dropdown-menu {
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #FFF;
  border: 1px solid #bfbfbf;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;
}

.tt-suggestion.tt-is-under-cursor {
  color: #FFF;
  background-color: #0081c2;
}

.tt-suggestion.tt-is-under-cursor a {
  color: #FFF;
}

.tt-suggestion p {
  margin: 0;
}

/*
 * Google Maps
 */
.google_maps {
  width: 100%;
  height: 350px;
  position: relative;
}

.google_maps * {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
}

.google_maps img {
  max-width: none;
  /* needed for google map popup*/
}

/*
 * Header btn
 */
.header-btn {
  margin-top: 5px;
}

/*
 * CKEDITOR
 */
.cke_top {
  background: rgba(248, 248, 248, 0.9) !important;
  background-image: none !important;
}

/*
 * BS Multiselect
 * TODO:
 */
.multiselect-container {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.multiselect-container .input-group {
  margin: 5px;
}

.multiselect-container > li {
  padding: 0;
}

.multiselect-container > li > a.multiselect-all label {
  font-weight: bold;
}

.multiselect-container > li > label.multiselect-group {
  margin: 0;
  padding: 3px 20px 3px 20px;
  height: 100%;
  font-weight: bold;
}

.multiselect-container > li > a > label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  font-weight: normal;
}

.multiselect-container > li > a > label.radio, .multiselect-container > li > a > label.checkbox {
  margin: 0;
}

.multiselect-container > li > a > label > input[type=checkbox] {
  margin-bottom: 5px;
}

.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

/*
 * FORMS: INPUT WITH ICONS
 */
.input-group .icon-addon .form-control {
  border-radius: 0;
}

.icon-addon {
  position: relative;
  color: #555;
  display: block;
}

.icon-addon:after,
.icon-addon:before {
  display: table;
  content: " ";
}

.icon-addon:after {
  clear: both;
}

.icon-addon.addon-md .glyphicon,
.icon-addon .glyphicon,
.icon-addon.addon-md .fa,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.icon-addon.addon-lg .form-control {
  line-height: 1.33;
  height: 46px;
  font-size: 18px;
  padding: 10px 16px 10px 40px;
}

.input-group-lg .icon-addon.addon-lg + .input-group-btn > .btn {
  height: 46px;
}

.icon-addon.addon-sm .form-control {
  height: 30px;
  padding: 5px 10px 5px 28px;
  font-size: 12px;
  line-height: 1.5;
}

.icon-addon.addon-lg .fa,
.icon-addon.addon-lg .glyphicon {
  font-size: 18px;
  margin-left: 0;
  left: 11px;
  top: 4px;
}

.icon-addon.addon-md .form-control,
.icon-addon .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}

.icon-addon.addon-sm .fa,
.icon-addon.addon-sm .glyphicon {
  margin-left: 0;
  font-size: 12px;
  left: 5px;
  top: -1px;
}

.icon-addon .form-control:focus + .glyphicon,
.icon-addon:hover .glyphicon,
.icon-addon .form-control:focus + .fa,
.icon-addon:hover .fa {
  color: #2580db;
}

.icon-addon input:disabled + .glyphicon,
.icon-addon select:disabled + .glyphicon,
.icon-addon input:disabled + .fa,
.icon-addon select:disabled + .fa {
  color: #ccc;
}

/*
 * GENERAL MOBILE (Applies to all resolutions below 979px)
 */
@media (max-width: 979px) {
  /* mobile lock x-scroll for menu */
  html.hidden-menu-mobile-lock {
    overflow-x: hidden;
  }

  /* Force content to spread 100% */
  #main {
    width: 100% !important;
  }

  /* Footer */
  .page-footer {
    padding: 15px 14px 0;
  }

  /* Profile Page */
  .profile-pic {
    float: left;
  }
  .profile-pic > img {
    width: 110px;
    margin-right: 10px;
  }
  .profile-pic + div, .profile-pic + div + div {
    padding-left: 30px;
    padding-right: 30px;
  }

  /* added new class per version 1.3 
  html, body {
  	overflow-x: hidden !important;
  }*/
  #fullscreen > :first-child > a {
    display: none !important;
  }

  body.container {
    border: none !important;
    width: 100% !important;
  }

  .inbox-data-message > :first-child {
    height: 50px;
    overflow: hidden;
  }

  .show-stats .show-stat-buttons > :first-child {
    padding-right: 13px;
  }

  .show-stats .show-stat-buttons > :last-child {
    padding-left: 13px;
  }

  .inbox-checkbox-triggered > .btn-group .btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  .inbox-body .table-wrap {
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .inbox-nav-bar {
    padding-left: 5px;
    padding-right: 5px;
  }

  .inbox-data-message > :first-child > :first-child {
    display: block !important;
    font-size: 14px;
  }

  .inbox-table-icon > :first-child {
    margin-top: 12px;
  }

  #inbox-table .inbox-table-icon {
    padding-left: 10px !important;
  }

  /* search header */
  .header-search.pull-right {
    margin-left: 0px;
  }

  /* Visibility */
  .visible-tablet {
    display: inline-block !important;
  }

  .hidden-tablet, .display-inline.hidden-tablet {
    display: none !important;
  }

  .jarviswidget header h2 {
    width: 310px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  /* dropzone image fix */
  .dropzone .dz-default.dz-message {
    background-size: 100%;
    width: 200px;
    height: 43px;
    margin-left: -112px;
    margin-top: -23.5px;
  }
}
/* MOBILE LAYOUT STARTS HERE */
/*
 * IPAD HORIZONTAL
 */
@media (min-width: 768px) and (max-width: 979px) {
  #main::after {
    /*content: "IPAD HORIZONTAL";*/
  }

  #main {
    margin-left: 0;
  }

  #left-panel {
    left: -220px;
  }

  /* minified adjustment counter */
  .minified #left-panel {
    left: 0;
  }

  /* responsive menu button */
  #hide-menu > :first-child > a {
    margin-top: 9px;
    width: 40px;
  }

  .btn-header a {
    margin-top: 9px !important;
    width: 40px !important;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 100%;
  }

  .hidden-menu #hide-menu i {
    color: #FFF;
  }

  #hide-menu i::before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }

  .fixed-ribbon #ribbon {
    left: 0px;
  }

  .jarviswidget header h2 {
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  /*
   * Header search
   */
  .header-search > input[type=text] {
    margin-top: 9px;
  }
}
/*
 * IPAD VERTICAL
 */
@media (min-width: 768px) and (max-width: 880px) {
  #main::after {
    /*content: "IPAD VERTICAL";*/
  }

  #main {
    margin-left: 0;
    overflow-x: hidden;
  }

  #left-panel {
    left: -220px;
  }

  .no-content-padding {
    margin: 0 -14px !important;
  }

  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }

  /* minified adjustment counter */
  .minified #left-panel {
    left: 0;
  }

  /* responsive menu button */
  #hide-menu > :first-child > a, .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }

  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }

  /* transparent header button */
  .btn-header.transparent a {
    border: none !important;
    background: none;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }

  .btn-header.transparent a:hover {
    color: #a90329;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }

  .hidden-menu #hide-menu i {
    color: #FFF;
  }

  #hide-menu i::before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }

  .fixed-ribbon #ribbon {
    left: 0;
  }

  .jarviswidget header h2 {
    width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  /*
   * Header search
   */
  .header-search > input[type=text] {
    margin-top: 9px;
  }
}
/*
 * OTHER DEVICES
 */
@media (max-width: 767px) {
  /* Profile Page */
  .profile-pic > img {
    width: 100px;
  }

  #main::after {
    /*content: "OTHER DEVICES";*/
  }

  #main {
    margin-left: 0px;
    overflow-x: hidden;
  }

  #left-panel {
    left: -220px;
  }

  /* dashboard microcharts */
  .show-stat-microcharts > div {
    margin-top: 0px !important;
    border-right: none !important;
  }

  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  /* padding adjustment */
  #content, #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }

  #header {
    padding-right: 5px;
  }

  #logo {
    margin-left: 4px;
  }

  .no-content-padding {
    margin: 0 -5px !important;
  }

  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }

  /* minified adjustment counter */
  .minified #left-panel {
    left: 0;
  }

  /* transparent header button */
  .btn-header.transparent a {
    border: none !important;
    background: none;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }

  .btn-header.transparent a:hover {
    color: #a90329;
  }

  /* responsive menu button */
  #hide-menu > :first-child > a, .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }

  #hide-menu > :first-child > a {
    width: 50px !important;
  }

  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }

  .hidden-menu #hide-menu i {
    color: #FFF;
  }

  #hide-menu i::before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }

  .fixed-ribbon #ribbon {
    left: 0;
  }

  /*.fixed-navigation #left-panel {
  	position:absolute !important;
  }*/
  body.fixed-navigation.hidden-menu {
    overflow: hidden !important;
  }

  /*
   * fixed ribbon
   */
  .fixed-ribbon #content {
    padding-top: 52px;
  }

  /*
   * header search
   */
  .header-search {
    display: none;
  }

  #search-mobile {
    display: block;
  }

  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 65px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0px;
  }

  .search-mobile .header-search > input[type=text] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }

  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #dddddd;
  }

  .search-mobile .header-search > button {
    right: 44px;
  }

  .search-mobile #search-mobile {
    display: none;
  }

  .search-mobile #cancel-search-js {
    display: block;
  }

  .jarviswidget header h2 {
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
/*
 * ANDROID
 */
@media only screen and (min-width: 0px) and (max-width: 679px) {
  /* Profile Page */
  .profile-pic > img {
    width: 90px;
  }

  #main::after {
    /*content: "ANDROID";*/
  }

  #main {
    margin-left: 0;
    overflow-x: hidden;
  }

  #left-panel {
    left: -220px;
  }

  /* dashboard microcharts */
  .show-stat-microcharts > div {
    margin-top: 0px !important;
    border-right: none !important;
  }

  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  /* padding adjustment */
  #content, #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }

  #header {
    padding-right: 5px;
  }

  #logo {
    margin-left: 4px;
  }

  .no-content-padding {
    margin: 0 -5px !important;
  }

  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }

  /* minified adjustment counter */
  .minified #left-panel {
    left: 0;
  }

  /* header color altered and adjusted for mobile view & contrast */
  #header {
    background: #FFF;
  }

  #ribbon {
    border-bottom: 1px solid #CFCFCF;
    border-top: 1px solid #E6E6E6;
    background: #f5f5f5;
  }

  #ribbon .breadcrumb a, #ribbon .breadcrumb {
    color: #6d6c6c !important;
  }

  #ribbon .breadcrumb > .active, #ribbon .breadcrumb li:last-child {
    color: #333 !important;
  }

  /* responsive menu button */
  #hide-menu > :first-child > a, .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }

  #hide-menu > :first-child > a {
    width: 50px !important;
  }

  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }

  /* transparent header button */
  .btn-header.transparent a {
    border: none !important;
    background: none;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }

  .btn-header.transparent a:hover {
    color: #a90329;
  }

  /* menu btn cont... */
  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }

  .hidden-menu #hide-menu i {
    color: #FFF;
  }

  #hide-menu i::before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }

  .fixed-ribbon #ribbon {
    left: 0;
  }

  /*
   * header search
   */
  .header-search {
    display: none;
  }

  #search-mobile {
    display: block;
  }

  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 65px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0px;
  }

  .search-mobile .header-search > input[type=text] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }

  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #dddddd;
  }

  .search-mobile .header-search > button {
    right: 44px;
  }

  .search-mobile #search-mobile {
    display: none;
  }

  .search-mobile #cancel-search-js {
    display: block;
  }

  .hidden-tablet {
    display: none !important;
  }

  .jarviswidget header h2 {
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
/*
 * IPHONE
 */
@media only screen and (min-width: 320px) and (max-width: 479px) {
  /* Hide headings on phones as its not needed*/
  .page-title {
    display: none;
  }

  /* Profile Page */
  .profile-pic {
    text-align: left;
  }

  .profile-pic > img {
    width: 90px;
    margin-left: 0px;
    top: -25px;
    margin-right: 0px;
  }

  .profile-pic + div > h1 {
    font-size: 20px;
  }

  .profile-pic + div > h1 small {
    font-size: 16px;
  }

  .profile-carousel .carousel-indicators {
    top: 10px;
  }

  #main::after {
    /*content: "IPHONE";*/
  }

  #main {
    margin-left: 0;
    overflow-x: hidden;
  }

  #left-panel {
    left: -220px;
  }

  /* dashboard microcharts */
  .show-stat-microcharts > div {
    margin-top: 0px !important;
    border-right: none !important;
  }

  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  /* padding adjustment */
  #content, #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }

  #header {
    padding-right: 5px;
  }

  #logo {
    margin-left: 4px;
  }

  .no-content-padding {
    margin: 0 -5px !important;
  }

  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }

  /* minified adjustment counter */
  .minified #left-panel {
    left: 0;
  }

  /* header color altered and adjusted for mobile view & contrast */
  #header {
    background: #FFF;
  }

  #ribbon {
    border-bottom: 1px solid #cfcfcf;
    border-top: 1px solid #e6e6e6;
    background: #f5f5f5;
  }

  #ribbon .breadcrumb a, #ribbon .breadcrumb {
    color: #6d6c6c !important;
  }

  #ribbon .breadcrumb > .active, #ribbon .breadcrumb li:last-child {
    color: #333 !important;
  }

  /* lego width adjustment */
  #logo {
    width: 135px;
  }

  #logo-group {
    width: 169px !important;
  }

  /* spark line top */
  #sparks {
    text-align: center;
    background: #e0e0e0;
    padding: 10px 0;
    margin-bottom: 15px;
  }

  #sparks li {
    padding: 0 10px 0 20px;
  }

  /* responsive menu button */
  #hide-menu > :first-child > a, .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }

  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }

  /* transparent header button */
  .btn-header.transparent a {
    border: none !important;
    background: none;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }

  .btn-header.transparent a:hover {
    color: #a90329;
  }

  #hide-menu > :first-child > a {
    width: 46px !important;
  }

  #hide-menu i {
    color: #6d6a69;
    font-size: 121%;
  }

  .hidden-menu #hide-menu i {
    color: #FFF;
  }

  #hide-menu i::before {
    content: "";
  }

  .hidden-menu #left-panel {
    left: 0;
  }

  .hidden-menu #main {
    position: relative;
    left: 210px;
  }

  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }

  .fixed-ribbon #ribbon {
    left: 0px;
  }

  /* #*$^& Bootstrap 3 ! */
  .hidden-mobile {
    display: none !important;
  }

  .visible-mobile {
    display: inline-block !important;
  }

  /* activity panel */
  .ajax-notifications {
    height: 250px;
  }

  .ajax-dropdown {
    width: 299px;
    height: 320px;
    left: 0;
    top: 65px;
  }

  .ajax-dropdown:before {
    margin-left: -14px;
  }

  .ajax-dropdown:after {
    margin-left: -13px;
  }

  /*
   * header search
   */
  .header-search {
    display: none;
  }

  #search-mobile {
    display: block;
    margin-left: 0;
  }

  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 65px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0px;
  }

  .search-mobile .header-search > input[type=text] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }

  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #dddddd;
  }

  .search-mobile .header-search > button {
    right: 44px;
  }

  .search-mobile #search-mobile {
    display: none;
  }

  .search-mobile #cancel-search-js {
    display: block;
  }

  /* widget headers width */
  .jarviswidget header h2 {
    width: 135px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
/*
 * No Padding
 */
.no-content-padding {
  margin: -10px -14px 0 -14px;
  /*used with content >:first-child */
}

.no-padding {
  padding: 0px !important;
}
.no-padding > pre {
  margin: 30px;
}

.no-padding > table, .no-padding .dataTables_wrapper table {
  border: none !important;
  margin-bottom: 0px !important;
  border-bottom-width: 0px !important;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-7 {
  padding: 7px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-gutter {
  padding: 13px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.no-padding > table tr td:last-child, .no-padding > table tr th:last-child, .no-padding .dataTables_wrapper table tr td:last-child, .no-padding .dataTables_wrapper table tr th:last-child {
  border-right: none !important;
}

.no-padding > table tr td:first-child, .no-padding > table tr th:first-child, .no-padding .dataTables_wrapper table tr td:first-child, .no-padding .dataTables_wrapper table tr th:first-child {
  border-left: none !important;
}

.no-padding .dataTables_wrapper table tbody tr:last-child td {
  border-bottom: 0;
}

/*
 * Font Weights
 */
.semi-bold {
  font-weight: 400 !important;
}

.ultra-light {
  font-weight: 300 !important;
}

/*
 * Font Sizes
 */
.font-xs {
  font-size: 85% !important;
}

.font-sm {
  font-size: 95% !important;
}

.font-md {
  font-size: 130% !important;
}

.font-lg {
  font-size: 160% !important;
}

.font-xl {
  font-size: 200% !important;
}

.font-400 {
  font-size: 400% !important;
}

/*
 * Margins
 */
.no-margin {
  margin: 0px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

/*
 * No border
 */
.no-border, .well[class*=" bg-"].no-border {
  border-width: 0px !important;
}

.no-border-transparent {
  border-color: transparent !important;
}

.no-border-radius {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

/*
 * Inlines
 */
.display-inline {
  display: inline-block !important;
}

.display-normal {
  display: inline !important;
}

/*
 * CURSORS
 */
.cursor-pointer {
  cursor: pointer;
}

/*
 * Images
 */
.hover-transparent {
  opacity: 0.5;
}

.hover-transparent:hover {
  opacity: 1;
}

/*
 * Forced text alignments
 */
.text-align-center, .text-center {
  text-align: center !important;
}

.text-align-left, .text-left {
  text-align: left !important;
}

.text-align-right, .text-right {
  text-align: right !important;
}

/*
 * HR
 */
hr.simple {
  margin-top: 10px;
  margin-bottom: 10px;
  border-style: dashed;
}

/*
 * SHORT CUTS
 */
.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

/*
 * Air fields
 * Description: absolute positioned objects
 */
.air {
  position: absolute;
  z-index: 5;
}

.air-bottom {
  bottom: 0px;
}

.air-top {
  top: 0px;
}

.air-top-right {
  top: 0px;
  right: 0px;
}

.air-top-left {
  top: 0px;
  left: 0px;
}

.air-bottom-left {
  bottom: 0px;
  left: 0px;
}

.air-bottom-right {
  bottom: 0px;
  right: 0px;
}

/*
 * Display none
 */
.display-none {
  display: none !important;
}

/*!
 * ClockPicker v{package.version} for Bootstrap (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/master/LICENSE)
 */
.clockpicker .input-group-addon {
  cursor: pointer;
}

.clockpicker-moving {
  cursor: move;
}

.clockpicker-align-left.popover > .arrow {
  left: 25px;
}

.clockpicker-align-top.popover > .arrow {
  top: 17px;
}

.clockpicker-align-right.popover > .arrow {
  left: auto;
  right: 25px;
}

.clockpicker-align-bottom.popover > .arrow {
  top: auto;
  bottom: 6px;
}

.clockpicker-popover .popover-title {
  background-color: #fff;
  color: #999;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
}
.clockpicker-popover .popover-title span {
  cursor: pointer;
}
.clockpicker-popover .popover-content {
  background-color: #f8f8f8;
  padding: 12px;
}

.popover-content:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.clockpicker-plate {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: visible;
  position: relative;
  /* Disable text selection highlighting. Thanks to Hermanya */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clockpicker-canvas, .clockpicker-dial {
  width: 200px;
  height: 200px;
  position: absolute;
  left: -1px;
  top: -1px;
}

.clockpicker-minutes {
  visibility: hidden;
}

.clockpicker-tick {
  border-radius: 50%;
  color: #666;
  line-height: 26px;
  text-align: center;
  width: 26px;
  height: 26px;
  position: absolute;
  cursor: pointer;
}
.clockpicker-tick.active, .clockpicker-tick:hover {
  background-color: #c0e5f7;
  background-color: rgba(0, 149, 221, 0.25);
}

.clockpicker-button {
  background-image: none;
  background-color: #fff;
  border-width: 1px 0 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  padding: 10px 0;
}
.clockpicker-button:hover {
  background-image: none;
  background-color: #ebebeb;
}
.clockpicker-button:focus {
  outline: none !important;
}

.clockpicker-dial {
  -webkit-transition: -webkit-transform 350ms, opacity 350ms;
  -moz-transition: -moz-transform 350ms, opacity 350ms;
  -ms-transition: -ms-transform 350ms, opacity 350ms;
  -o-transition: -o-transform 350ms, opacity 350ms;
  transition: transform 350ms, opacity 350ms;
}

.clockpicker-dial-out {
  opacity: 0;
}

.clockpicker-hours.clockpicker-dial-out {
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}

.clockpicker-minutes.clockpicker-dial-out {
  -webkit-transform: scale(0.8, 0.8);
  -moz-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
}

.clockpicker-canvas {
  -webkit-transition: opacity 175ms;
  -moz-transition: opacity 175ms;
  -ms-transition: opacity 175ms;
  -o-transition: opacity 175ms;
  transition: opacity 175ms;
}
.clockpicker-canvas line {
  stroke: #0095dd;
  stroke-width: 1;
  stroke-linecap: round;
  /*shape-rendering: crispEdges;*/
}

.clockpicker-canvas-out {
  opacity: 0.25;
}

.clockpicker-canvas-bearing, .clockpicker-canvas-fg {
  stroke: none;
  fill: #0095dd;
}

.clockpicker-canvas-bg {
  stroke: none;
  fill: #c0e5f7;
}

.clockpicker-canvas-bg-trans {
  fill: rgba(0, 149, 221, 0.25);
}

@-ms-viewport {}
/* --- Basics --- */
.loading {
  display: inline-block;
  overflow: hidden;
  height: 1.3em;
  margin-top: -0.3em;
  line-height: 1.5em;
  vertical-align: text-bottom;
}

.loading::after {
  display: inline-table;
  white-space: pre;
  text-align: left;
}

/* --- Animations --- */
@keyframes spin1 {
  to {
    transform: translateY(-1.5em);
  }
}
@keyframes spin2 {
  to {
    transform: translateY(-3em);
  }
}
@keyframes spin3 {
  to {
    transform: translateY(-4.5em);
  }
}
@keyframes spin4 {
  to {
    transform: translateY(-6em);
  }
}
@keyframes spin5 {
  to {
    transform: translateY(-7.5em);
  }
}
@keyframes spin6 {
  to {
    transform: translateY(-9em);
  }
}
@keyframes spin7 {
  to {
    transform: translateY(-10.5em);
  }
}
@keyframes spin8 {
  to {
    transform: translateY(-12em);
  }
}
@keyframes spin9 {
  to {
    transform: translateY(-13.5em);
  }
}
@keyframes spin10 {
  to {
    transform: translateY(-15em);
  }
}
@keyframes spin11 {
  to {
    transform: translateY(-16.5em);
  }
}
@keyframes spin12 {
  to {
    transform: translateY(-18em);
  }
}
.loading::after {
  content: "\a.\a..\a...";
  animation: spin4 2s steps(4) infinite;
}

.loading.triangles::after {
  content: "▹▹▹▹▹\a▸▹▹▹▹\a▹▸▹▹▹\a▹▹▸▹▹\a▹▹▹▸▹\a▹▹▹▹▸";
  animation: spin6 1s steps(6) infinite;
}

.loading.beam::after {
  content: "\a=   \a==  \a=== \a====\a  ===\a   ==\a    =\a";
  animation: spin9 1.2s steps(9) infinite;
  font-family: monospace;
}

.loading.bullet::after {
  content: " ●    \a   ●   \a    ●  \a     ● \a      ●\a     ● \a    ●  \a   ●   \a  ●    \a●     ";
  animation: spin10 1s steps(10) infinite;
}

.loading.circle::after {
  content: "◴\a◷\a◶\a◵";
  animation: spin4 1s steps(4) infinite;
}

.loading.open-circle::after {
  content: "◜\a◠\a◝\a◞\a◡\a◟";
  animation: spin6 0.6s steps(6) infinite;
}

.loading.lifting::after {
  content: "꜈꜍\a꜉꜎\a꜊꜏\a꜋꜐\a꜌꜑";
  animation: spin5 0.5s steps(5) infinite alternate;
}

.loading_global_right {
  position: fixed;
  bottom: 17px;
  right: -5px;
  z-index: 9999;
  max-width: 500px;
  margin-right: 15px;
}

.btnwarwhite {
  background-color: #FFF;
  border-color: #c79121;
  color: #c79121;
}
.btnwarwhite:hover {
  background-color: #c79121;
  color: #FFF;
}

.btnsucceswhite {
  background-color: #FFF;
  border-color: #15660e;
  color: #15660e;
}
.btnsucceswhite:hover {
  background-color: #15660e;
  color: #FFF;
}

.btndangerwhite {
  background-color: #FFF;
  border-color: #a90329;
  color: #a90329;
}
.btndangerwhite:hover {
  background-color: #a90329;
  color: #FFF;
}

.btnprimarywhite {
  background-color: #FFF !important;
  border-color: #008BD1 !important;
  color: #008BD1 !important;
}
.btnprimarywhite:hover {
  background-color: #008BD1 !important;
  color: #FFF !important;
}

/*
 * jarvismetro TILE
 */
.jarvismetro-tile {
  float: left;
  display: block;
  background-color: #FFF;
  width: 100px;
  height: 70px;
  cursor: pointer;
  box-shadow: inset 0px 0px 1px #FFFFCC;
  border: 1px dotted #C5C5C5;
  text-decoration: none;
  color: #FFF;
  position: relative;
  font-weight: 300;
  font-smooth: always;
  margin: 0 10px 20px 0;
  padding: 5px;
  position: relative;
}

.jarvismetro-tile:hover {
  z-index: 10;
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
}

.jarvismetro-tile.big-cubes {
  width: 120px;
  height: 120px;
}

.jarvismetro-tile.double {
  width: 249px;
}

.jarvismetro-tile:active {
  top: 1px;
  left: 1px;
}

.jarvismetro-tile .iconbox {
  text-align: center;
}

.jarvismetro-tile .iconbox i {
  display: block;
  margin: 15px auto 0;
  height: 75px;
}

.jarvismetro-tile .iconbox span {
  display: block;
  text-align: left;
}

.jarvismetro-tile .iconbox span > span {
  position: absolute;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  padding: 5px 5px;
  border: 1px solid #FFF;
}

#shortcut .selected {
  border: 1px solid #0091d9 !important;
}

#shortcut .selected {
  position: relative;
}

#shortcut .selected:before {
  display: block;
  position: absolute;
  content: "";
  color: #FFF;
  right: 4px;
  top: 3px;
  font-family: FontAwesome;
  z-index: 2;
}

#shortcut .selected:after {
  display: block;
  width: 0;
  height: 0;
  border-top: 35px solid #0091d9;
  border-left: 35px solid rgba(0, 0, 0, 0);
  position: absolute;
  display: block;
  right: 0;
  content: "";
  top: 0;
  z-index: 1;
}

/*
 * SHORT CUT
 */
#shortcut {
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  height: auto;
  width: 100%;
  background-color: #33302F;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 907;
  display: none;
  color: #FFF;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#shortcut ul {
  padding: 15px 20px 10px;
  list-style: none;
  margin: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid #423F3F;
}

#shortcut ul li {
  display: inline-block;
  width: auto;
}

#shortcut ul li .jarvismetro-tile {
  margin: 0px 3px 3px;
  border: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

#shortcut ul li .jarvismetro-tile:hover {
  color: #FFF;
  text-decoration: none;
}

#shortcut ul li .jarvismetro-tile:active, #shortcut ul li .jarvismetro-tile:focus {
  left: 0;
  top: 0;
}

.shortcut-on #response-btn {
  display: none !important;
}

.shortcut-on #main .navbar, .shortcut-on #left-bar .navbar {
  border: none !important;
}

.jarvismetro-tile.big-cubes {
  width: 130px;
  height: 130px;
}

.no-padding .table-responsive .table {
  margin-bottom: 0;
}

.table .devise {
  width: 80%;
  text-align: center;
}

.th_center th {
  text-align: center;
}

.maxinput input, .maxinput select {
  margin: 1px;
  padding: 3px 3px;
  height: 32px;
}

.table.maxinput > tbody > tr > td {
  padding: 2px;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@-ms-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@-o-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.fa-pulsate {
  -webkit-animation: pulsate 1s infinite;
  -moz-animation: pulsate 1s infinite;
  -ms-animation: pulsate 1s infinite;
  -o-animation: pulsate 1s infinite;
  animation: pulsate 1s infinite;
}

.table.table_synthese tr th, .table.table_synthese tr td {
  border-top: none;
}

label {
  font-weight: 400;
}

fieldset {
  padding: 20px;
  border: 1px solid #999999;
}

fieldset.login {
  padding: 0px;
  border: 0px;
}

.input_center input {
  text-align: center;
}

.form-control {
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

/**
* Dashboard
*/
.date_order {
  line-height: 20px;
}
.date_order input {
  height: 28px;
  margin-top: 2px;
  color: #000;
  margin-left: 5px;
  margin-right: 10px;
}
.date_order .btn {
  margin-top: -2px;
  line-height: 20px;
}

/*
 * Card component
 */
.card {
  position: relative;
  background-color: #F7F7F7;
  /* just in case there no content*/
  padding: 0px;
  padding-bottom: 20px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

/** LOGIN **/
.login {
  margin-top: 30px;
}
.login .logo {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 15px;
  height: 75px;
}
.login .logo img {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}

.lock {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
}
.lock .circle {
  float: left;
  background-color: #008BD1;
  -webkit-border-radius: 50px;
  -khtml-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  padding: 10px;
}
.lock .circle .child-circle {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-border-radius: 50px;
  -khtml-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
  background-color: #FFF;
}
.lock .circle .child-circle span {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
}

.devise_col {
  float: left;
  width: 25%;
}

/** END LOGIN **/
.addmultiperm .row {
  margin-bottom: 5px;
  padding: 0px;
}

.permissions {
  margin-top: 20px;
}
.permissions li {
  list-style-type: none;
}
.permissions ul {
  padding-left: 10px;
  min-height: 80px;
}

.tab-pane {
  padding-top: 20px;
}

.addoncustom .input-group {
  margin-bottom: 10px;
}
.addoncustom .input-group-addon {
  padding: 2px 12px;
  margin-bottom: 15px;
}

.qty {
  width: 60px;
}

.selectqty {
  width: 90%;
}

.table tbody td.labelinput {
  padding-top: 12px;
}

.controllabel {
  text-align: right;
  margin-bottom: 0;
  padding-top: 7px;
}

/** TOGGLE **/
.toggle {
  margin-bottom: 4px;
  font-size: 15px;
  color: #404040;
  cursor: pointer;
  box-sizing: content-box;
}
.toggle:last-child {
  margin-bottom: 0;
}
.toggle input {
  position: absolute;
  left: -9999px;
}
.toggle input:checked + i:after {
  content: attr(data-swchon-text);
  text-align: right;
}
.toggle input:checked + i:before {
  right: 36px;
}
.toggle i {
  content: "";
  position: absolute;
  top: 12px;
  display: block;
  width: 49px;
  height: 17px;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  background: #fff;
  box-sizing: content-box;
}
.toggle i:after {
  content: attr(data-swchoff-text);
  position: absolute;
  top: 2px;
  right: 8px;
  left: 8px;
  font-style: normal;
  font-size: 9px;
  line-height: 13px;
  font-weight: 700;
  text-align: left;
  color: #5f5f5f;
}
.toggle i:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  opacity: 1;
  transition: right 0.2s;
  -o-transition: right 0.2s;
  -ms-transition: right 0.2s;
  -moz-transition: right 0.2s;
  -webkit-transition: right 0.2s;
  background-color: #a90329;
}

.toggle input:checked + i {
  border-color: #3276B1;
}

.progress {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  height: 22px;
  background: #eeeeee;
  box-shadow: 0 1px 0 transparent, 0 0 0 1px #e3e3e3 inset;
  -webkit-box-shadow: 0 1px 0 transparent, 0 0 0 1px #e3e3e3 inset;
  -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px #e3e3e3 inset;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 11px;
  color: #FFF;
  text-align: center;
  background-color: #57889c;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  font-weight: bold;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-image: url("../img/pattern/overlay-pattern.png");
}

.percent {
  font-size: 14px;
}

.dd3-content .users {
  font-size: 80%;
  margin-left: 20px;
  font-style: italic;
}

#nestable .delTeam {
  position: absolute;
  right: 5px;
  top: 9px;
  color: #a90329;
}
#nestable .delTeam:hover {
  color: #555555;
  cursor: pointer;
}

/*
#nestable  .dd3-handle.child {

  &::before {
    background-color: #2a6395;
    color: $white;
  }
}*/
.select2-container {
  margin: 0;
  position: relative;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.select2-container .select2-choice {
  display: block;
  height: 32px;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
  white-space: nowrap;
  line-height: 32px;
  color: #444;
  text-decoration: none;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #ccc;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 8px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}

.select2-container .select2-choice abbr:hover {
  cursor: pointer;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  background: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  margin-top: -1px;
  width: 100%;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-top: 0;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-auto-width {
  border-top: 1px solid #ccc;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

.select2-drop.select2-drop-above {
  margin-bottom: 0;
  border-top: 1px solid #5D98CC;
  border-top-width: 3px;
  border-bottom: 0;
  -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-active {
  border: 1px solid #5D98CC;
  border-top: none;
  border-bottom-width: 3px;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-top-width: 3px;
}

.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 34px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #ccc;
  background: #eee;
}

.select2-container .select2-choice .select2-arrow b {
  width: 100%;
  height: 100%;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-size: 14px;
}

.select2-container .select2-choice .select2-arrow b:before {
  content: "";
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
}

.select2-search input {
  width: 100%;
  height: auto !important;
  min-height: 29px;
  padding: 6px 20px 5px 10px;
  margin: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: 1em;
  border: 1px solid #aaa;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: linear-gradient(top, #fff 85%, #eee 99%);
}

.select2-search:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  content: "";
  top: 25%;
  right: 10px;
  color: #686868 !important;
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
}

.select2-drop.select2-drop-above .select2-search:before {
  top: 34%;
}

.select2-search input.select2-active {
  background: #fff url(../img/select2-spinner.gif) no-repeat 100%;
  background: url(../img/select2-spinner.gif) no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url(../img/select2-spinner.gif) no-repeat 100%, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url(../img/select2-spinner.gif) no-repeat 100%, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url(../img/select2-spinner.gif) no-repeat 100%, linear-gradient(top, #fff 85%, #eee 99%);
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 1px solid #5D98CC;
  outline: 0;
}

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  -webkit-box-shadow: 0 1px 0 #fff inset;
  box-shadow: 0 1px 0 #fff inset;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #5D98CC;
  border-top-color: transparent;
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: 0 0;
  border-left: none;
  filter: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}

.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label {
  padding-left: 20px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 40px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 60px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 80px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 100px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 110px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 120px;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: 700;
}

.select2-results .select2-result-label {
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-results .select2-highlighted {
  background: #3276b1;
  color: #fff;
}

.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: 0 0;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
  padding: 3px 5px;
}

.select2-no-results {
  padding-left: 20px;
}

.select2-no-results:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  margin-right: 5px;
}

.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url(../img/select2-spinner.gif) no-repeat 100%;
}

.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}

.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}

.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #ccc;
  cursor: text;
  overflow: hidden;
  background-color: #fff;
}

.select2-locked {
  padding: 3px 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #5D98CC;
  outline: 0;
}

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url(../img/select2-spinner.gif) no-repeat 100% !important;
}

.select2-default {
  color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 1px 28px 1px 8px;
  margin: 4px 0 3px 5px;
  position: relative;
  line-height: 18px;
  color: #fff;
  cursor: default;
  border: 1px solid #2a6395;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #3276b1;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  opacity: 0.8;
}

.select2-search-choice-close {
  display: block;
  min-width: 21px;
  min-height: 20px;
  position: absolute;
  right: 3px;
  top: 3px;
  margin: 0;
  padding: 0;
  font-size: 15px;
  text-decoration: none !important;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select2-search-choice-close:before {
  color: #fff;
  content: "";
}

.select2-container-multi .select2-search-choice-close {
  display: block;
  top: 0;
  right: 0;
  padding: 3px 4px 3px 6px;
}

.select2-container-multi .select2-search-choice-close:hover {
  background: rgba(0, 0, 0, 0.3);
}

.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px;
  border: 1px solid #4a90cc;
  background-image: none;
  background-color: #86b4dd;
  cursor: not-allowed;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: 0 0;
}

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

.has-error .select2-choice,
.has-error .select2-container .select2-choice .select2-arrow,
.has-error .select2-container-multi .select2-choices {
  border-color: #b94a48;
}

.has-error .select2-container .select2-choice .select2-arrow {
  background: #f2dede;
  color: #b94a48;
}

.has-error .select2-dropdown-open .select2-choice {
  border-color: #5D98CC !important;
}

.select2-drop.select2-drop-above.select2-drop-active {
  margin-top: 2px;
}

/*
@import "plugins/_plugin-dataTables.bootstrap";
@import "plugins/_plugin-dataTables.colReorder";
@import "plugins/_plugin-dataTables.colVis";
@import "plugins/_plugin-dataTables.responsive";
@import "plugins/_plugin-dataTables.tableTools";
@import "plugins/_plugin-dataTables-cust";
*/
.fa-1_5X {
  font-size: 1.9rem;
}

.flag {
  width: 16px;
  height: 11px;
  background: url("../img/flags/flags.png") no-repeat;
  margin-top: -0.18em;
  display: inline-block;
}

.flag.flag-ad {
  background-position: -16px 0;
}

.flag.flag-ae {
  background-position: -32px 0;
}

.flag.flag-af {
  background-position: -48px 0;
}

.flag.flag-ag {
  background-position: -64px 0;
}

.flag.flag-ai {
  background-position: -80px 0;
}

.flag.flag-al {
  background-position: -96px 0;
}

.flag.flag-am {
  background-position: -112px 0;
}

.flag.flag-an {
  background-position: -128px 0;
}

.flag.flag-ao {
  background-position: -144px 0;
}

.flag.flag-ar {
  background-position: -160px 0;
}

.flag.flag-as {
  background-position: -176px 0;
}

.flag.flag-at {
  background-position: -192px 0;
}

.flag.flag-au {
  background-position: -208px 0;
}

.flag.flag-aw {
  background-position: -224px 0;
}

.flag.flag-az {
  background-position: -240px 0;
}

.flag.flag-ba {
  background-position: 0 -11px;
}

.flag.flag-bb {
  background-position: -16px -11px;
}

.flag.flag-bd {
  background-position: -32px -11px;
}

.flag.flag-be {
  background-position: -48px -11px;
}

.flag.flag-bf {
  background-position: -64px -11px;
}

.flag.flag-bg {
  background-position: -80px -11px;
}

.flag.flag-bh {
  background-position: -96px -11px;
}

.flag.flag-bi {
  background-position: -112px -11px;
}

.flag.flag-bj {
  background-position: -128px -11px;
}

.flag.flag-bm {
  background-position: -144px -11px;
}

.flag.flag-bn {
  background-position: -160px -11px;
}

.flag.flag-bo {
  background-position: -176px -11px;
}

.flag.flag-br {
  background-position: -192px -11px;
}

.flag.flag-bs {
  background-position: -208px -11px;
}

.flag.flag-bt {
  background-position: -224px -11px;
}

.flag.flag-bv {
  background-position: -240px -11px;
}

.flag.flag-bw {
  background-position: 0 -22px;
}

.flag.flag-by {
  background-position: -16px -22px;
}

.flag.flag-bz {
  background-position: -32px -22px;
}

.flag.flag-ca {
  background-position: -48px -22px;
}

.flag.flag-catalonia {
  background-position: -64px -22px;
}

.flag.flag-cd {
  background-position: -80px -22px;
}

.flag.flag-cf {
  background-position: -96px -22px;
}

.flag.flag-cg {
  background-position: -112px -22px;
}

.flag.flag-ch {
  background-position: -128px -22px;
}

.flag.flag-ci {
  background-position: -144px -22px;
}

.flag.flag-ck {
  background-position: -160px -22px;
}

.flag.flag-cl {
  background-position: -176px -22px;
}

.flag.flag-cm {
  background-position: -192px -22px;
}

.flag.flag-cn {
  background-position: -208px -22px;
}

.flag.flag-co {
  background-position: -224px -22px;
}

.flag.flag-cr {
  background-position: -240px -22px;
}

.flag.flag-cu {
  background-position: 0 -33px;
}

.flag.flag-cv {
  background-position: -16px -33px;
}

.flag.flag-cw {
  background-position: -32px -33px;
}

.flag.flag-cy {
  background-position: -48px -33px;
}

.flag.flag-cz {
  background-position: -64px -33px;
}

.flag.flag-de {
  background-position: -80px -33px;
}

.flag.flag-dj {
  background-position: -96px -33px;
}

.flag.flag-dk {
  background-position: -112px -33px;
}

.flag.flag-dm {
  background-position: -128px -33px;
}

.flag.flag-do {
  background-position: -144px -33px;
}

.flag.flag-dz {
  background-position: -160px -33px;
}

.flag.flag-ec {
  background-position: -176px -33px;
}

.flag.flag-ee {
  background-position: -192px -33px;
}

.flag.flag-eg {
  background-position: -208px -33px;
}

.flag.flag-eh {
  background-position: -224px -33px;
}

.flag.flag-en {
  background-position: -176px -44px;
}

.flag.flag-england {
  background-position: -240px -33px;
}

.flag.flag-er {
  background-position: 0 -44px;
}

.flag.flag-es {
  background-position: -16px -44px;
}

.flag.flag-et {
  background-position: -32px -44px;
}

.flag.flag-eu {
  background-position: -48px -44px;
}

.flag.flag-fi {
  background-position: -64px -44px;
}

.flag.flag-fj {
  background-position: -80px -44px;
}

.flag.flag-fk {
  background-position: -96px -44px;
}

.flag.flag-fm {
  background-position: -112px -44px;
}

.flag.flag-fo {
  background-position: -128px -44px;
}

.flag.flag-fr {
  background-position: -144px -44px;
}

.flag.flag-ga {
  background-position: -160px -44px;
}

.flag.flag-gb {
  background-position: -176px -44px;
}

.flag.flag-gd {
  background-position: -192px -44px;
}

.flag.flag-ge {
  background-position: -208px -44px;
}

.flag.flag-gf {
  background-position: -224px -44px;
}

.flag.flag-gg {
  background-position: -240px -44px;
}

.flag.flag-gh {
  background-position: 0 -55px;
}

.flag.flag-gi {
  background-position: -16px -55px;
}

.flag.flag-gl {
  background-position: -32px -55px;
}

.flag.flag-gm {
  background-position: -48px -55px;
}

.flag.flag-gn {
  background-position: -64px -55px;
}

.flag.flag-gp {
  background-position: -80px -55px;
}

.flag.flag-gq {
  background-position: -96px -55px;
}

.flag.flag-gr {
  background-position: -112px -55px;
}

.flag.flag-gs {
  background-position: -128px -55px;
}

.flag.flag-gt {
  background-position: -144px -55px;
}

.flag.flag-gu {
  background-position: -160px -55px;
}

.flag.flag-gw {
  background-position: -176px -55px;
}

.flag.flag-gy {
  background-position: -192px -55px;
}

.flag.flag-hk {
  background-position: -208px -55px;
}

.flag.flag-hm {
  background-position: -224px -55px;
}

.flag.flag-hn {
  background-position: -240px -55px;
}

.flag.flag-hr {
  background-position: 0 -66px;
}

.flag.flag-ht {
  background-position: -16px -66px;
}

.flag.flag-hu {
  background-position: -32px -66px;
}

.flag.flag-ic {
  background-position: -48px -66px;
}

.flag.flag-id {
  background-position: -64px -66px;
}

.flag.flag-ie {
  background-position: -80px -66px;
}

.flag.flag-il {
  background-position: -96px -66px;
}

.flag.flag-im {
  background-position: -112px -66px;
}

.flag.flag-in {
  background-position: -128px -66px;
}

.flag.flag-io {
  background-position: -144px -66px;
}

.flag.flag-iq {
  background-position: -160px -66px;
}

.flag.flag-ir {
  background-position: -176px -66px;
}

.flag.flag-is {
  background-position: -192px -66px;
}

.flag.flag-it {
  background-position: -208px -66px;
}

.flag.flag-je {
  background-position: -224px -66px;
}

.flag.flag-jm {
  background-position: -240px -66px;
}

.flag.flag-jo {
  background-position: 0 -77px;
}

.flag.flag-jp {
  background-position: -16px -77px;
}

.flag.flag-ke {
  background-position: -32px -77px;
}

.flag.flag-kg {
  background-position: -48px -77px;
}

.flag.flag-kh {
  background-position: -64px -77px;
}

.flag.flag-ki {
  background-position: -80px -77px;
}

.flag.flag-km {
  background-position: -96px -77px;
}

.flag.flag-kn {
  background-position: -112px -77px;
}

.flag.flag-kp {
  background-position: -128px -77px;
}

.flag.flag-kr {
  background-position: -144px -77px;
}

.flag.flag-kurdistan {
  background-position: -160px -77px;
}

.flag.flag-kw {
  background-position: -176px -77px;
}

.flag.flag-ky {
  background-position: -192px -77px;
}

.flag.flag-kz {
  background-position: -208px -77px;
}

.flag.flag-la {
  background-position: -224px -77px;
}

.flag.flag-lb {
  background-position: -240px -77px;
}

.flag.flag-lc {
  background-position: 0 -88px;
}

.flag.flag-li {
  background-position: -16px -88px;
}

.flag.flag-lk {
  background-position: -32px -88px;
}

.flag.flag-lr {
  background-position: -48px -88px;
}

.flag.flag-ls {
  background-position: -64px -88px;
}

.flag.flag-lt {
  background-position: -80px -88px;
}

.flag.flag-lu {
  background-position: -96px -88px;
}

.flag.flag-lv {
  background-position: -112px -88px;
}

.flag.flag-ly {
  background-position: -128px -88px;
}

.flag.flag-ma {
  background-position: -144px -88px;
}

.flag.flag-mc {
  background-position: -160px -88px;
}

.flag.flag-md {
  background-position: -176px -88px;
}

.flag.flag-me {
  background-position: -192px -88px;
}

.flag.flag-mg {
  background-position: -208px -88px;
}

.flag.flag-mh {
  background-position: -224px -88px;
}

.flag.flag-mk {
  background-position: -240px -88px;
}

.flag.flag-ml {
  background-position: 0 -99px;
}

.flag.flag-mm {
  background-position: -16px -99px;
}

.flag.flag-mn {
  background-position: -32px -99px;
}

.flag.flag-mo {
  background-position: -48px -99px;
}

.flag.flag-mp {
  background-position: -64px -99px;
}

.flag.flag-mq {
  background-position: -80px -99px;
}

.flag.flag-mr {
  background-position: -96px -99px;
}

.flag.flag-ms {
  background-position: -112px -99px;
}

.flag.flag-mt {
  background-position: -128px -99px;
}

.flag.flag-mu {
  background-position: -144px -99px;
}

.flag.flag-mv {
  background-position: -160px -99px;
}

.flag.flag-mw {
  background-position: -176px -99px;
}

.flag.flag-mx {
  background-position: -192px -99px;
}

.flag.flag-my {
  background-position: -208px -99px;
}

.flag.flag-mz {
  background-position: -224px -99px;
}

.flag.flag-na {
  background-position: -240px -99px;
}

.flag.flag-nc {
  background-position: 0 -110px;
}

.flag.flag-ne {
  background-position: -16px -110px;
}

.flag.flag-nf {
  background-position: -32px -110px;
}

.flag.flag-ng {
  background-position: -48px -110px;
}

.flag.flag-ni {
  background-position: -64px -110px;
}

.flag.flag-nl {
  background-position: -80px -110px;
}

.flag.flag-no {
  background-position: -96px -110px;
}

.flag.flag-np {
  background-position: -112px -110px;
}

.flag.flag-nr {
  background-position: -128px -110px;
}

.flag.flag-nu {
  background-position: -144px -110px;
}

.flag.flag-nz {
  background-position: -160px -110px;
}

.flag.flag-om {
  background-position: -176px -110px;
}

.flag.flag-pa {
  background-position: -192px -110px;
}

.flag.flag-pe {
  background-position: -208px -110px;
}

.flag.flag-pf {
  background-position: -224px -110px;
}

.flag.flag-pg {
  background-position: -240px -110px;
}

.flag.flag-ph {
  background-position: 0 -121px;
}

.flag.flag-pk {
  background-position: -16px -121px;
}

.flag.flag-pl {
  background-position: -32px -121px;
}

.flag.flag-pm {
  background-position: -48px -121px;
}

.flag.flag-pn {
  background-position: -64px -121px;
}

.flag.flag-pr {
  background-position: -80px -121px;
}

.flag.flag-ps {
  background-position: -96px -121px;
}

.flag.flag-pt {
  background-position: -112px -121px;
}

.flag.flag-pw {
  background-position: -128px -121px;
}

.flag.flag-py {
  background-position: -144px -121px;
}

.flag.flag-qa {
  background-position: -160px -121px;
}

.flag.flag-re {
  background-position: -176px -121px;
}

.flag.flag-ro {
  background-position: -192px -121px;
}

.flag.flag-rs {
  background-position: -208px -121px;
}

.flag.flag-ru {
  background-position: -224px -121px;
}

.flag.flag-rw {
  background-position: -240px -121px;
}

.flag.flag-sa {
  background-position: 0 -132px;
}

.flag.flag-sb {
  background-position: -16px -132px;
}

.flag.flag-sc {
  background-position: -32px -132px;
}

.flag.flag-scotland {
  background-position: -48px -132px;
}

.flag.flag-sd {
  background-position: -64px -132px;
}

.flag.flag-se {
  background-position: -80px -132px;
}

.flag.flag-sg {
  background-position: -96px -132px;
}

.flag.flag-sh {
  background-position: -112px -132px;
}

.flag.flag-si {
  background-position: -128px -132px;
}

.flag.flag-sk {
  background-position: -144px -132px;
}

.flag.flag-sl {
  background-position: -160px -132px;
}

.flag.flag-sm {
  background-position: -176px -132px;
}

.flag.flag-sn {
  background-position: -192px -132px;
}

.flag.flag-so {
  background-position: -208px -132px;
}

.flag.flag-somaliland {
  background-position: -224px -132px;
}

.flag.flag-sr {
  background-position: -240px -132px;
}

.flag.flag-ss {
  background-position: 0 -143px;
}

.flag.flag-st {
  background-position: -16px -143px;
}

.flag.flag-sv {
  background-position: -32px -143px;
}

.flag.flag-sx {
  background-position: -48px -143px;
}

.flag.flag-sy {
  background-position: -64px -143px;
}

.flag.flag-sz {
  background-position: -80px -143px;
}

.flag.flag-tc {
  background-position: -96px -143px;
}

.flag.flag-td {
  background-position: -112px -143px;
}

.flag.flag-tf {
  background-position: -128px -143px;
}

.flag.flag-tg {
  background-position: -144px -143px;
}

.flag.flag-th {
  background-position: -160px -143px;
}

.flag.flag-tj {
  background-position: -176px -143px;
}

.flag.flag-tk {
  background-position: -192px -143px;
}

.flag.flag-tl {
  background-position: -208px -143px;
}

.flag.flag-tm {
  background-position: -224px -143px;
}

.flag.flag-tn {
  background-position: -240px -143px;
}

.flag.flag-to {
  background-position: 0 -154px;
}

.flag.flag-tr {
  background-position: -16px -154px;
}

.flag.flag-tt {
  background-position: -32px -154px;
}

.flag.flag-tv {
  background-position: -48px -154px;
}

.flag.flag-tw {
  background-position: -64px -154px;
}

.flag.flag-tz {
  background-position: -80px -154px;
}

.flag.flag-ua {
  background-position: -96px -154px;
}

.flag.flag-ug {
  background-position: -112px -154px;
}

.flag.flag-um {
  background-position: -128px -154px;
}

.flag.flag-us {
  background-position: -144px -154px;
}

.flag.flag-uy {
  background-position: -160px -154px;
}

.flag.flag-uz {
  background-position: -176px -154px;
}

.flag.flag-va {
  background-position: -192px -154px;
}

.flag.flag-vc {
  background-position: -208px -154px;
}

.flag.flag-ve {
  background-position: -224px -154px;
}

.flag.flag-vg {
  background-position: -240px -154px;
}

.flag.flag-vi {
  background-position: 0 -165px;
}

.flag.flag-vn {
  background-position: -16px -165px;
}

.flag.flag-vu {
  background-position: -32px -165px;
}

.flag.flag-wales {
  background-position: -48px -165px;
}

.flag.flag-wf {
  background-position: -64px -165px;
}

.flag.flag-ws {
  background-position: -80px -165px;
}

.flag.flag-ye {
  background-position: -96px -165px;
}

.flag.flag-yt {
  background-position: -112px -165px;
}

.flag.flag-za {
  background-position: -128px -165px;
}

.flag.flag-zanzibar {
  background-position: -144px -165px;
}

.flag.flag-zm {
  background-position: -160px -165px;
}

.flag.flag-zw {
  background-position: -176px -165px;
}

/* flag with span */
.flag + span {
  text-transform: capitalize;
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-top: -3px;
}

@media (min-width: 768px) and (max-width: 979px) {
  .flag + span {
    max-width: 30px !important;
  }
}
#header {
  display: block;
  height: 65px;
  margin: 0;
  padding: 0 13px 0 0;
  position: relative;
  z-index: 905;
  background-color: #FFF;
}
#header div.open {
  font-weight: bold;
}
#header #logo-group span {
  display: inline-block;
  height: 39px;
  float: left;
}
#header #logo {
  display: inline-block;
  margin-top: 4px;
  width: auto;
  margin-left: 9px;
}
#header #logo img {
  width: auto;
  height: 58px;
  padding-left: 3px;
}
#header .header-dropdown-list {
  list-style: none;
  float: right;
  position: relative;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: 10px;
}
#header .header-dropdown-list li {
  display: inline-block;
  padding-right: 5px;
}
#header .header-dropdown-list li .dropdown-toggle {
  margin-top: 16px;
  display: block;
}
#header .btn-header {
  margin-top: 8px;
}
#header .btn-header span a {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: default !important;
  display: inline-block;
  font-weight: 700;
  height: 30px;
  line-height: 24px;
  min-width: 30px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  border: 1px solid #008BD1;
  color: #6D6A69;
  font-size: 17px;
  margin: 10px 0 0;
}
#header .header-search input[type=text] {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  min-width: 200px;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  border-color: #bfbfbf;
  background: #fff;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-top: 10px;
  line-height: normal;
}
#header .header-search button {
  background: 0 0;
  border: none;
  color: #6D6A69;
  font-size: 17px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 10px;
  width: 30px;
  z-index: 2;
}
#header #cancel-search-js {
  position: absolute;
  display: none;
  background: #a90329;
  z-index: 3;
  color: #fff;
  padding: 0;
  right: 10px;
  top: 10px;
  text-align: center;
  height: 29px;
  line-height: 29px;
  width: 30px;
  font-size: 17px;
  text-decoration: none !important;
}
#header .btn-header.pull-right, #header .header-search.pull-right {
  margin-left: 6px;
  position: relative;
}
#header .btn-header.pull-right a:hover, #header .header-search.pull-right a:hover {
  cursor: pointer;
  color: #008BD1;
}
#header .nav-pills li {
  margin-right: 20px;
}
#header .nav-pills li:hover {
  background: none;
}
#header .nav-pills li a {
  background-color: #FFF;
  border: 1px solid #008BD1;
  color: #555555;
  display: inherit;
  height: auto;
  margin-top: 8px;
  padding: 5px 10px;
  font-size: 15px;
}
#header .nav-pills li a:hover {
  background: #008BD1;
  color: #FFF;
}
#header .nav-pills li.active a {
  background-color: #008BD1;
  color: #FFF;
}

span#activity {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer !important;
  display: inline-block;
  font-weight: 700;
  width: 32px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  color: #c4bab6;
  font-size: 19px;
  margin: 10px 0 0;
  position: relative;
}
span#activity b.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  background: #0091d9;
  display: inline-block;
  font-size: 10px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  padding: 2px 4px 3px;
  text-align: center;
  line-height: normal;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  direction: ltr;
  font-family: "Lato", "Open Sans", Arial, Helvetica, Sans-Serif;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  background-color: #F5F5F5;
  overflow-x: hidden;
}

.mar-all {
  margin: 20px;
}

/**
Blague
 */
.blague {
  z-index: 999999;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #FFF;
}

.bg_blague {
  color: #FFF;
  animation: myfirst 1s;
  -moz-animation: myfirst 1s infinite;
  /* Firefox */
  -webkit-animation: myfirst 1s infinite;
  /* Safari and Chrome */
}

.bl_center {
  margin: 0 auto;
  margin-top: 20%;
  width: 50%;
  color: #FFF;
  padding: 10px;
  background-color: #008BD1;
}
.bl_center p {
  font-size: 120%;
}

@-moz-keyframes myfirst /* Firefox */ {
  0% {
    background: #FFF;
  }
  50% {
    background: #a90329;
  }
  100% {
    background: #FFF;
  }
}
@-webkit-keyframes myfirst /* Firefox */ {
  0% {
    background: #FFF;
  }
  50% {
    background: #a90329;
  }
  100% {
    background: #FFF;
  }
}
h1 {
  letter-spacing: -1px;
  font-size: 24px;
}

.bg-color-red {
  background-color: #a90329 !important;
}

.errorBorder {
  border-color: #a90329;
}

.error {
  color: #a90329;
}

.left {
  float: left;
}

.margin-left-10 {
  margin-left: 10px;
}

.alert-heading {
  margin: 0;
  color: inherit;
  line-height: normal;
  font-weight: 800;
}

.flex {
  display: flex;
}

.padtop20 {
  padding-top: 20px;
}

.pull-right {
  float: right !important;
}

.dropdown-menu {
  min-width: inherit;
}
.dropdown-menu li a:hover {
  color: #3276b1;
}

.ul_inline .btn-xs {
  padding: 1px 5px 0px 5px;
  line-height: inherit;
}

.btn-xs {
  padding: 2px 5px 0px;
}

.nodisplay {
  display: none;
}

.clear {
  clear: both;
}

.panel-bluemega {
  border-color: #008BD1;
}
.panel-bluemega .panel-heading {
  color: #fff;
  background-color: #008BD1;
  border-color: #008BD1;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.ul_inline li {
  display: inline;
  margin-right: 15px;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.font100 {
  font-size: 100%;
}

.w100 {
  width: 100%;
}

.table-responsive {
  overflow-x: inherit;
}

.alert {
  margin-bottom: 20px;
  margin-top: 0;
  border-width: 0;
  border-left-width: 5px;
  padding: 10px;
  border-radius: 0;
}

.clearfix {
  clear: both;
}

.wrapper {
  padding: 10px;
}

.pointer {
  cursor: pointer;
}

.borderleft {
  border-left: 1px solid #999999;
}

.fa.pointer {
  cursor: pointer;
}
.fa.pointer:hover {
  font-weight: bold;
  font-size: 14px;
}

.nav-tabs > li > a .label-2x {
  opacity: 0.7;
  filter: alpha(opacity=70);
  font-size: 100%;
  margin: 0px;
}

.label-2x {
  font-size: 100%;
}

ul li {
  list-style-type: none;
}

.btn-bluemega {
  color: #fff;
  background-color: #008BD1;
  border-color: #007ab8;
}
.btn-bluemega:focus, .btn-bluemega.focus {
  color: #fff;
  background-color: #00699e;
  border-color: #002538;
}
.btn-bluemega:hover {
  color: #fff;
  background-color: #00699e;
  border-color: #00517a;
}
.btn-bluemega:active, .btn-bluemega.active, .open > .btn-bluemega.dropdown-toggle {
  color: #fff;
  background-color: #00699e;
  border-color: #00517a;
}
.btn-bluemega:active:hover, .btn-bluemega:active:focus, .btn-bluemega:active.focus, .btn-bluemega.active:hover, .btn-bluemega.active:focus, .btn-bluemega.active.focus, .open > .btn-bluemega.dropdown-toggle:hover, .open > .btn-bluemega.dropdown-toggle:focus, .open > .btn-bluemega.dropdown-toggle.focus {
  color: #fff;
  background-color: #00517a;
  border-color: #002538;
}
.btn-bluemega:active, .btn-bluemega.active, .open > .btn-bluemega.dropdown-toggle {
  background-image: none;
}
.btn-bluemega.disabled, .btn-bluemega.disabled:hover, .btn-bluemega.disabled:focus, .btn-bluemega.disabled.focus, .btn-bluemega.disabled:active, .btn-bluemega.disabled.active, .btn-bluemega[disabled], .btn-bluemega[disabled]:hover, .btn-bluemega[disabled]:focus, .btn-bluemega[disabled].focus, .btn-bluemega[disabled]:active, .btn-bluemega[disabled].active, fieldset[disabled] .btn-bluemega, fieldset[disabled] .btn-bluemega:hover, fieldset[disabled] .btn-bluemega:focus, fieldset[disabled] .btn-bluemega.focus, fieldset[disabled] .btn-bluemega:active, fieldset[disabled] .btn-bluemega.active {
  background-color: #008BD1;
  border-color: #007ab8;
}
.btn-bluemega .badge {
  color: #008BD1;
  background-color: #fff;
}

.btn-nano {
  padding: 1px 3px;
  margin-left: 3px;
}
.btn-nano i {
  font-size: 10px;
}

.btn-border {
  padding: 1px 3px;
  border: 1px solid #FFF;
}

#search-mobile {
  display: none;
}

#ribbon {
  min-height: 40px;
  background: #0e0e0e;
  padding: 0 13px;
  position: relative;
}
#ribbon .information {
  margin: 0;
  color: #FFF;
  padding: 11px 34px 11px 0px;
}
#ribbon .information .listpermissions {
  margin-top: -2px;
  padding-top: 3px;
}

.login-info img {
  width: 25px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-top: 1px;
  margin-right: 5px;
  margin-left: 0;
  border-left: 3px solid #fff;
}

.login-info a {
  text-decoration: none !important;
  color: #c0bbb7;
  display: inline-block;
  margin-top: 6px;
}

.login-info a span {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 28px;
}

.breadcrumb {
  display: inline-block;
  margin: 0;
  margin-left: 10px;
  padding: 11px 34px 11px 0 !important;
  color: #BBB !important;
  background: none;
  text-decoration: none !important;
  vertical-align: top;
}
.breadcrumb li {
  padding-top: 2px;
}
.breadcrumb a:first-child {
  color: #FFF;
}
.breadcrumb li:last-child, .breadcrumb.active {
  color: #E4E4E4;
}

.leftmenu {
  margin-top: 20px;
}
.leftmenu li {
  padding: 5px 10px;
  border-bottom: 1px solid #999999;
}
.leftmenu li.active {
  font-weight: bold;
}
.leftmenu li a {
  font-size: 14px;
}

#content {
  padding: 10px 14px;
  position: relative;
}
#content section .bgborder {
  background-color: #fff;
  border: 1px solid #2A7DBF;
  margin-bottom: 10px;
}
#content .header_title {
  margin: 0px 0px 20px;
}
#content .page-title h1 {
  padding: 0px;
  font-size: 16px;
  font-weight: 400;
  color: #222;
  margin: 0px;
}

.link_customer.nav-pills li a {
  padding: 5px 10px;
  font-size: 14px;
}

li a.btn-primary {
  background-color: #FFF;
}
li a.btn-primary i.fa {
  color: #008BD1;
}

li.active a.btn-primary {
  background-color: #008BD1;
}
li.active a.btn-primary i.fa {
  color: #FFF;
}

.information a {
  color: #FFF;
}

.products .table > tbody > tr > td, .products .table > tfoot > tr > td {
  padding: 2px;
}

a.bg-color-green:hover {
  background-color: #2a582a !important;
}

a.bg-color-magenta:hover {
  background-color: #582b5b !important;
}

tbody.lineheight49 td {
  line-height: 49px;
}

.ref {
  width: 100px;
}

.pht {
  width: 90%;
}

.unitht {
  width: 120px;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  vertical-align: middle;
}

.trtotal {
  width: 90px;
  text-align: right;
}

.ttht {
  font-weight: bold;
  font-size: 15px;
}

.ttc {
  font-weight: bold;
}

.sstable {
  padding-right: 12px;
}

/*
 * Margins
 */
.no-margin {
  margin: 0px !important;
}

.margin-5 {
  margin: 5px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.padding-10 {
  padding: 10px !important;
}

.ajax-dropdown, .ajax-dropdown-permissions {
  position: absolute;
  z-index: 905;
  top: 48px;
  right: 16px;
  width: 344px;
  height: 435px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  -moz-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  background: #fff;
  border: 1px solid #b3b3b3;
}

.ajax-notifications {
  margin-right: 0px;
  margin-left: 0px;
}

.getPermissions {
  color: #000;
}
.getPermissions h3 {
  font-size: 110%;
  font-weight: bold;
}
.getPermissions ul {
  min-height: 90px;
  margin: 0px;
  padding-left: 0px;
}

#project-context,
.project-context {
  margin-left: 60px;
}

.custom-scroll::-webkit-scrollbar-track-piece {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-radius: 0;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  height: 10px;
  width: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  -moz-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

.notification-body {
  padding: 0 0 0 3px;
  margin: 0;
  list-style: none;
}

.notification-body *, .notification-body:after *, .notification-body:before * {
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
}

.notification-body > li > span {
  background: #fff;
  display: block;
  min-height: 25px;
  overflow: hidden;
  padding: 8px 10px 13px;
  white-space: normal;
}

.notification-body .me {
  background: #FFFFE0;
}

.feedback {
  background-color: #555555;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  z-index: 1999;
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin-right: 10px;
  width: 250px;
}
.feedback .feed_header {
  display: block;
  cursor: pointer;
  background-color: #a90329;
  padding: 0px 5px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.feedback .feed_header a {
  color: #FFF;
}
.feedback .feed_header a.feed_link {
  float: left;
}
.feedback .feed_header a.feed_button {
  float: right;
}
.feedback .feed_form {
  padding: 10px;
  margin-bottom: 10px;
}
.feedback .formloading {
  height: 100%;
}
.feedback .formloading img {
  top: 40%;
}

.page-footer {
  height: 30px;
  padding: 6px 13px 0;
  border-top: 1px solid #CECECE;
  background: #2a2725;
  width: 100%;
  position: absolute;
  display: block;
  bottom: 0;
}

.popover {
  width: 200px;
  text-align: center;
}

/**
* ACCORDIONS
*/
.smart-accordion-default.panel-group {
  margin-bottom: 0px;
}

.smart-accordion-default input {
  margin-top: 2px;
}

.smart-accordion-default.panel-group .panel + .panel {
  margin-top: -1px;
}

.smart-accordion-default.panel-group .panel-heading {
  padding: 0px;
}

.smart-accordion-default.panel-group .panel-title a, .smart-accordion-default.panel-group .panel-title span {
  display: block;
  padding: 10px 15px;
  text-decoration: none !important;
}

.smart-accordion-default .panel-heading, .panel-group .panel {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}

.smart-accordion-default .panel-default > .panel-heading {
  background-color: #fcfcfc;
}

.smart-accordion-default .panel-default {
  border-color: #c3c3c3;
}

#pendingModal .pending {
  position: relative;
  min-height: 400px;
}
#pendingModal .pending div {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
}

.shortcut h3 {
  margin: 5px;
  background-color: #008BD1;
  color: #FFF;
  padding: 4px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.shortcut ul {
  -webkit-transition: color 0.3s ease;
  -khtml-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
  display: block;
}
@media only screen and (max-width: 768px) {
  .shortcut ul {
    display: none;
    margin: 0 auto 0;
    width: 300px;
  }
  .shortcut ul li {
    display: block;
    margin-bottom: 5px;
  }
}

.table-eye tr td:first-child {
  text-align: right;
}

.borderbottomblue {
  border-bottom: 2px solid #008BD1;
  margin-bottom: 10px;
}

.synthese ul {
  margin: 5px 10px 10px 30px;
  padding: 0px;
}
.synthese h5 {
  margin-top: 0px;
  margin-bottom: 5px;
}

.popover {
  width: 250px;
}

.badge {
  padding: 1px 4px;
}

.offer-menu #left-panel {
  left: -210px;
}
.offer-menu #main {
  margin-left: 0px;
}

.color {
  width: 150px;
}

table.offer {
  margin-top: 5px;
}
table.offer tr {
  height: 25px;
  line-height: 25px;
}
table.offer .toggle.offre i {
  top: 8px;
}

.editableoffer a {
  color: #FFF;
}
.editableoffer .popover-title {
  color: #555555;
}

input[disabled], input[readonly] {
  opacity: 1;
  cursor: not-allowed;
}

.modal-body-standard {
  padding: 20px 30px;
}