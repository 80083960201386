@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  //background: url("/assets/img/mybg.png") #fff;
  direction: ltr;
  font-family: 'Lato', "Open Sans", Arial, Helvetica, Sans-Serif;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  background-color: #F5F5F5;
  overflow-x: hidden;
}
.mar-all{
  margin:20px;
}
/**
Blague
 */
.blague {
  z-index: 999999;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: $white;
}


.bg_blague {
  color: $white;
  animation: myfirst 1s;
  -moz-animation: myfirst 1s infinite; /* Firefox */
  -webkit-animation: myfirst 1s infinite; /* Safari and Chrome */
}
.bl_center{
  margin: 0 auto;
  margin-top:20%;
  width:50%;
  color:$white;
  padding: 10px;
  background-color: $blueMega;
  p{
    font-size: 120%;
  }
}

@-moz-keyframes myfirst /* Firefox */
{
  0% {
    background: $white
  }
  50% {
    background: $brand-danger
  }
  100% {
    background: $white
  }
}

@-webkit-keyframes myfirst /* Firefox */
{
  0% {
    background: $white;
  }
  50% {
    background: $brand-danger;
  }
  100% {
    background: $white;
  }
}


h1 {
  letter-spacing: -1px;
  font-size: 24px;
}

.bg-color-red {
  background-color: $red !important;
}

.errorBorder {
  border-color: $red;
}

.error {
  color: $red
}

.left {
  float: left;
}

.margin-left-10 {
  margin-left: 10px;
}

.alert-heading {
  margin: 0;
  color: inherit;
  line-height: normal;
  font-weight: 800;
}

.flex{
  display:flex;
}

.padtop20 {
  padding-top: 20px;
}

.pull-right {
  float: right !important;
}

.dropdown-menu {
  min-width: inherit;
  li a:hover {
    color: #3276b1;
  }
}

.ul_inline .btn-xs {
  padding: 1px 5px 0px 5px;
  line-height: inherit;
}

.btn-xs {
  padding: 2px 5px 0px;
}

.nodisplay {
  display: none;
}

.clear {
  clear: both;
}

.panel-bluemega {
  .panel-heading {
    color: #fff;
    background-color: $blueMega;
    border-color: $blueMega;
  }
  border-color: $blueMega;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.ul_inline {
  li {
    display: inline;
    margin-right: 15px;
  }
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.font100 {
  font-size: 100%;
}

.w100 {
  width: 100%;
}

.table-responsive {
  overflow-x: inherit;
}

.alert {
  margin-bottom: 20px;
  margin-top: 0;
  border-width: 0;
  border-left-width: 5px;
  padding: 10px;
  border-radius: 0;
}

.clearfix {
  clear: both;
}

.wrapper {
  padding: 10px;
}

.pointer {
  cursor: pointer;
}

.borderleft {
  border-left: 1px solid $gray-light;
}

.fa.pointer {
  cursor: pointer;
  &:hover {
    font-weight: bold;
    font-size: 14px;
  }
}

.nav-tabs > li > a .label-2x {
  @include opacity(0.7);
  font-size: 100%;
  margin: 0px;
}

.label-2x {
  font-size: 100%;
}

ul li {
  list-style-type: none;
}

// Danger and error appear as red
.btn-bluemega {
  @include button-variant($btn-bluemega-color, $btn-bluemega-bg, $btn-bluemega-border);
}

.btn-nano {
  padding: 1px 3px;
  margin-left: 3px;
  i {
    font-size: 10px;
  }
}

.btn-border {
  padding: 1px 3px;
  border: 1px solid $white;
}

#search-mobile {
  display: none;
}

#ribbon {
  min-height: $ribbon-height;
  background: $ribbonBGColor;
  padding: 0 13px;
  position: relative;
  .information {
    margin: 0;
    color: $white;
    padding: 11px 34px 11px 0px;
    //line-height: 28px;
    .listpermissions {
      margin-top: -2px;
      padding-top: 3px;

    }
  }

}

.login-info img {
  width: 25px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-top: 1px;
  margin-right: 5px;
  margin-left: 0;
  border-left: 3px solid #fff;
}

.login-info a {
  text-decoration: none !important;
  color: #c0bbb7;
  display: inline-block;
  margin-top: 6px;
}

.login-info a span {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 28px;

}

.breadcrumb {
  display: inline-block;
  margin: 0;
  //line-height: 23px;
  margin-left: 10px;
  padding: 11px 34px 11px 0 !important;
  color: #BBB !important;
  background: none;
  text-decoration: none !important;
  vertical-align: top;
  li {
    padding-top: 2px;
  }
  a:first-child {
    color: $white;
  }
  li:last-child, &.active {
    color: #E4E4E4;
  }
}

.leftmenu {
  margin-top: 20px;
  li {
    padding: 5px 10px;
    border-bottom: 1px solid $gray-light;
    &.active {
      font-weight: bold;
    }
    a {
      font-size: 14px;

    }
  }
}

#content {
  padding: 10px 14px;
  position: relative;
  section .bgborder {
    background-color: #fff;
    border: 1px solid $blueMegaLighest;
    margin-bottom: 10px;
  }
  .header_title {
    // border-bottom: 1px dashed rgba(0, 0, 0, .2);
    margin: 0px 0px 20px;
  }

  .page-title {
    h1 {
      padding: 0px;
      font-size: 16px;
      font-weight: 400;
      color: #222;
      margin: 0px;
    }

  }

}

.link_customer.nav-pills li a {
  padding: 5px 10px;
  font-size: 14px;
}

li a.btn-primary {
  background-color: $white;
  i.fa {
    color: $brand-primary;
  }
}

li.active a.btn-primary {
  background-color: $brand-primary;
  i.fa {
    color: $white;
  }
}

.information a {
  color: $white;
}

.products .table > tbody > tr > td, .products .table > tfoot > tr > td {
  padding: 2px;
}

a.bg-color-green:hover {
  background-color: darken($green, 6.5%) !important;
}

a.bg-color-magenta:hover {
  background-color: darken($magenta, 6.5%) !important;
}

tbody.lineheight49 td {
  line-height: 49px;
}

.ref {
  width: 100px;
}

.pht {
  width: 90%;
}

.unitht {
  width: 120px;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  vertical-align: middle;
}

.trtotal {
  width: 90px;
  text-align: right;
}

.ttht {
  font-weight: bold;
  font-size: 15px;
}

.ttc {
  font-weight: bold;

}

.sstable {
  padding-right: 12px;
}

/*
 * Margins
 */
.no-margin {
  margin: 0px !important;
}

.margin-5 {
  margin: 5px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-top-0 {
  margin-top: 0px !important
}

.margin-bottom-0 {
  margin-bottom: 0px !important
}

.padding-10 {
  padding: 10px !important;
}

.ajax-dropdown, .ajax-dropdown-permissions {
  position: absolute;
  z-index: 905;
  top: 48px;
  right: 16px;
  width: 344px;
  height: 435px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, .25);
  -moz-box-shadow: 0 2px 4px rgba(30, 30, 100, .25);
  box-shadow: 0 2px 4px rgba(30, 30, 100, .25);
  background: #fff;
  border: 1px solid #b3b3b3;
}

.ajax-notifications {
  margin-right: 0px;
  margin-left: 0px;
}

.getPermissions {
  color: $black;
  h3 {
    font-size: 110%;
    font-weight: bold
  }
  ul {
    min-height: 90px;
    margin: 0px;
    padding-left: 0px;
  }
}

#project-context,
.project-context {
  margin-left: 60px;
}

.custom-scroll::-webkit-scrollbar-track-piece {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-radius: 0;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  height: 10px;
  width: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  -moz-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, .2);
  border: none;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
}

.notification-body {
  padding: 0 0 0 3px;
  margin: 0;
  list-style: none;
}

.notification-body *, .notification-body:after *, .notification-body:before * {
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
}

.notification-body > li > span {
  background: #fff;
  display: block;
  min-height: 25px;
  overflow: hidden;
  padding: 8px 10px 13px;
  white-space: normal;
}

.notification-body .me {
  background: #FFFFE0;
}

.feedback {
  background-color: $gray;
  @include border-radius(3px);
  z-index: 1999;
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin-right: 10px;

  width: 250px;
  .feed_header {
    display: block;
    cursor: pointer;
    background-color: $red;

    padding: 0px 5px;
    @include border-radius(3px);
    a {
      color: $white;
    }
    a.feed_link {
      float: left;
    }
    a.feed_button {
      float: right;
    }
  }
  .feed_form {
    //min-height:200px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .formloading {
    height: 100%;
    img {
      top: 40%;
    }
  }
}

.page-footer {
  height: 30px;
  padding: 6px 13px 0;
  border-top: 1px solid #CECECE;
  background: #2a2725;
  width: 100%;
  position: absolute;
  display: block;
  bottom: 0;
}

.popover {
  width: 200px;
  text-align: center;

}

/**
* ACCORDIONS
*/

.smart-accordion-default.panel-group {
  margin-bottom: 0px;
}

.smart-accordion-default input {
  margin-top: 2px;
}

.smart-accordion-default.panel-group .panel + .panel {
  margin-top: -1px;
}

.smart-accordion-default.panel-group .panel-heading {
  padding: 0px;
}

.smart-accordion-default.panel-group .panel-title a, .smart-accordion-default.panel-group .panel-title span {
  display: block;
  padding: 10px 15px;
  text-decoration: none !important;
}

.smart-accordion-default .panel-heading, .panel-group .panel {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}

.smart-accordion-default .panel-default > .panel-heading {
  background-color: lighten($gray-lightest, 3.5%);
}

.smart-accordion-default .panel-default {
  border-color: lighten($gray-light, 16.5%);
}

#pendingModal .pending {
  position: relative;
  min-height: 400px;
  div {

    @include all-align();
  }
}

.shortcut {
  h3 {
    margin: 5px;
    background-color: $blueMega;
    color: $white;
    padding: 4px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 10px;
    cursor: pointer;

  }
  ul {

    @include transition(color .3s ease);
    display: block;
    @include bp-small {
      display: none;
      margin: 0 auto 0;
      width: 300px;
      li {

        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

.table-eye {
  tr td:first-child {
    text-align: right;
  }
}

.borderbottomblue {
  border-bottom: 2px solid $blueMega;
  margin-bottom: 10px;
}

.synthese {
  ul {
    margin: 5px 10px 10px 30px;
    padding: 0px;

  }
  h5 {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}

.popover {
  width: 250px;
}

.badge {
  padding: 1px 4px;
}

.offer-menu {
  #left-panel {
    left: -210px;
  }
  #main {
    margin-left: 0px;
  }
}

.color {
  width: 150px;
}

table.offer {
  margin-top: 5px;
  tr {
    height: 25px;
    line-height: 25px;
  }
  .toggle.offre i {
    top: 8px;
  }
}

.editableoffer {
  a {
    color: $white
  }
  .popover-title {
    color: $gray;
  }
}

input[disabled], input[readonly] {
  //background-color: #eeeeee;
  opacity: 1;
  cursor: not-allowed;
}

.modal-body-standard{
  padding: 20px 30px;
}