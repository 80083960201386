
#header {
  display: block;
  height: $smart-navbar-height;
  margin: 0;
  padding: 0 13px 0 0;
  position: relative;
  z-index: 905;
  background-color: $white;


  div.open {
    font-weight: bold;
  }

  #logo-group span {
    display: inline-block;
    height: 39px;
    float: left;
  }

  #logo {
    display: inline-block;

    margin-top: 4px;
    width:auto;
    margin-left: 9px;
    img {
      //width: 190px;
      //max-height: 58px;
      width: auto;
      height: 58px;
      padding-left: 3px;
    }
  }

  .header-dropdown-list {
    list-style: none;
    float: right;
    position: relative;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 10px;
    & li {
      display: inline-block;
      padding-right: 5px;
      & .dropdown-toggle {
        margin-top: 16px;
        display: block;
      }
    }
  }
  .btn-header {
    margin-top: 8px;
  }
  .btn-header span a {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    cursor: default !important;
    display: inline-block;
    font-weight: 700;
    height: 30px;
    line-height: 24px;
    min-width: 30px;
    padding: 2px;
    text-align: center;
    text-decoration: none !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    background-color: $navbar-default-bg;
    background-image: -webkit-gradient(linear, left top, left bottom, from($navbar-default-bg), to(#f1f1f1));
    background-image: -webkit-linear-gradient(top, $navbar-default-bg, #f1f1f1);
    background-image: -moz-linear-gradient(top, $navbar-default-bg, #f1f1f1);
    background-image: -ms-linear-gradient(top, $navbar-default-bg, #f1f1f1);
    background-image: -o-linear-gradient(top, $navbar-default-bg, #f1f1f1);
    background-image: linear-gradient(top, $navbar-default-bg, #f1f1f1);
    border: 1px solid $blueMega;
    color: #6D6A69;
    font-size: 17px;
    margin: 10px 0 0;
  }
  .header-search input[type=text] {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    min-width: 200px;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    border-color: #bfbfbf;
    background: #fff;
    color: $darken;
    appearance: normal;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin-top: 10px;
    line-height: normal;
  }
  .header-search button {
    background: 0 0;
    border: none;
    color: #6D6A69;
    font-size: 17px;
    height: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 10px;
    width: 30px;
    z-index: 2;
  }
  #cancel-search-js {
    position: absolute;
    display: none;
    background: $red;
    z-index: 3;
    color: #fff;
    padding: 0;
    right: 10px;
    top: 10px;
    text-align: center;
    height: 29px;
    line-height: 29px;
    width: 30px;
    font-size: 17px;
    text-decoration: none !important;
  }

  .btn-header.pull-right, .header-search.pull-right {
    margin-left: 6px;
    position: relative;
    a{
      //background: none;
      //background-color: $white;
      &:hover{
        cursor: pointer;
        //background-color: $blueMega;
        color:$blueMega;
      }
    }
  }
  .nav-pills {
    li {
      margin-right: 20px;
      &:hover {
        background: none;
      }
      a {
        background-color: $white;

        border: 1px solid $blueMega;
        color: $gray;
        //border-radius: 0px;
        display: inherit;
        height: auto;
        margin-top: 8px;
        padding: 5px 10px;
        font-size: 15px;

        &:hover {
          background: $blueMega;
          color: $white;

        }
      }
      &.active {
        a {
          background-color: $blueMega;
          color: $white;
        }
      }
    }

    //line-height: 30px;
  }
}

span#activity {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer !important;
  display: inline-block;
  font-weight: 700;
  width: 32px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  @include background-image(#f8f8f8,#f1f1f1);
  border: 1px solid #bfbfbf;
  color: #c4bab6;
  font-size: 19px;
  margin: 10px 0 0;
  position: relative;
  b.badge {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    background: #0091d9;
    display: inline-block;
    font-size: 10px;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
    color: #fff;
    font-weight: 700;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    padding: 2px 4px 3px;
    text-align: center;
    line-height: normal;
  }
}
