.btnwarwhite{
  background-color: $white;
  border-color: $brand-warning;
  color:$brand-warning;
  &:hover{
    background-color:$brand-warning;
    color:$white;
  }
}

.btnsucceswhite{
  background-color: $white;
  border-color: $brand-success;
  color:$brand-success;
  &:hover{
    background-color:$brand-success;
    color:$white;
  }
}

.btndangerwhite{
  background-color: $white;
  border-color: $brand-danger;
  color:$brand-danger;
  &:hover{
    background-color:$brand-danger;
    color:$white;
  }
}

.btnprimarywhite{
  background-color: $white !important;
  border-color: $brand-primary !important;
  color:$brand-primary !important;
  &:hover{
    background-color:$brand-primary !important;
    color:$white !important;
  }
}

