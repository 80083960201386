.dt-toolbar {
  display: block;
  position: relative;
  padding: 6px 7px 1px;
  width: 100%;
  float: left;
  border-bottom: 1px solid #ccc;
  background: #fafafa;
}
.dt-toolbar-footer {
  background: #fafafa;
  font-size: 11px;
  overflow: hidden;
  padding: 5px 10px;
  border-top: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px #fff;
  -moz-box-shadow: inset 0 1px #fff;
  -ms-box-shadow: inset 0 1px #fff;
  box-shadow: inset 0 1px #fff;
}
.dt-toolbar, .dt-toolbar-footer {
  >:first-child { padding-left: 0px !important; }
  >:last-child { padding-right: 0px !important; }
}

table.dataTable {
  thead, tfoot {
    > tr {
      > th {
        padding-left: 9px !important;
        input {
          width: 100% !important;
          font-weight: normal;
        }
      }
    }
  }
}

.dataTables_empty {
  padding: 20px 10px !important;
  font-size: 14px;
  text-align: center;
  color: #575757;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  padding: 8px 10px;
}

div.dataTables_info {
  padding-top: 9px;
  font-size: 13px;
  font-weight: bold;
  font-style: italic;
  color: #969696;
}

/*
* Double lines in thead tfoot
*/
.dataTable thead>tr>th {
  border-left:none !important;
}

/* checkbox */
.dataTable input[type=checkbox].checkbox,
.dataTable input[type=radio].radiobox {
  left: -999px;
}

/*
* DT FIlter
*/
.dataTables_filter {
  float:left;
  width:100%;
  .input-group-addon {
    width: 32px;
    margin-top: 0px;
    float: left;
    height: 32px;
    padding-top: 8px;
    + .form-control {
      float:left;
    }
  }
}

/*
* LTR filter position
*/
.dt-toolbar {
  >:first-child {
    .dataTables_filter >:only-child, .DTTT, .pagination, .dataTables_length { float: left !important; }
  }
  >:last-child {
    .dataTables_filter >:only-child, .DTTT, .pagination, .dataTables_length { float: right; }
  }
}

/* bottom portion */
.dt-toolbar-footer {
  >:first-child {
    .dataTables_filter >:only-child, .DTTT, .pagination, .dataTables_length { float: left; }
  }
  >:last-child {
    .dataTables_filter >:only-child, .DTTT, .pagination, .dataTables_length { float: right; }
  }
}

/*
* PRINT
*/

body.DTTT_Print { background:#fff !important; }
.DTTT_Print #main { margin:0px !important; }
.DTTT_PrintMessage {
  font-size: 20px;
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  font-weight: bold;
}
.DTTT_Print table.dataTable { margin-top:0px !important; }
.DTTT_Print .dataTables_wrapper table { border: 1px solid #C7C7C7 !important; }

/*
* COLUMN SHOW/HIDE
*/
div.ColVis { margin-bottom:0px !important; }
ul.ColVis_collection {
  width: 127px;
  span { vertical-align: 3px; }
  label { margin-bottom:0px !important; }
}

/*
 * Processing Fix
 */

.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
}

/* custom font icon */
.dataTable tbody .details-control{
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.dataTable tbody .details-control:before{
  font-family: 'FontAwesome';
  content: "\f055";
  font-size: 1.4em;
  color:$brand-success;
}
.dataTable tbody .shown .details-control:before{
  content: "\f056";
   color:$brand-danger;
}

.dataTable tbody .shown + tr > td {
  padding:0px !important;
}

.dataTable tbody .shown {
  background: rgba(205, 209, 98, 0.2) !important;
}

.dataTable tbody .shown > td {
  background:none !important;
}

.dataTable tbody .shown + tr > td > table > tbody > tr:last-child{
  border-bottom: 1px solid rgb(194, 194, 194);
}

.dataTable tbody .shown + tr > td > table tbody tr {
  background-color: rgba(205, 209, 98, 0.05) !important;
}


.table-bordered .progress {
  background:#fff !important;
  -webkit-box-shadow: 0 1px 0 transparent,0 0 0 1px #D6D6D6 inset;
  -moz-box-shadow: 0 1px 0 transparent,0 0 0 1px #D6D6D6 inset;
  box-shadow: 0 1px 0 transparent,0 0 0 1px #D6D6D6 inset;
}

/* datatable scroll-y bug fix for firefox */

.dataTables_scroll .dataTables_scrollHead,
.dataTables_scroll .dataTables_scrollBody,
.dataTables_scroll + .dt-toolbar-footer {
  float:left;
}
.dataTables_scroll + .dt-toolbar-footer {
  width:100%;
}

.dataTables_scroll .dataTables_scrollHeadInner {
  margin-top:-6px;
}

.dataTables_scroll .dataTables_scrollHead {
  background: rgb(244, 244, 244);
}