.no-padding .table-responsive .table {
  margin-bottom: 0;
}

.table .devise {
  width: 80%;
  text-align: center;

}

.th_center th {
  text-align: center;
}

.maxinput input, .maxinput select {
  margin: 1px;
  padding: 3px 3px;
  height: 32px;
}

.table.maxinput > tbody > tr > td{
  padding: 2px;
}
@include keyframes(pulsate)
{
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
.fa-pulsate
{

  @include animation('pulsate 1s infinite');
}

.table.table_synthese tr th,.table.table_synthese tr td{
  border-top:none;
}